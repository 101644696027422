import React, { useEffect, useState, useContext } from "react";
import { useSetRecoilState } from "recoil";
import { curRouteDetailsState } from "../../../../Helpers/Recoil";
import EmployeeCard from "../../../../componants/EmployeeCard/EmployeeCard";
import iconSearch from "./../../../../asset/svg/icon-search.svg";
import HomeSearch from "../../Landing/HomeSearch";
import { apiRequest, generateURLSearch } from "../../../../Helpers/General";
import NoRecord from "../../../../componants/NoRecord/NoRecord";
import Loader from "../../../../componants/Loader/Loader";
import DocumentMeta from "react-document-meta";
import { MasterContext } from "../../../../Context";
import { useInView } from "react-intersection-observer";
import { useParams, useHistory } from "react-router-dom";
import Modal from "../../../../componants/Modal/Modal";
import LawyerInquiry from "../../Auth/LawyerInquiry";
import { getVariable, saveVariable } from "../../../../Helpers/Session";

const LawyerDirectory = (props) => {
    let { country,state,city,practice_area } = useParams();
    let suffix_obj = {};
    let login_user_id = (props && props.userProfile && props.userProfile.user_master_id) || 0;
    let maskedNumber = true;
    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const setCurRouteDetails = useSetRecoilState(curRouteDetailsState);
    const [totalRecords, setTotalRecords] = useState(0);
    const[stateCityData,setStateCityData] = useState([]);
    const[descriptionTxt,setDescriptionTxt] = useState("Best Lawyers in India");
    let countryTxt = '';
    let cityTxt = '';
    let expertiseTxt = '';
    let newDescTxt = '';
    let practiceTxt = '';
    let metaDesc = '';

    const [ref, inView] = useInView({
        threshold: 0,
    });

    useEffect(() => {
        setCurRouteDetails(props.componentInfo);
    });

    const { genralMasterData, countrydata, expertiseMasterData } = useContext(MasterContext);
    
    let lawyerDirectoryLabel = genralMasterData.LAWYER_DIRECTORY;
    const [lowyerDirectoryData, setLowyerDirectoryData] = useState([]);
    const [lowyerDirectoryMetaData, setLowyerDirectoryMetaData] = useState([]);
    const [isPageUpdating, setIsPageUpdating] = useState(true);
    const [searchParam, setSearchParam] = useState(
        localStorage.getItem("searchParam")
        ? JSON.parse(localStorage.getItem("searchParam"))
        : {}
    );
    const [filterData, setFilterData] = useState([]);
    const [pageIndex, setpageIndex] = useState("1");
    const [nextPage, setNextPage] = useState(0);
    const [countryArr , setCountryArr] = useState(countrydata);
    const [expertiseArr , setExpertiseArr] = useState(expertiseMasterData);
    const [country_id, setCountryId] = useState(102);
    const [openModal , setOpenModal] = useState(false);
    let clickedLawyers = getVariable("selLawyers", true);
    const [viewLayers, setViewLayers] = useState(
        clickedLawyers ? clickedLawyers : {}
    );
    const[selectedLawyer ,setSelectedLawyer] = useState();   
    const [requestOn , setRequestOn] = useState(""); 
    

    let seo_search = {
        'ct' : {
            loop_data : countryArr,
            key : 'country'
        },
        'pa' : {
            loop_data : expertiseArr,
            key : 'Area'
        },
        'st' : {
            loop_data : stateCityData,
            key : 'statecity'
        },
        'cy' : {
            loop_data : stateCityData,
            key : 'statecity',
            slug_key : ""
        }
    }
    useEffect(async () => {
        const checkSeoParams = async () => {
            if(country) {
                suffix_obj['ct'] = country;
            }
            if(state && city ) {
                if(state !== undefined) {
                    suffix_obj['st'] = state;
                } 
                if (city !== undefined) {
                    seo_search['cy']['slug_key'] = city;
                }
            }
            if (practice_area){
                suffix_obj['pa'] = practice_area;
            } else if (state && city === undefined) {
                suffix_obj['pa'] = state;
            }
			
            if(Object.keys(suffix_obj).length > 0) {
                if(countryArr.length <= 0 || expertiseArr.length <= 0) {
                    const apiSetting = {
                        method: "GET", apiParams: { country_id: "" },
                    };
                    const apiResult = await apiRequest("get_common_master_list", apiSetting);
                    if (apiResult.data.settings.success === "1") {
                        seo_search['ct']['loop_data'] = apiResult.data.data.country_data;
                        setCountryArr(apiResult.data.data.country_data);
                        seo_search['pa']['loop_data'] = apiResult.data.data.expertise_master_data;
                        setExpertiseArr(apiResult.data.data.expertise_master_data);
                    }
                }
                let slug_key_v2 = ''
                for(const[key,value] of Object.entries(suffix_obj) ) {
                    if(seo_search[key] && seo_search[key]['loop_data']) {
                        if ((key === "st") && (stateCityData.length <= 0 || seo_search['st']['loop_data'].length <= 0)) {
                            let cityData = await getCityStateData(country_id);
                            setStateCityData(cityData);
                            seo_search['cy']['loop_data'] = cityData;
                            seo_search['st']['loop_data'] = cityData;
							slug_key_v2 = seo_search['cy']['slug_key'];
                        } else if (key === "st") {
							slug_key_v2 = seo_search['cy']['slug_key'];
						}
                        let loop_data = seo_search[key]['loop_data'];
                        let storage_data = seo_search[key]['key'];
                        
                        if(loop_data.length > 0 ) { 
                            searchParam[storage_data] = await generateURLSearch(key,value,loop_data,slug_key_v2);
                            if (key === "ct" && searchParam.country && searchParam.country[0] && searchParam.country['country_id'] && searchParam.country['country_id'] !== country_id ) {
                                setCountryId(searchParam.country[0]['country_id'])
                            }
                        } else {
                            delete searchParam.storage_data;
                        }
                    }
                }
            }
        }
        const GetData = async () => {       
            try {
                if (searchParam.pageIndex) setpageIndex(searchParam.pageIndex);
                const apiSetting = {
                    method: "GET",
                    apiParams: {
                        country_id:
                        searchParam && searchParam.country && searchParam.country[0]
                            ? searchParam.country[0].country_id
                            : "102",
                        user_type: "Lawyer",
                        state_id:
                        searchParam && searchParam.statecity && searchParam.statecity[0]
                            ? searchParam.statecity[0].state_id
                            : "",
                        city_id:
                        searchParam && searchParam.statecity && searchParam.statecity[0]
                            ? searchParam.statecity[0].city_id
                            : "",
                        court_id:
                        searchParam && searchParam.Court && searchParam.Court[0]
                            ? searchParam.Court[0].court_master_id
                            : "",
                        expertise_id:
                        searchParam && searchParam.Area && searchParam.Area[0]
                            ? searchParam.Area[0].expertise_master_id
                            : "",
                        user_name: searchParam && searchParam.Name ? searchParam.Name : "",
                        page_index: searchParam.pageIndex
                        ? searchParam.pageIndex
                        : pageIndex,
                        verified_lawyer : searchParam && searchParam.lawyerVerified && searchParam.lawyerVerified === true 
                                            ? 'Yes' 
                                            : ""
                    },
                };
                delete searchParam.pageIndex;
                const apiResult = await apiRequest("get_lawyer_directory_listing",apiSetting);
                localStorage.setItem("lawyer_sel_country",apiSetting.apiParams.country_id);

                if (apiResult.data.settings.success === "1") {
                    let data = apiResult.data.data.return_data;
                    setDescriptionTxt(apiResult.data.data.descriptionTxt);
                    setTotalRecords(apiResult.data.settings.count);
                    setNextPage(parseInt(apiResult.data.settings.next_page));
                    if (props.userProfile) {
                        let index = "";
                        data.map((item, inde) => {
                            if (item.user_master_id === props.userProfile.user_master_id) {
                                index = inde;
                            }
                        });

                        if (index !== "" && index > -1) {
                            data.splice(index, 1);
                            setTotalRecords(apiResult.data.settings.count - 1);
                        }
                    }
                    if (pageIndex > 1) {
                        lowyerDirectoryData.push(...data);
                    } else {
                        setLowyerDirectoryData(data);
                        setLowyerDirectoryMetaData(apiResult.data.data.lawyer_gc_meta_data[0]);

                        setFilterData(data);
                        if (searchParam) {
                            delete searchParam.pageIndex;
                            setSearchParam(searchParam);
                        } else {
                            setSearchParam({});
                        }
                    }
                } else {
                    countryTxt = searchParam && searchParam['country'] && searchParam['country'][0] 
                                ? searchParam['country'][0]['country'] : 
                                "";
                    cityTxt =   searchParam && searchParam.statecity && searchParam.statecity[0]
                                ? searchParam.statecity[0].city_code
                                : ""
                    expertiseTxt =  searchParam && searchParam.Area && searchParam.Area[0]
                                    ? searchParam.Area[0].expertise_name
                                    : "";
                    practiceTxt =  searchParam && searchParam.Court && searchParam.Court[0]
                                    ? searchParam.Court[0].court_name
                                    : "";
                      
                    metaDesc = "Explore our comprehensive directory to find the best lawyers in ";

                    if(expertiseTxt) {
                        newDescTxt = "Best "+expertiseTxt+" Lawyers";
                    }
        
                    if(cityTxt) {
                        newDescTxt = newDescTxt != '' ? newDescTxt : ' Best Lawyers';
                        newDescTxt += " in "+cityTxt;
                        metaDesc +=cityTxt+", "; 
                    } else if (countryTxt) {
                        newDescTxt = newDescTxt != '' ? newDescTxt : ' Best Lawyers';
                        newDescTxt += " in "+countryTxt;
                    }
                    metaDesc += countryTxt;
                    if(practiceTxt == "" && expertiseTxt == "") {
                        metaDesc += " and locality to suit your specific legal needs. ";
                    } else {
                        metaDesc += ". ";
                        if(expertiseTxt) {
                            metaDesc += expertiseTxt+", ";
                        }
                        if(practiceTxt) {
                            metaDesc +=  practiceTxt+" and";
                        }
                        metaDesc += " locality to suit your specific legal needs. ";
                    }
                    
                    metaDesc += "Our listed lawyers practice in all major courts in "+ ( cityTxt ? cityTxt : countryTxt ) + ".";

                    setTotalRecords(0);
                    setNextPage(0);
                    setDescriptionTxt(metaDesc);
                    setLowyerDirectoryData([]);
                    let metaData = apiResult.data.data ? apiResult.data.data[0] : [];
                    if(newDescTxt != '' && Object.keys(metaData).length > 0) {
                        let modifiedMeta = { 
                            meta_title : newDescTxt,
                            meta_description : metaDesc
                        }
                        metaData = { ...metaData , ...modifiedMeta };
                    }
                   
                    setLowyerDirectoryMetaData(metaData);
                    setFilterData([]);
                    if (searchParam) {
                        delete searchParam.pageIndex;
                        setSearchParam(searchParam);
                    } else {
                        setSearchParam({});
                    }
                    /* setSearchParam(
                        localStorage.getItem("searchParam")
                        ? JSON.parse(localStorage.getItem("searchParam"))
                        : ""
                    ); */
                }
                localStorage.removeItem("searchParam");

            } catch (error) {
                console.log("error", error);
            } finally {
                setIsPageUpdating(false);
            }
        };
        if (isPageUpdating) {
            await checkSeoParams();
            GetData();
        }
    }, [isPageUpdating, pageIndex]);

    useEffect(() => {
        if (inView) {
            setIsPageUpdating(true);
        }
    }, [inView]);

    const OnChange = (e) => {
        setSearchParam(e);
        if(Object.keys(e).length > 0) {
            let push_arr = '';
            let country_slug = "india";       
            for(let x in e) {
                if(x === "country" && ((e[x] && e[x][0]?.country_slug) || country_slug)) {
                    push_arr += ((e[x] && e[x][0]?.country_slug) || country_slug)+"/";
                } else if (x === "statecity" && e[x] && e[x][0]?.state_slug) {
                    push_arr += e[x][0]['state_slug']+"/";
                    push_arr += e[x][0]['city_slug']+"/"
                } else if (x === "Area" && e[x] && e[x][0]?.expertise_slug) {
                    push_arr += e[x][0]['expertise_slug']+"/";
                }
            }
            history.push("/lawyers-directory/"+push_arr);
        }
        setIsPageUpdating(true);
    };

    const onSearch = (e) => {
        let Text = e.target.value;
        if (Text) {
        const filtered = lowyerDirectoryData.filter((row) => {
            return (
            (row.expertise &&
                row.expertise[0].user_expertise_name
                .toLowerCase()
                .includes(Text.toLowerCase())) ||
            (row.court_data &&
                row.court_data[0].user_court_name
                .toLowerCase()
                .includes(Text.toLowerCase())) ||
            (row.user_full_name &&
                row.user_full_name.toLowerCase().includes(Text.toLowerCase())) ||
            (row.emp_membership &&
                row.emp_membership.toLowerCase().includes(Text.toLowerCase())) ||
            (row.user_address1 &&
                row.user_address1.toLowerCase().includes(Text.toLowerCase())) ||
            (row.user_experience &&
                row.user_experience.toLowerCase().includes(Text.toLowerCase()))
            );
        });
        setFilterData(filtered);
        } else {
        setFilterData(lowyerDirectoryData);
        }
    };
    
    async function getCityStateData (country_id) {
        if(isNaN(country_id) || parseInt(country_id) <= 0) {
            return true;
        }
        const apiSetting = {
            method: "GET",
            apiParams: { country_id: country_id , from : "lawyer_directory" },
        };

        const apiResult = await apiRequest("get_city_master", apiSetting);
        if (apiResult.data.settings.success === "1") {
			const city_state_data = apiResult.data.data.filter((row1) => {
                return (row1.city = row1.city + " - " + row1.state);
            });
            setStateCityData(city_state_data);
            return city_state_data;
        }
        return true;
    }   

    /*useEffect( () => {
        setCountryArr(countrydata);
        setExpertiseArr(expertiseMasterData);
    },[countrydata, expertiseMasterData]);*/

    const meta = {
        title: lowyerDirectoryMetaData && lowyerDirectoryMetaData.meta_title,
        description:
        lowyerDirectoryMetaData && lowyerDirectoryMetaData.meta_description,
        meta: {
            charset: "utf-8",
            name: {
                keywords:
                lowyerDirectoryMetaData && lowyerDirectoryMetaData.meta_key_words,
            },
        },
    };

    const viewLawyerDetail =  async (inquery_obj = {}) => {
        setSelectedLawyer(inquery_obj?.user_master_id);
        let profile_access = await getVariable('profile_access',true);
        let cookie_time = localStorage.getItem('user_cookie_time');
        let cur_time = new Date().getTime();
        if((cookie_time > cur_time) && profile_access) {
            await saveInquiryLog([{
                'selected_lawyer_id' : inquery_obj?.user_master_id,
                'inquiry_mobile_number' : profile_access.mobile_no,
                'request_on' : inquery_obj?.request_on,
                'user_name' : profile_access.user_name
            }]);
        } else {
            setRequestOn(inquery_obj?.request_on);
            localStorage.removeItem('profile_access');
            setOpenModal(true);
        }
    }
    const saveInquiryLog = async (data) => {
        if(data && data[0]) {
            let request_on = data[0].request_on
            const apiSetting = {
                method: "POST",
                apiParams: {
                    user_name : data[0].user_name,
                    lawyer_id : data[0].selected_lawyer_id,
                    mobile_no : data[0].inquiry_mobile_number,
                    request_from : "Listing",
                    request_on : request_on
                 },
            };

            const apiResult = await apiRequest("add_lawyer_info", apiSetting);
            if(apiResult.data.settings.success === "1") {
                saveVariable("profile_access",{ user_name : data[0].user_name,  mobile_no : data[0].inquiry_mobile_number});
                if(clickedLawyers == undefined) {
                    clickedLawyers = {
                        "Whatsapp" : [],
                        "Mobile" : []
                    };
                }
                
                clickedLawyers[request_on].push(data[0].selected_lawyer_id);
                saveVariable("selLawyers",clickedLawyers);
                setViewLayers(clickedLawyers);
            }
        }
    }

  if (isPageUpdating) {
    return <Loader />;
  } else {
    return (
      <DocumentMeta {...meta}>
        <div className="inner-page ldirectory-page directory-listing-page">
          <div className="container">
            <div className="row">
              <div className="inner-head d-flex align-item-center">
                <div className="inner-head-left">
                  <div className="page-title  text-start">
                    <h2>
                      {lawyerDirectoryLabel && lawyerDirectoryLabel.gm_heading
                        ? lawyerDirectoryLabel.gm_heading
                        : "Lawyer Directory"}
                    </h2>
                  </div>
                </div>
               
                {openModal && <Modal
                    title="Lawyer Info"
                    id="lawyerinquiryPopup"
                    button_hide={true}
                    isOpen={true}
                    btnCoverClass="btn-primary"
                    btnClass="mdi mdi-plus"
                    child={LawyerInquiry}
                    saveInquiryLog={saveInquiryLog}
                    selectedLawyer = {selectedLawyer}
                    setOpenModal={setOpenModal}
                    request_on = {requestOn}
                    {...props}
                />}
            
                <div className="inner-header-rgt ms-auto d-flex align-items center">
                  <div className="search-field with-filter">
                    <div className="form-row">
                      <div className="form-input-div">
                        <input
                          type="text"
                          placeholder="Search"
                          className="form-control"
                          onChange={onSearch}
                        />
                        <i className="search-icon">
                          <img src={iconSearch} className="svg" alt="" />
                        </i>
                      </div>
                    </div>
                    <button className="filter-toggler" onClick={toggle}>
                      filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12 order-1 directory-search ">
                <div
                  className={
                    isOpen
                      ? "right-search-block search-hide"
                      : "right-search-block search-show"
                  }
                >
                  <div className="right-block-title d-flex flex-column">
                    <h2 className="mb-5">
                      Filter <span>Results</span>
                    </h2>
                  </div>

                  <HomeSearch
                    page="Lowyer"
                    searchParam={searchParam}
                    OnChange={OnChange}
                  />
                </div>
              </div>
                <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12">
                    <p className="common-paragraph">{descriptionTxt}</p>
                </div>
              <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12 directory-content">
                <div className="common-page-content publication-content">
                  <div className="event-lst-box d-flex justify-content-between flex-column">
                    {filterData.length > 0 ? (
                      filterData.map((row, key) => (
                        <EmployeeCard
                          page="Lowyer"
                          row={row}
                          key={key}
                          {...props}
                          viewLawyerDetail={viewLawyerDetail}
                          displayMaskedNumber = { !maskedNumber || login_user_id > 0 ||(viewLayers && viewLayers.Mobile && viewLayers.Mobile.includes(row.user_master_id.toString())) ? false : true}
                          displayMaskedWhatsapp = { !maskedNumber || login_user_id > 0 || (viewLayers && viewLayers.Whatsapp && viewLayers.Whatsapp.includes(row.user_master_id.toString())) ? false : true}
                        />
                      ))
                    ) : (
                      <NoRecord pageName={props.componentInfo.title} />
                    )}
                  </div>
                  <div>
                    <div className="footer-action">
                      <h3>
                        {" "}
                        Count :{" "}
                        <span>
                          {filterData.length} / {totalRecords}
                        </span>
                      </h3>

                      {nextPage > 0 && (
                        <button
                          // ref={ref}
                          className="btn-primary"
                          onClick={() => {
                            setIsPageUpdating(true);
                            setpageIndex((parseInt(pageIndex) + 1).toString());
                          }}
                        >
                          Load More
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DocumentMeta>
    );
  }
};

export default LawyerDirectory;
