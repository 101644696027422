import { useSetRecoilState } from "recoil";
import { curRouteDetailsState } from "../../../../Helpers/Recoil";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import iconPlus from "./../../../../asset/svg/icon-plus.svg";
import TableData from "../../../../componants/Table/TableData";
import { apiRequest, formatDate } from "../../../../Helpers/General";
import swal from "sweetalert";
import NoRecord from "../../../../componants/NoRecord/NoRecord";
import Loader from "../../../../componants/Loader/Loader";
const Bookings = (props) => {
  let id = props.id
  ? props.id
  : props.userProfile && props.userProfile.user_master_id;
  let user_country_id = props && props.userProfile && props.userProfile.country_id;
  const setCurRouteDetails = useSetRecoilState(curRouteDetailsState);
  function compare(a, b) {
    if (a.user_booking_status < b.user_booking_status) {
      return -1;
    }
    if (a.user_booking_status > b.user_booking_status) {
      return 1;
    }
    return 0;
  }
  useEffect(() => {
    setCurRouteDetails(props.componentInfo);
  });                 
  const [BookingData, setBookingData] = useState([]);
  const [isPageUpdating, setIsPageUpdating] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
   
    const GetMembership = async () => {
      try {
        const apiSetting = {
          method: "GET",
          apiParams: { country_id: user_country_id, user_id: id },
        };
        const apiResult = await apiRequest("get_user_booking_info", apiSetting);

        if (apiResult.data.settings.success === "1") {
          let data = (apiResult.data.data).sort(compare)
          setBookingData(data);
          setIsPageUpdating(false);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsPageUpdating(false);
      }
    };
    if (isPageUpdating) {
      GetMembership();
    }
  }, [isPageUpdating,id]);

  const colModal = [
    {
      dataField: "booking_title",
      text: "Title",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "30%", textAlign: "left" };
      },
    },
    {
      dataField: "user_booking_time",
      text: "Booking Date",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "20%", textAlign: "center" };
      },
      formatter: (cellContent) => {
        return (<div className="text-center">{formatDate("DateTimeSecond",cellContent)}</div>)
      }
    },
    {
      dataField: "meeting_date",
      text: "Event Date",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "20%", textAlign: "center" };
      },
      formatter: (cellContent) => {
        return (<div className="text-center">{formatDate("12HourFormate",cellContent)}</div>)
      }
    },
    {
      dataField: "booking_action",
      text: "Action",
      headerStyle: (colum, colIndex) => {
        return { width: "30%", textAlign: "right" };
      },
      classes: "actionCell",
      formatter: (cellContent, row) => {
        let eventType =row.user_booking_type==="networkmeeting"?"/networking-details/":"/event-detail/"
        return (
          <div className="custom-listview-action">
            <div className="custom-listview-action">
              {row.user_booking_status === "Cancelled" ? (
                 <div title={formatDate("DateTimeSecond",row.ub_updated_date)} className="badge-cancel">Cancelled</div>
              ) : (
                row.booking_cancel_allowed && row.booking_cancel_allowed === "Yes" ? 
                <button
                  onClick={() =>{
                    swal({
                      title: "Are you sure?",
                      text: "Are you sure you want to cancel Booking?",
                      icon: "warning",
                      buttons: ["No", "Yes Please"],
                      dangerMode: true,
                    })
                    .then((willDelete) => {
                      if (willDelete) {
                        cancelBooking(row);
                      }
                    });      
                     }}
                  className="btn-border btn-view btn-cancel custom-btn-link"
                >
                  <i className="mdi mdi-close"></i> cancel
                </button>
                : ""
              )}
              <Link
                to={eventType + row.user_event_network_metting_id}
                className="btn-border btn-view btn-edit"
              >
                <i className="mdi mdi-arrow-right"></i> View Detail
              </Link>
            </div>
          </div>
        );
      },
    },
  ];
  const apiSetting = {
    apiFun: "get_user_booking_info",
    apiParams: { lang_id: "EN", user_id: id },
   /*  apiParams: { lang_id: "EN", voucher_code: "GEN_2011_1016_100008" }, */
  };
  const expandRow = {
    renderer: (row) => <></>,
    showExpandColumn: true,
  };

  const cancelBooking = async (rowid) => {
     setIsLoading(true);
    if (rowid.user_booking_type === "event") {
      let parms = {
        event_id: rowid.user_event_network_metting_id,
        booking_type: rowid.user_booking_type,
        booking_date_time: rowid.user_booking_time,
        booking_status: "Cancelled",
        user_id: id,
        country_id: "",
      };

      try {
        const apiSetting = {
          method: "POST",
          apiParams: parms,
        };
        const apiResult = await apiRequest("regester_for_event", apiSetting);
        if (apiResult.data.settings.success === "1") {
          swal({
            title: "Success",
            text: apiResult.data.settings.message,
            icon: "success",
            button: {
              text: "Done",
              closeModal: true,
            },
            dangerMode: false,
          });
          setIsPageUpdating(true);
        } else {
          swal({
            title: "Error",
            text: apiResult.data.settings.message,
            icon: "warning",
            button: {
              text: "Close",
              closeModal: true,
            },
            dangerMode: true,
          });
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsLoading(false);
      }
    } else {
      let parmsNetwork = {
        network_meeting_id: rowid.user_event_network_metting_id,
        booking_type: "networkmeeting",
        booking_date_time: rowid.user_booking_time,
        booking_status: "Cancelled",
        user_id: id,
        country_id: "102",
      };
      try {
        const apiSetting = {
          method: "POST",
          apiParams: parmsNetwork,
        };
        const apiResult = await apiRequest(
          "regester_for_network_meeting",
          apiSetting
        );
        if (apiResult.data.settings.success === "1") {
          swal({
            title: "Success",
            text: apiResult.data.settings.message,
            icon: "success",
            button: {
              text: "Done",
              closeModal: true,
            },
            dangerMode: false,
          });
          setIsPageUpdating(true)
        } else {
          swal({
            title: "Error",
            text: apiResult.data.settings.message,
            icon: "warning",
            button: {
              text: "Close",
              closeModal: true,
            },
            dangerMode: true,
          });
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsLoading(false);
      }
    }
  };
if(isPageUpdating || isLoading){
  return <Loader/>
}else{
  return (
    <div className="common-inner-profile-page inner-page bookings-page after-login-page">
      <div className="row">
        <div className="col-lg-12">
        <div className="inner-head d-flex align-item-center afterlogin-inner-head">
          <div className="inner-head-left">
            <div className="page-title  text-start">
              <h2>Bookings</h2>
            </div>
          </div>
          <div className="inner-header-rgt ms-auto d-flex align-items center">
            <div className="emp-card-itm">
              <Link to="/networking-details/" className="btn-green custom-btn-link">
                <img src={iconPlus} alt="" /> New Booking
              </Link>
            </div>
          </div>
        </div></div>
      </div>
      <div className="row">
        <div className="booking-table custom-table">
        {BookingData.length>0?  <TableData
            keyField="user_master_id"
            colModal={colModal}
            apiSetting={apiSetting}
            expandRow={expandRow}
            data={BookingData}
          />:<NoRecord pageName="Bookings"/>}
        </div>
      </div>
    </div>
  );
        }
};

export default Bookings;
