import { BrowserRouter } from 'react-router-dom'
import Header from './LoginNotRequired/Auth/Header';
import Routing from './Routing'
import { clearAll,getVariable } from '../Helpers/Session';
import './Css/Layout.css';
import './Css/common-inner.css';
import Footer from './LoginNotRequired/Auth/Footer';
import { useHistory } from 'react-router';
import { useState, useEffect ,useContext } from 'react';
import GoToTop from "../componants/GoToTop/GoToTop"
import { useRecoilValue } from "recoil";
import { curRouteDetailsState } from "../Helpers/Recoil";

import Disclaimer from './LoginNotRequired/Auth/Disclaimer';
import Modal from '../componants/Modal/Modal';
import { MasterContext } from '../Context/index';

function Layout(props) {
  const histoty = useHistory()
  let path = window.location.href.split("/").pop();
  const routeDetails = useRecoilValue(curRouteDetailsState);
  const logoutAction = () => {
    clearAll();
    histoty.push("/")
  }
  const [header, setHeader] = useState("");

  const listenScrollEvent = event => {
    if (window.scrollY < 111) {
      return setHeader("");
    } else if (window.scrollY > 110) {
      return setHeader("header-fixed");
    }
  };
  
  let accept_disclaimer = getVariable("accept_disclaimer", true,false);
  const [openModal, setOpenModal] = useState(
    accept_disclaimer == 'yes' ? false :true
  );
  const {genralMasterData } = useContext(MasterContext);
  let disclaimerDetail = genralMasterData?.DISCLAIMER_CONTENT || '';

  useEffect(() => {
    var width = window.innerWidth;
    if (width > 1000) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  return (
    <BrowserRouter>
      {disclaimerDetail && openModal && <Modal
          title = {disclaimerDetail?.gm_heading || "Disclaimer"} 
          id="disclaimerPopup"
          button_hide={true}
          isOpen={true}
          btnCoverClass="btn-primary"
          btnClass="mdi mdi-plus"
          child={Disclaimer}
          setOpenModal={setOpenModal}
          hide_closebtn = {true}
          {...disclaimerDetail}
      />}
      <div className={"page " + header}>
        {routeDetails && routeDetails.title !== '404NotFound' && <Header logoutAction={logoutAction} userProfile={props.userProfile} />}
        <main className="main-content-area">
          <div className="container-fluid p-0">
            <div className="inner-container">
              <Routing {...props} />
            </div>
          </div>
        </main>
        {routeDetails && routeDetails.title !== '404NotFound' && path.toUpperCase() !== ("reset-Password").toUpperCase() && <button className="top-btn" onClick={scrollToTop} >Top</button>}
        {routeDetails && routeDetails.title !== '404NotFound' && path.toUpperCase() !== ("reset-Password").toUpperCase() && <Footer {...props} />}
      </div>
      <GoToTop />
    </BrowserRouter>

  );
}
export default Layout;