
import { useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { curRouteDetailsState } from "../../../../Helpers/Recoil";
import { useEffect } from "react";
import { apiRequest, formatDate } from "../../../../Helpers/General";
import { useState } from "react";
import swal from "sweetalert";
const InactiveRequest = (props) => {


  const { dataid } = props
  let user_id = props.id
    ? props.id
    : props.userProfile && props.userProfile.user_master_id;
  const setCurRouteDetails = useSetRecoilState(curRouteDetailsState);
  //const [isPageLoading, setIsPageLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit, 
    //setValue,
    //clearErrors,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setCurRouteDetails(props.componentInfo);
  });
  const onSubmit = async (data) => {
    setLoading(true);
    let parms = {
      master_id:dataid,
      type:props.requestType,
      reason :data.reason,
      user_id : user_id,
      req_date_time: formatDate("systemDate")
    };
    try {
      const apiSetting = { method: "POST", apiParams: parms };
      const apiResult = await apiRequest(
        "job_publication_inactive_log",
        apiSetting
      );
      if (apiResult.data.settings.success === "1") {
        // setIsOpen(true);
        swal({
          title: "Success",
          text: apiResult.data.settings.message,
          icon: "success",
          buttons: "OK",
          dangerMode: false,
        }).then((shouldCreateNew) => {
          props.setIsPageUpdating(true);
          props.closeModal()
        });
        
      } else {
        swal({
          title: "Error",
          text: apiResult.data.settings.message,
          icon: "warning",
          button: {
            text: "Close",
            closeModal: true,
          },
          dangerMode: true,
        });
        props.closeModal()
        throw new Error(apiResult.data.settings.message);
      }
    } catch (error) {
      console.log("error", error);
    }finally{
      setLoading(false);
    }

  };

  return (
    <div className="inner-page add-publications after-login-page">
      <div className="container">
        <div className="row">
          <form
            id="documents-add-hook-form-AddPublication"
            onSubmit={handleSubmit(onSubmit)}
          >
            <input
              type="hidden"
              id="inputId"
              className="form-control"
              {...register("id")}
            />
            <div className="form-section">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="form-row mb-4">
                    <label htmlFor="reason" className="float-start mb-1">
                      Reason for Inactive <em>*</em>
                    </label>
                 
                      <textarea
                        id="reason"
                        className="form-control custom-textarea"
                        placeholder="Reason for Inactive"
                        rows="4"
                        {...register("reason", { required: "Reason for Inactive is required" })}
                        autoFocus
                      />
                      <div className="input-err">{errors?.reason?.message}</div>
                   
                  </div>
                </div>                      
              </div>
            </div>
            <div className="profile-action-area">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="form-row">
                  {loading ?<button
                            form="contact-person-add-hook-form"
                            className="w-20 btn btn-primary m-auto"> 
                            <div className="spinner-border text-white  text-center" role="status">
                            <span className="sr-only">Loading...</span>
                        </div> </button>:   <button
                      className="btn-primary"
                      form="documents-add-hook-form-AddPublication"
                      type="submit"
                    >
                      Submit
                    </button>}
                  </div>
                </div>
              </div>
            </div>{" "}
          </form>
        </div>
      </div>
    </div>
  );
};

export default InactiveRequest;
