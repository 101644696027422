import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { curRouteDetailsState } from "../../../../Helpers/Recoil";
import { apiRequest } from "../../../../Helpers/General";
import ImageUploadMultiple from "../../../../componants/ImageUpload/ImageUploadMultiple";
import SelectBox from "../../../../componants/SelectBox/SelectBoxForUpgradeRanking";
import swal from "sweetalert";
import { useParams } from "react-router";
import pdf from '../../../../asset/icons/pdf.png'
import jpg from '../../../../asset/icons/jpg.png'
import excel from '../../../../asset/icons/excel.png'
import txt from '../../../../asset/icons/txt.png'
import doc from "../../../../asset/icons/word.png"
import image from '../../../../asset/icons/image.png'
import { getVariable } from "../../../../Helpers/Session";
const UpgradeRankingRandom = (props) => {
  const { id } = useParams();
  const setCurRouteDetails = useSetRecoilState(curRouteDetailsState);
  useEffect(() => {
    setCurRouteDetails(props.componentInfo);
  });
  const userProfile = getVariable("user");
  const [lawyerRankingData, setLawyerRankingData] = useState([]);
  const [isPageUpdating, setIsPageUpdating] = useState(true);
  const [lawyerRankingDetails, setLawyerRankingDetails] = useState([]);
  const [lawyerRanking, setLawyerRanking] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [selectedExperty, setSelectedExperty] = useState([]);
  const [filesUrls, setFilesUrls] = useState([]);
  const [selectedFilesUrls, setSelectedFilesUrls] = useState([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState([]);
  useEffect(() => {
    if (filesUrls) {
      setTimeout(() => {
        setSelectedFilesUrls(filesUrls)
      }, 0)
    }
  }, [filesUrls])
  useEffect(() => {
    const GetData = async () => {
      try {
        const apiSetting = {
          method: "GET",
          apiParams: {
            country_id: "102",
          },
        };
        const apiResult = await apiRequest("get_lawyer_ranking", apiSetting);

        if (apiResult.data.settings.success === "1") {
          setLawyerRankingData(apiResult.data.data.rank_master_data);
          setLawyerRanking(apiResult.data.data.rank_master_data);
          setIsPageUpdating(false);
        } else {
          setLawyerRankingData([]);
          setLawyerRanking([]);
          setIsPageUpdating(false);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsPageUpdating(false);
      }
    };
    const GetDetails = async () => {
      try {
        const apiSetting = {
          method: "GET",
          apiParams: {
            user_id: userProfile && userProfile.user_master_id === id ? id : "0",
          },
        };
        const apiResult = await apiRequest("upgrade_rank_detail", apiSetting);
        if (apiResult.data.settings.success === "1") {
          setLawyerRankingDetails(apiResult.data.data.expertise_rank_data.ranking_data);
          setIsPageUpdating(false);
        } else {
          setLawyerRankingDetails([]);

          setIsPageUpdating(false);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsPageUpdating(false);
      }
    };
    if (isPageUpdating) {
      GetData();
      GetDetails();
    }
    // eslint-disable-next-line
  }, [isPageUpdating, id]);
  useEffect(() => {
    if (lawyerRankingDetails) {
      changeSelect([lawyerRankingDetails[0]])
    }
    // eslint-disable-next-line
  }, [lawyerRankingDetails])
  const changeSelect = (e) => {

    setFilesUrls([])
    setSelectedFilesUrls([])
    setValue([])
    setLawyerRanking([])
    setSelectedValue(e)
    setLawyerRanking(lawyerRankingData);
    setSelectedExperty(e[0])
  }
  const onSubmit = async () => {
    try {
      setLoading(true);
      if (!selectedValue[0]) {
        swal({
          title: "Error",
          text: "Please Select Experties First",
          icon: "warning",
          button: {
            text: "Close",
            closeModal: true,
          },
          dangerMode: true,
        })
        return false
      }
      if (value.length === 0) {
        swal({
          title: "Error",
          text: "Please Select Document First",
          icon: "warning",
          button: {
            text: "Close",
            closeModal: true,
          },
          dangerMode: true,
        })
        return false
      }


      if (value) {
        let file = {
          ranking_documents: value,
        };
        const apiSetting = {
          method: "POST",
          apiParams: {
            user_id: id,
            current_rank_id: selectedValue[0] && selectedValue[0].current_rank_id ? selectedValue[0].current_rank_id : "0",
            new_upgrade_rank_id: (selectedExperty && selectedExperty.ranking_master_id) || (selectedValue[0] && selectedValue[0].rank_m_id),
            expertise_id: selectedValue[0] && selectedValue[0].expertise_id,
            user_rank_history_id: selectedValue[0] && selectedValue[0].rank_history_id,
            country_id: "102",
          },
          fileInput: file
        };

        const apiResult = await apiRequest("upgrade_ranking_request", apiSetting);

        if (apiResult.data.settings.success === "1") {
          swal({
            title: "Thank You!",
            text: apiResult.data.settings.message,
            icon: "success",
            button: {
              text: "Ok",
              closeModal: true,
            },
            dangerMode: false,
          }).then((willDelete) => {
            setIsPageUpdating(true);
          })
        
        
        } else {
          swal({
            title: "Error",
            text: apiResult.data.settings.message,
            icon: "warning",
            button: {
              text: "Close",
              closeModal: true,
            },
            dangerMode: true,
          })
        }
      } else {
        // ReactToast({
        //   type: "error",
        //   message: "Please select",
        // });
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };
  const removeDocument = (id) => {

    let filterData = filesUrls.filter(item => item.file_name !== id);
    let tempValue = [...value];
    const tmpValue = tempValue.filter((obj) => {
      return obj.name !== id;
    });

    setValue(tmpValue);
    setFilesUrls(filterData);

  }

  const onRankSelect = (row) => {
    if (row.rank_level > (selectedValue[0] && selectedValue[0].current_rank_level) && selectedValue[0].approval_status !== "Pending") {
      setSelectedExperty(row);
      setSelectedFilesUrls([])
    }
  }

  return (
    <div className="inner-page upgrade-ranking-page ranking-page">
      <div className="container">
        <div className="row">
          <div className="form-row custom-dropdown">
            <label htmlFor="inputCurrency" className="float-start mb-1 d-flex">
              Area of Expertise
            </label>
            <div className="form-input position-relative">
              {lawyerRankingDetails[0] && <SelectBox
                className="mdi mdi-chevron-down"
                name="areaofexpertise"
                labelKey="expertise_name"
                valueKey="expertise_id"
                placeholder=""
                defaultValue={[lawyerRankingDetails[0]]}
                defaultSelected={[lawyerRankingDetails[0]]}
                options={lawyerRankingDetails}
                OnChange={changeSelect}
                selectBoxFor="upgradeRanking"
              />}
            </div>
          </div>
          <div className="ranking-list">
            <div className="ranking-itm ranking-upcoming ranking-color-0">
              <div className="row">
                <div className="">
                  <div className="upgrade-ranking-badges">
                    <ul>
                      {lawyerRanking.length > 0 &&
                        lawyerRanking.map((row, key) => {
                          return <li key={key} className={((selectedExperty && selectedExperty.rank_level === row.rank_level) || (selectedValue[0] && selectedValue[0].current_rank_level === row.rank_level)) ? "active-rank" : "no-rank"} onClick={() => onRankSelect(row)} >
                            <div className="emp_ranking cursor-on-bage">
                              <img src={row.rank_image} alt={row.alt_for_rank_image ? row.alt_for_rank_image : ""} />
                              <h3>{selectedValue[0] && selectedValue[0].expertise_name ? selectedValue[0].expertise_name : "Your Expertise"}</h3>
                            </div>
                            <h4>
                              <span>{row.rank_level === (selectedValue[0] && selectedValue[0].rank_level) ? "Next " : row.rank_level === (selectedValue[0] && selectedValue[0].current_rank_level) ? "Current " : ""}Grading</span>
                              {row.rank_name}
                            </h4>
                          </li>
                        }
                        )}
                      {/* <li className="active-rank">
                        <img src={ImgUpgradeRanks} alt="" />
                        <h4>
                          <span>Grading</span> Upcoming Star
                        </h4>
                      </li> */}

                    </ul>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  {selectedValue[0] && selectedValue[0].approval_status === "Pending" && <div className="ranking-review">
                    Under Review : <span>{selectedValue[0].rank_name}</span>
                  </div>}

                </div>
              </div>
              {selectedExperty && <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-left">
                  <div className="ranking-content">
                    <p className="common-paragraph">
                      Kindly check the below requirements for the next rank and
                      upload the supporting documents
                    </p>

                    <h2>
                      <span>Next Rank</span>{
                        selectedExperty && selectedExperty.rank_name
                      }
                    </h2>
                    <div className="ranking-description"
                      dangerouslySetInnerHTML={{
                        __html: selectedExperty && selectedExperty.rank_description,
                      }}
                    >
                    </div>

                  </div>
                </div>
              </div>}
            </div>
          </div>
        </div>
        {selectedValue[0] && <div className="row">
          <div className="col-lg-4">
            <div className="form-row">
              <label htmlFor="inputAttachments" className="float-start mb-3">
                Upload Supporting Documents
              </label>

              <div className="upload-document-block input-group">
                <ImageUploadMultiple
                  type="text"
                  name="ranking_documents"
                  id="inputAttachments"
                  className="form-control"
                  placeholder="Image Upload"
                  // {...register("imageuploads")}
                  setValue={setValue}
                  setFilesUrls={setFilesUrls}
                  existingFileArr={filesUrls}
                  value={value}
                  disabled={selectedValue[0].approval_status === "Pending" ? true : false}
                  suportedFormate="pdf, image , doc"
                //   dataId={id}
                //   toBeDeleted={toBeDeleted}
                //   setToBeDeleted={setToBeDeleted}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="document-list">
              <ul>
                {selectedFilesUrls.map((item, key) => {
                  let type = item.file_name.split('.').pop();
                  return <li key={key} >
                    <div className="documant-icon"><img alt="" src={type === "pdf" ? pdf : type === "image" ? image : type === "jpg" ? jpg : type === "ods" || type === "xls" ? excel : type === "doc" || type === "docx" ? doc : txt} /></div>
                    <label> {item.file_name}</label>
                    <span onClick={() => removeDocument(item.file_name)} className="close-link"><i className="mdi mdi-close"></i></span>
                  </li>
                }
                )}
                {selectedValue[0] && selectedValue[0].uploaded_docs && selectedValue[0].uploaded_docs.map((item, key) => {
                  let type = item.split('.').pop();
                  return <li key={key} >
                    <div className="documant-icon"><img alt="" src={type === "pdf" ? pdf : type === "image" ? image : type === "jpg" ? jpg : type === "ods" || type === "xls" ? excel : type === "doc" ? doc : image} /></div>
                    <label> {item.split("/").pop()}</label>
                  </li>
                }
                )}
              </ul>
            </div>
          </div>

        </div>}
        {selectedValue[0] && <div className="row">
          <div className="page-title text-end">
            <div className="custom-action d-flex float-end">
              {selectedValue[0].approval_status === "Pending" ? "" :
                loading ? <button
                  form="contact-person-add-hook-form"
                  className="w-20 btn btn-primary m-auto">
                  <div className="spinner-border text-white  text-center" role="status">
                    <span className="sr-only">Loading...</span>
                  </div> </button> :
                  <button
                    className="btn-green btn-primary"
                    onClick={() => onSubmit()}
                  >
                    Submit
                  </button>}
            </div>
          </div>
        </div>}
      </div>
    </div>
  );
};

export default UpgradeRankingRandom;
