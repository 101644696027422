import {atom} from "recoil";

export const curRouteDetailsState = atom({
  key: 'curRouteDetails',
  default:{},
});

export const pageTitleState = atom({
  key: 'pageTitle', // unique ID (with respect to other atoms/selectors)
  default:'', // default value (aka initial value)
});

export const classNameState = atom({
  key: 'className', // unique ID (with respect to other atoms/selectors)
  default: '', // default value (aka initial value)
});