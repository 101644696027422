
import { useSetRecoilState } from "recoil";
import { curRouteDetailsState } from "../../../../Helpers/Recoil";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import iconTime from "./../../../../asset/svg/icon-time.svg";
import Modal from "../../../../componants/Modal/Modal";
import { apiRequest, formatDate } from "../../../../Helpers/General";
import moment from "moment";
import NoRecord from "../../../../componants/NoRecord/NoRecord";
import AddJob from "./AddJob";
import DocumentMeta from "react-document-meta";
import { useInView } from 'react-intersection-observer';
//import DeleteActionBox from "../../../../componants/Modal/DeleteActionBox";
import InactiveRequest from "../Publication/InactiveRequest";
import swal from "sweetalert";
import Loader from "../../../../componants/Loader/Loader";
const JobOpportunity = (props) => {
  const setCurRouteDetails = useSetRecoilState(curRouteDetailsState);
  useEffect(() => {
    setCurRouteDetails(props.componentInfo);
  });
  let id = props.id
    ? props.id
    : props.userProfile && props.userProfile.user_master_id;
  const [JobOprtunity, setJobOprtunity] = useState([]);
  const [JobOprtunityMeta, setJobOprtunityMeta] = useState([]);
  const [isPageUpdating, setIsPageUpdating] = useState(true);
  const [doRefresh, setDoRefresh] = useState(false);
  const [pageIndex, setpageIndex] = useState("1");
  const [totalRecords, setTotalRecords] = useState(0);
   // eslint-disable-next-line
  const [ref, inView] = useInView({
    threshold: 0,
  })
  useEffect(() => {
    const getJobOpportunity = async () => {
      try {
        const apiSetting = {
          method: "GET",
          apiParams: {
            posted_by_id: id,
            page_index: pageIndex,
            current_date: props.userProfile && props.userProfile.user_master_id ? "" : formatDate("systemDate"),
          },
        };
        const apiResult = await apiRequest("get_job_master_list", apiSetting);
        if (apiResult.data.settings.success === "1") {
          let data = apiResult.data.data.job_master_data
          if (pageIndex > 1) {
            JobOprtunity.push(...data);
            setIsPageUpdating(false);
          } else {
            setJobOprtunityMeta(apiResult.data.data.employment_meta_data[0])
            setJobOprtunity(apiResult.data.data.job_master_data);
            setTotalRecords(apiResult.data.settings.count);
            setIsPageUpdating(false);
          }
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsPageUpdating(false);
        setDoRefresh(false);
      }
    };
    if (isPageUpdating || doRefresh) {
      getJobOpportunity();
    }
     // eslint-disable-next-line
  }, [isPageUpdating, doRefresh, id]);

  useEffect(() => {
    if (inView) {
      setIsPageUpdating(true);
      setpageIndex((parseInt(pageIndex) + 1).toString())
    }
     // eslint-disable-next-line
  }, [inView])


  /* const deletePublication = (rowId) => {
    const apiSetting = {
      method: "POST",
      apiParams: {
        user_id: id,
        job_master_id: rowId,
        mode: "delete"
      },
    };
    DeleteActionBox(
      "delete_job",
      apiSetting,
      doRefresh,
      setDoRefresh,
    );
  } */

  const meta = {
    title: JobOprtunityMeta && JobOprtunityMeta.meta_title,
    description: JobOprtunityMeta && JobOprtunityMeta.meta_description,
    meta: {
      charset: "utf-8",
      name: {
        keywords: JobOprtunityMeta && JobOprtunityMeta.meta_key_words,
      },
    },
  };
  let loadMore = Math.ceil(totalRecords / 20);
  if (isPageUpdating) {
    return <Loader />
  } else {
    return (
      <DocumentMeta {...meta}>
        <div className="common-inner-profile-page inner-page jobopportunity-page after-login-page">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner-head d-flex align-item-center afterlogin-inner-head">
                <div className="inner-head-left">
                  <div className="page-title  text-start">
                    <h2>Job Opportunities</h2>
                  </div>
                </div>
                {!props.type && (
                  <div className="inner-header-rgt ms-auto d-flex align-items center">
                    <div className="emp-card-itm">
                      {/*     <Link to="/AddJob" className="btn-green custom-btn-link">
                  <img src={iconPlus} alt="" /> Job Post
                </Link> */}

                      <Modal
                        btnCoverClass="btn-green custom-btn-link"
                        title="Add Job"
                        btnTitle="Job Post"
                        btnClass="add-icon"
                        userId={id}
                        child={AddJob}
                        setDoRefresh={setDoRefresh}
                      // onChange={onChangeProfile}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="common-page-content publication-content">
                <div className="event-lst-box d-flex justify-content-between">
                  {JobOprtunity.length > 0 ? (
                    JobOprtunity.map((item, key) => {
                      let plainEmpTitle = item.job_designation.replace(/[^a-z0-9" "]/gi,"").substring(0, 180).split(" ").filter(Boolean).join("-").toLowerCase();
                      if (plainEmpTitle.includes("?") === true) {
                        plainEmpTitle = plainEmpTitle.split("?").join("-");
                      }
                      plainEmpTitle = plainEmpTitle + " " + item.job_posted_by.trim();
                      plainEmpTitle = plainEmpTitle.split(" ").join("-").toLowerCase();
                      const EmpLinkurl = "/employment-detail/" + plainEmpTitle + "-" + item.job_master_id
                      return <div className={"card-itm inactive_req_status_" + item.inactive_req_status.toLowerCase() + " status_" + item.job_status.toLowerCase()} key={key}>
                        <div className="card">
                          <div className="card-content pt-0">
                            <div className="card-content-top d-flex">
                              <div className="card-title w-50 float-start">
                                <Link
                                  to={EmpLinkurl}
                                >
                                  {" "}
                                  {item.job_designation}
                                </Link>
                                {/* {item.job_designation} */}
                              </div>
                              {/* <div className="post-type ms-auto">
                            Posted by:
                            <span className="highlight-text">
                              {item.job_posted_by ? item.job_posted_by : "--"}
                            </span>
                          </div> */}
                            </div>
                            <small className="w-100 d-block m-auto">
                              {item.job_location} {item.job_city ? ", " + item.job_city : ""} {" , "}{item.job_state}{" , "}
                              {item.job_country}
                            </small>
                            <p className="common-paragraph">
                              {item.job_description}
                            </p>
                          </div>

                          <div className="card-footer">
                            {!props.type && item.job_status === "Active" && <div className="card-action px-md-2">
                              <Modal
                                btnCoverClass="btn-border btn-view btn-edit custom-btn-link"
                                title={item.inactive_req_status === "Pending" ? "Request For Inactive Job Opportunity Is Pending" : "Request For Inactive Job Opportunity"}
                                disabled={item.inactive_req_status === "Pending" ? true : false}
                                btnTitle="Inactive"
                                btnClass="mdi mdi-delete"
                                id={id}
                                requestType="Job"
                                child={InactiveRequest}
                                setIsPageUpdating={setDoRefresh}
                                dataid={item.job_master_id}
                              // onChange={onChangeProfile}
                              />
                            </div>}
                            {!props.type && item.job_status === "Active" && (
                              <div className="card-action">
                                {/* <Link
                              to={"/EditJob/" + item.job_master_id}
                              className="btn-border btn-view btn-edit custom-btn-link"
                            >
                              <i className="edit-icon"></i>
                              Edit
                            </Link> */}
                                <Modal
                                  btnCoverClass="btn-border btn-view btn-edit custom-btn-link"
                                  title="Edit Job"
                                  btnTitle="Edit"
                                  btnClass="edit-icon"
                                  id={item.job_master_id}
                                  userId={id}
                                  child={AddJob}
                                  setDoRefresh={setDoRefresh}
                                // onChange={onChangeProfile}
                                />
                              </div>
                            )}


                            {/* <div className="card-action mr-2">
                                <button type="button" className="btn-border btn-view btn-edit custom-btn-link" onClick={()=> deletePublication( item.job_master_id)}>
                                      <i className="mdi mdi-delete"></i> Delete
                                      </button> 
                                      </div> */}
                            {!props.type && <div className="posted-status ml-4">

                              <i className="">
                                <img
                                  src={iconTime}
                                  className="svg"
                                  alt=""
                                />
                              </i>
                              Job Status :
                              <span
                                className={
                                  item.job_status === "Active" ? "badge-publication-active" : " badge-publication-inactive"
                                }
                                onClick={() => {
                                  item.job_status === "Inactive" &&
                                    swal({
                                      title: "",
                                      text: "Reason of inactive : " + item.reason_for_inactive_req_by_user,
                                      icon: "info",
                                      button: {
                                        text: "Close",
                                        closeModal: true,
                                      },
                                      dangerMode: true,
                                    });
                                }}
                              >
                                {
                                  item.job_status
                                }
                              </span>
                            </div>}
                            <div className="publication-date">
                              <i className="">
                                <img src={iconTime} className="svg" alt="" />
                              </i>
                              Application Closes :&nbsp;
                              {moment(item.job_application_deadline).format(
                                "DD/MM/YYYY"
                              )}
                            </div>
                            <div className="publication-date">
                              <i className="">
                                <img src={iconTime} className="svg" alt="" />
                              </i>
                              Posted On :{" "}
                              {moment(item.job_posted_date).format("DD/MM/YYYY")}
                            </div>

                          </div>
                        </div>
                      </div>
                    }
                    )
                  ) : (
                    <NoRecord pageName="Job Opportunity" />
                  )}
                </div>
                <div>
                  <div className="footer-action">
                    <h3> Count : <span>{JobOprtunity.length} / {totalRecords}</span></h3>

                    {loadMore > pageIndex && <button
                      // ref={ref} 
                      className="btn-primary" onClick={() => {
                        setIsPageUpdating(true);
                        setpageIndex((parseInt(pageIndex) + 1).toString())
                      }}>Load More</button>
                    }

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DocumentMeta>

    );
  }
};

export default JobOpportunity;
