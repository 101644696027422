const ProfileStatistics = (props) => {
  const ShowProfileStates = () => {
    if (props.data) {
      return Object.values(props.data).map((value, key) => {
        return (
          <div key={key} className="profile-stats">
            <span>
              {value.profile_stat === "unlimited" ? (
                <b className="unlimited_symbole">&#8734;</b>
              ) : (
                value.profile_stat
              )}
            </span>
            <label>{value.param_title}</label>
          </div>
        );
      });
    } else {
      return (
        <div className="profile-stats">
          <label>Data is not available.</label>
        </div>
      );
    }
  };
  return (
    <div className="profile-statistics">
      <ShowProfileStates />
    </div>
  );
};

export default ProfileStatistics;
