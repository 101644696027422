import { Route, Switch } from "react-router-dom";
import RoutesArr from "./RoutesArr";
import { imgSvg } from '../Helpers/General'
import NotFound from './LoginNotRequired/Auth/404NotFound'
const Routing = (props) => (
  
  <div className="routing">
    <Switch>
      {RoutesArr.map((row, index) => {
        if(props.userProfile){
          return (
            <Route key={index} exact path={row.path}>
              <row.component componentInfo={row} {...props} />
            </Route>
          );
        }else{
          if(!row.loginRequred){
          return (
            <Route key={index} exact path={row.path}>
              <row.component componentInfo={row} {...props} />
            </Route>
          );
          }
          else return "";
        }     
      })}
       <Route component={NotFound} />
    </Switch>
    
  </div>
);
imgSvg();
export default Routing;
