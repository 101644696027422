import React from "react";
import { useForm } from "react-hook-form";
const AwardForm = (props) => {

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    let formData = props.previesData;
    let row_id = (formData.length + 1) * -1;



    formData.push({
      awards_id: row_id,
      award_name: data.award_name.trim(),
      year: data.year,
      user_awards_id: row_id,
      user_award_name: data.award_name.trim(),
      user_award_year: data.year,
    });


    props.setLineInfo(formData);
    props.setDoRefresh(Math.random());
    props.closeModal();
  }

  return (
    <div
      className="ReactModal-namechange-content-container ReactModal-Content-Container"
      id="name_change_form"
    >
      <div className="ReactModal-middle-container">
        <form
          id="contact-person-add-hook-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <input
            type="hidden"
            id="inputId"
            className="form-control"
            {...register("id")}
          />
          {/* <div className="form-group">
            <div className="profile-content width75">  */}
          <div className="form-group">
            <div className="form-row mb-3 ">
              <label htmlFor="inputAwardName" className="float-start mb-1">
                Award Name <em>*</em>
              </label>
              <div className="input-group">
                <input
                  type="text"
                  id="inputAwardName"
                  className="form-control"
                  placeholder="Name"
                  {...register("award_name", {
                    required: "Award Name ",
                  })}
                />


              </div>
              <div className="input-err">{errors?.award_name?.message}</div>
            </div>
          </div>
          <div className="form-group">

            <div className="form-row mb-3 ">
              <label htmlFor="inputYear" className="float-start mb-1">
                Year<em>*</em>
              </label>
              <div className="input-group">
                <input
                  type="text"
                  id="inputYear"
                  className="form-control"
                  placeholder="e.g. 2015"
                  defaultValue={props.previesData.user_award_year}
                  {...register("year", {
                    required: "Year is required",
                    maxLength: {
                      value: 4,
                      message:
                        "Invalid Value",
                    },
                    minLength: {
                      value: 4,
                      message:
                        "Invalid Value",
                    },
                  })}
                />
              </div>
              <div className="input-err">{errors?.year?.message}</div>
            </div>

          </div>

        </form>
      </div>
      <div className="ReactModal-Footer">
        <div className="ReactModal-ActionBlock profile-action-area">
          <div className="btn-grp m-auto">
            <button
              form="contact-person-add-hook-form"
              className="w-20 btn btn-primary m-auto"
              onClick={handleSubmit(onSubmit)}
            >
              <i className="mdi mdi-check"></i> Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AwardForm;
