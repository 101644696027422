import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { apiRequest } from "../Helpers/General";
const MasterContext = React.createContext();

const ContextProvider = (props) => {
    const [isapicalling, setIsApiCalling] = useState(true);
    const [categorymasterdata, setCategoryMasterData] = useState([]);
    const [countrydata, setCountryData] = useState([]);
    const [expertiesmasterdata, setExpertiesMasterData] = useState([]);
    const [languagemasterdata, setLanguageMasterData] = useState([]);
    const [rankingmasterdata, setRankingMasterData] = useState([]);
    const [courtMasterData, setcourtMasterData] = useState([]);
    const [expertiseMasterData, setExpertiseMasterData] = useState([]);
    const [genralMasterData, setGenralMasterData] = useState([]);
    const [userDetailByUrl, setUserDetailByUrl] = useState();  
    useEffect(() => {
        if (isapicalling) {
            GetData();
            getGeoInfo();
        }
    }, [isapicalling])

    const GetData = async () => {
        const apiSetting = {
            method: "GET", apiParams: { country_id: "" },
        };
        const apiResult = await apiRequest("get_common_master_list", apiSetting);
        if (apiResult.data.settings.success === "1") {
            setCategoryMasterData(apiResult.data.data.category_master_data);
            setCountryData(apiResult.data.data.country_data);
            setExpertiesMasterData(apiResult.data.data.expertise_master_data);
            setLanguageMasterData(apiResult.data.data.language_master_data);
            setRankingMasterData(apiResult.data.data.ranking_master_data);
            setcourtMasterData(apiResult.data.data.court_master_data);
            setExpertiseMasterData(apiResult.data.data.expertise_master_data);
            setGenralMasterData(apiResult.data.data.general_data.general_master_final_data)
            setIsApiCalling(false)
        }
    }
    const getGeoInfo = () => {
        axios.get('https://ipapi.co/8.8.8.8/json/').then((response) => {
            let data = response.data;
            setUserDetailByUrl(data);
            
        }).catch((error) => {
            console.log(error);
        });
    };
    return (
        <MasterContext.Provider value={
            {    countrydata,
                courtMasterData,
                expertiseMasterData,
                categorymasterdata,
                expertiesmasterdata,
                languagemasterdata,
                rankingmasterdata,
                userDetailByUrl,
                genralMasterData
            }
        }>
            {props.children}
        </MasterContext.Provider>
    );
};
export default ContextProvider;
export { MasterContext }