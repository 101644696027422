import React, { useState, useEffect } from "react";
import Modal from "../../../../componants/Modal/Modal";
import UploadVideoForm from "./UploadVideoForm";
import { useSetRecoilState } from "recoil";
import { curRouteDetailsState } from "../../../../Helpers/Recoil";
import { apiRequest } from "../../../../Helpers/General";
import DeleteActionBox from "../../../../componants/Modal/DeleteActionBox";
import NoRecord from "../../../../componants/NoRecord/NoRecord";
import Loader from "../../../../componants/Loader/Loader";
const Videos = (props) => {
  let id = props.id
    ? props.id
    : props.userProfile && props.userProfile.user_master_id;
  let user_country_id = props && props.userProfile && props.userProfile.country_id;
  const [VideoUrls, setVideoUrls] = useState([]);
  const [Urls, setUrls] = useState([]);
  const setCurRouteDetails = useSetRecoilState(curRouteDetailsState);
  useEffect(() => {
    setCurRouteDetails(props.componentInfo);
  });
  const [isPageUpdating, setIsPageUpdating] = useState(true);
  const [doRefresh, setDoRefresh] = useState(false);
  useEffect(() => {
    const GetPhotos = async () => {
      try {
        const apiSetting = {
          method: "GET",
          apiParams: { country_id: user_country_id, user_id: props.userProfile ? props.userProfile.user_master_id : 0, file_type: "Video", posted_by: props.id },
        };
        const apiResult = await apiRequest(
          "get_user_photo_and_video_info",
          apiSetting
        );
        if (apiResult.data.settings.success === "1") {
          setUrls(apiResult.data.data.return_data);
          setIsPageUpdating(false);
        }
        else
        {
          setUrls([]);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
         setIsPageUpdating(false);
      }
    };
    if (isPageUpdating || doRefresh) {
      GetPhotos();
    }
  }, [isPageUpdating, doRefresh, props.id, props.userProfile]);
  const deleteAction = (Gallary_id) => {
    
    const apiSetting = {
      method: "POST",
      apiParams: {
        country_id: user_country_id,
        user_id: id,
        gallery_id: Gallary_id,
        file_type: "Video",
      },
    };
    DeleteActionBox(
      "delete_user_photo_video",
      apiSetting,
      doRefresh,
      setDoRefresh
    );
  };
  // const onsaveUrls = (data) => {
  //   setUrls(data);
  // };
  if (isPageUpdating) {
    return <Loader />;
  } else {
    return (
      <div className="common-inner-profile-page inner-page video-page after-login-page">
        <div className="row">
          <div className="col-lg-12">
            <div className="inner-head d-flex align-item-center afterlogin-inner-head">
              <div className="inner-head-left">
                <div className="page-title  text-start">
                  <h2>Videos</h2>
                </div>
              </div>
              {!props.type && (
                <div className="inner-header-rgt ms-auto d-flex align-items center">
                  <div className="emp-card-itm">
                    <Modal
                      btnCoverClass="btn-green custom-btn-link"
                      title="Upload A Video"
                      btnTitle="Upload Video"
                      btnClass="add-icon"
                      id="AddLogoChildForm"
                      child={UploadVideoForm}
                      setLineInfo={setVideoUrls}
                      previesData={VideoUrls}
                      setIsPageUpdating={setIsPageUpdating}
                      // setVideoUrls={setVideoUrls}
                      dataId={id}
                    // onChange={onsaveUrls}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          {Urls.length > 0 ? (
            Urls.map((row, key) => {             
              let embedId =(row.user_file_name).replace("watch?v=", "").split("/").pop()
              return (
                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 mb-4" key={key}>
                  <div className={"custom-itm status_" + (row.user_approval_status ? row.user_approval_status.toLowerCase():"")} key={key}>
                    <i className="add-icon"></i>
                    {!props.type && (
                      <button onClick={() => deleteAction(row.user_gallery_id)}>
                        <i className="mdi mdi-close"></i>
                      </button>
                    )}
                    <iframe
                      title="Video"
                      key={key}
                      width="300"
                      height="175"
                      src={`https://www.youtube.com/embed/${embedId}`}
                      alt={row.alt_for_gallery?row.alt_for_gallery:""}
                    ></iframe>
                  </div>
                </div>
              );
            })
          ) : (
            <NoRecord pageName="Videos" />
          )}
        </div>
      </div>
    );
  }
};

export default Videos;
