
import { saveVariable } from "../../../Helpers/Session";
const Disclaimer = (props) => {
    const acceptDisclaimer = (e) => {
        saveVariable('accept_disclaimer','yes',true);
        props.closeModal();
    };

  
  return (
    <div className="ReactModal-namechange-content-container ReactModal-Content-Container" id="disclaimer_txt">
        <div className="ReactModal-middle-container">
            <div className="disclaimer-txt">                
                <div dangerouslySetInnerHTML={{ __html: props.gm_short_description }}></div>
            </div>
        </div>
        <div className="ReactModal-Footer">
            <div className="ReactModal-ActionBlock profile-action-area">
                <div className="btn-grp m-auto">
                    <button form="contact-person-add-hook-form" className="w-20 btn btn-primary m-auto" onClick={acceptDisclaimer}>
                        <i className="mdi mdi-check"></i> Accept
                    </button>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Disclaimer;
