import "./EmployeeCard.css";
import { Link } from "react-router-dom";
import iconPhone from "./../../asset/svg/icon-phone.svg";
import iconWhatsup from "./../../asset/images/social/icon-whatsapp.png";
import iconBadgeSmall from "./../../asset/images/badge-small-01.svg";
import Modal from "../../componants/Modal/Modal";
import sendEmail from "../../componants/sendEmail/sendEmail";
import "react-medium-image-zoom/dist/styles.css";
import Avatar from "../Avatar/Avatar";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import moment from "moment";
import { formatUrlLink } from "../../Helpers/General";
import iconStar from "../../../src/asset/svg/star_yellow.png";
import iconStarEmpty from "../../../src/asset/svg/star_gray.png";
import Rating from "react-rating";

const EmployeeCard = (props) => {
  const numArr =
    props.row && props.row.whats_up_no && props.row.whats_up_no.match(/\d/g);
  const whatsupno = numArr && numArr.join("");
  // let whatsupno = props.row && props.row.whats_up_no.replace("+", "")
  let detailUrl = props.page === "GC" ? "/general-counsel/" : "/lawyer-profile/";

  return (
    <>
      
        <div className="card-itm emp-card-itm">
          <div className="card">
            <div className="card-left">
              <div className="emp-pic">
                <Avatar
                  id={props.id}
                  fullName={props.row.user_full_name}
                  pictureUrl={
                    props.row.user_profile_pic ? props.row.user_profile_pic : ""
                  }
                  alt={
                    props.row.alt_for_profile_pic
                      ? props.row.alt_for_profile_pic
                      : ""
                  }
                  size="120px"
                  fontSize="30px"
                ></Avatar>
              </div>
              {props.page === "Lowyer" && props.row.lawyer_verified && props.row.lawyer_verified === "Yes" && (
                <div className="lawyer-verified">
                  <div className="d-flex flex-column text-center mt-less-2">
                    <span>&nbsp;</span>
                  </div>
                </div>
              )}
              {props.row.user_experience && (
                <div className="emp-exp">
                  {props.page === "Lowyer" && (
                    <div className="d-flex flex-column text-center mt-2">
                      <label>Experience</label>
                      <span>
                        {props.row.user_experience
                          ? props.row.user_experience + " Years"
                          : "--"}
                      </span>
                    </div>
                  )}
                </div>
              )}
              
              {props.page === "Lowyer" && props.row.user_rating >= 1 && (
                  
                  <div className="user-review">
                      <div className="user-review-rating-wrap">
                      <div className="d-flex rating-cnt">
                      <div className="rating-count">{props.row.user_rating}</div>
                      <div className="star-review">
                        
                        <Rating 
                          initialRating={props.row.user_rating}  
                          readonly
                          start={0}
                          stop={5}
                          fractions={2}
                          step={1} 
                          emptySymbol={<img src={iconStarEmpty}/>}
                          fullSymbol={<img src={iconStar}/>} 
                          className="star-wrap"
                        /> 
                          
                        
                      </div>
                      </div>
                      <Link to={detailUrl +  (props.row.user_full_name).replace(/[^a-z0-9" "]/gi,"").trim().split(" ").filter(Boolean).join("-").toLowerCase()+"-"+ props.row.user_master_id}>
                      
                      <label className="review-count-label"><span className="review-count">{props.row.user_review_count}</span> Reviews</label>
                      </Link>
                      </div>
                  </div>
                )}
                
            </div>
            <div className="card-mdl">
              <div className="card-body">
                <h5 className="card-title">
                  <Link to={detailUrl +  (props.row.user_full_name).replace(/[^a-z0-9" "]/gi,"").trim().split(" ").filter(Boolean).join("-").toLowerCase()+"-"+ props.row.user_master_id}>
                    {props.row.user_full_name}
                  </Link>
                </h5>
                <ul>
                <li className="mobile-view">
                        {props.row.user_experience && (
                            <div className="emp-exp">
                              {props.page === "Lowyer" && (
                                <div className="d-flex flex-column text-start mb-2">
                                  <label>Experience</label>
                                  <span>
                                    {props.row.user_experience
                                      ? props.row.user_experience + " Years"
                                      : "--"}
                                  </span>
                                </div>
                              )}
                            </div>
                          )}
                    </li>
                  {props.row.expertise && props.row.expertise.length > 0 && props.page === "Lowyer" && (

               
                    <li className="d-flex flex-column expertise-list">
                      <label>Areas Of Expertise </label>
                      <div className="ranking-badge d-flex flex-column">
                        {props.row.expertise && props.row.expertise.length > 0
                          ? props.row.expertise.map((item, indx) => {
                            return (
                              <div
                                key={indx}
                                className={
                                  "ranking-badge-lst d-flex flex-row align-items-center ranking-color" +
                                  (props.row.expertise_ranking &&
                                    props.row.expertise_ranking[
                                    item.user_expertise_master_id
                                    ]
                                    ? "-" +
                                    props.row.expertise_ranking[
                                      item.user_expertise_master_id
                                    ].rank_level
                                    : "")
                                }
                              >
                                
                                    <div className="icon-badge me-3">
                                      <img
                                        src={iconBadgeSmall}
                                        className=""
                                        alt=""
                                      />
                                    </div>
                                 
                                <div className="content-badge d-flex flex-row">
                                <span className="badge-name">
                                  < >
                                    {item.user_expertise_name}
                                  </>

                                  {props.row.expertise_ranking &&
                                    props.row.expertise_ranking[
                                    item.user_expertise_master_id
                                    ] ? (
                                    <>
                                      &nbsp;({" "}
                                      {
                                        props.row.expertise_ranking[
                                          item.user_expertise_master_id
                                        ].user_rank_name
                                      }
                                      )
                                   </>
                                  ) : (
                                    " "// <span className="badge-type">Novice</span>
                                  )} </span>
                                </div>
                              </div>
                            );
                          })
                          : "--"}
                      </div>
                    </li>
                  )}
                  {props.row.court_data && props.page !== "GC" && (
                    <li className="court-practise flex-column">
                      <label>Courts of Practice</label>
                      <span>
                        {props.row.court_data
                          ? props.row.court_data.map((item, indx) => (
                            <i key={indx}>
                              {item.user_court_name}
                              {props.row.court_data.length > indx + 1
                                ? ", "
                                : ""}
                            </i>
                          ))
                          : "--"}
                      </span>
                    </li>
                  )}

                  {props.row.user_organizatin_name && props.page === "GC" && (
                    <li className="d-flex flex-column gc-designation">
                      {props.page !== "GC" && <label>Designation</label>}
                      <span>
                        {props.row.designation ? props.row.designation : "--"}
                        &nbsp;at&nbsp;
                        <strong><u>{props.row.user_organizatin_name
                          ? props.row.user_organizatin_name
                          : "--"}</u></strong>
                      </span>
                    </li>
                  )}

                  {/*     {props.row.designation && props.page === "GC" && (
                     <li className="d-flex flex-column">
                      <label>Designation</label>
                      <span>
                        {props.row.designation ? props.row.designation : "--"}
                      </span>
                    </li>
                  )} */}




                  <li className="d-flex flex-row gc-other-detail">
                    {props.row.user_experience && (
                      <div className="emp-exp me-3">
                        {props.page !== "Lowyer" && (
                          <div className="d-flex flex-column text-start mt-2">
                            <label>Experience</label>
                            <span>
                              {props.row.user_experience
                                ? props.row.user_experience + " Years"
                                : "--"}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                    {props.row.user_experience && (
                      <div className="emp-exp">
                        {props.page !== "Lowyer" && (
                          <div className="d-flex flex-column text-start mt-2">
                            <label>Enrolment</label>
                            <span>
                              {props.row.user_year_of_enrollment
                                ? props.row.user_year_of_enrollment + ""
                                : "--"} &nbsp; - &nbsp;
                              {props.row.user_bar_council
                                ? props.row.user_bar_council + ""
                                : "--"}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </li>
                  
                  
                
                </ul>
              </div>
            </div>
            <div className="card-right">

              <div className="card-action">
                {props.page !== "GC" &&

                  <div className="lawyer-avilibility">
                    {
                      // props.page !== "Gc" &&
                      (props.userProfile || props.page !== "Gc") && props.row.from_time !== "" && props.row.from_time !== "00:00:00" && props.row.to_time !== "00:00:00" && (
                        <div className="cmn-list">
                          <label>preferred time to call</label>
                          <span>
                            {props.row.from_time
                              ? moment(props.row.from_time, "hh:mm:ss").format("h:mm a")
                              : "- -"}
                            {props.row.from_time && " To "}
                            {props.row.to_time
                              ? moment(props.row.to_time, "hh:mm:ss").format("h:mm a")
                              : "- -"}
                          </span>
                        </div>
                      )}
                  </div>
                }
                {props.page !== "GC" && (props.page === "Lowyer" || props.userProfile) &&
                  props.row.user_mobile_no !== "" ? (
                    (props.displayMaskedNumber ? 
                        <button className="btn-green contact-link" rel="noreferrer" onClick={() => { 
                            props.viewLawyerDetail({
                                user_master_id : props.row.user_master_id,
                                request_on : "Mobile"
                            })
                        }}>
                            <img src={iconPhone} className="svg" alt="" />{props.row.masked_mobile_no}
                        </button> : 
                        <a href={"tel:" + props.row.user_mobile_no} className="btn-green contact-link" rel="noreferrer">
                            <img src={iconPhone} className="svg" alt="" />
                                {formatPhoneNumberIntl(props.row.user_mobile_no) ||
                                 formatPhoneNumberIntl("+91"+props.row.user_mobile_no)}
                        </a>
                    )
                ) : (
                  ""
                )}

                {props.page !== "Lowyer" &&
                  <div className="social-links d-flex flex-row">



                    {props.page !== "GC" &&
                      (props.page === "Lowyer" || props.userProfile) && (
                        <a
                          href={
                            "https://wa.me/" +
                            whatsupno +
                            "?text=I%20came%20from%20Xperts%20Legal%20Website"
                          }
                          rel="noreferrer"
                          target="_blank"
                          className={
                            whatsupno === ""
                              ? "social-lst btn-disabled"
                              : "social-lst social-wapp"
                          }
                        >
                          <i className="social-wapp-icon"></i>
                          {props.row.whatsupno}
                        </a>
                      )}

                    {props.page !== "GC" &&
                      (props.page === "Lowyer" || props.userProfile) &&
                      props.showWhatsApp !== false ? (
                      <a
                        href={
                          "https://wa.me/" +
                          whatsupno +
                          "?text=I%20came%20from%20Xperts%20Legal%20Website"
                        }
                        target="_blank"
                        rel="noreferrer"
                        className={
                          whatsupno === ""
                            ? "social-lst btn-disabled"
                            : "social-lst social-wapp"
                        }
                      >
                        <img src={iconWhatsup} className="svg" alt="" />
                      </a>
                    ) : (
                      ""
                    )}

                    { props.page !== "GC" && (props.page === "Lowyer" || props.userProfile) ? (
                      <Modal
                        btnCoverClass="social-lst"
                        title="Email to the Lawyer"
                        btnTitle=""
                        btnClass="email-icon"
                        id="AddLogoChildForm"
                        child={sendEmail}
                        data={props.row}
                      // onChange={onChangeProfile}
                      />
                    ) : (
                      ""
                    )}
                    {props.userProfile && props.row.user_user_type !=="Gc" && props.row.face_book_profile_link && (
                      <a
                      href={formatUrlLink(props.row.face_book_profile_link)}
                        target="blank"
                        rel="noreferrer noopener"
                        className="social-lst social-fb"
                      >
                        <i className="social-fb-icon"></i>
                      </a>
                    )}
                    {props.userProfile && props.row.user_user_type !=="Gc" &&  props.row.twitter_profile_link && (
                      <a
                      href={formatUrlLink(props.row.twitter_profile_link)}
                        target="_blank"
                        rel="noreferrer noopener"
                        className="social-lst social-tt"
                      >
                        <i className="social-tt-icon"></i>
                      </a>
                    )}
                    {props.userProfile && props.row.linked_in_link && (
                      <a
                      href={formatUrlLink(props.row.linked_in_link)}
                        target="_blank"
                        rel="noreferrer noopener"
                        className="social-lst social-link"
                      >
                        <i className="social-link-icon"></i>
                      </a>
                    )}
                    {props.userProfile && props.row.user_user_type !=="Gc" && props.row.you_tube_link && (
                      <a
                      href={formatUrlLink(props.row.you_tube_link)}
                        target="_blank"
                        rel="noreferrer noopener"
                        className="social-lst social-yt"
                      >
                        <i className="social-yt-icon"></i>
                      </a>
                    )}
                  </div>
                }

                {/*   <Link
                  to={
                    detailUrl +
                    props.row.user_first_name.toLowerCase() +
                    "-" +
                    props.row.user_last_name.toLowerCase() +
                    "-" +
                    props.row.user_master_id
                  }
                  className="btn-border btn-back btn-view"
                >
                  <i className="back-icon"></i> View Profile
                </Link> */}
              </div>

              {props.page !== "GC" &&
                <div className="social-links d-flex flex-row">



                  {props.page === "GC" &&
                    (props.page === "Lowyer" || props.userProfile) && (
                      <a
                        href={
                          "https://wa.me/" +
                          whatsupno +
                          "?text=I%20came%20from%20Xperts%20Legal%20Website"
                        }
                        rel="noreferrer"
                        target="_blank"
                        className={
                          whatsupno === ""
                            ? "social-lst btn-disabled"
                            : "social-lst social-wapp"
                        }
                      >
                        <img src={iconWhatsup} className="svg" alt="" />
                        {props.row.whatsupno}
                      </a>
                    )}

                  {props.page !== "GC" &&
                    (props.page === "Lowyer" || props.userProfile) &&
                    props.showWhatsApp !== false ? (
                        (props.displayMaskedWhatsapp ? 
                            <i className="social-wapp-icon"  onClick={() => { props.viewLawyerDetail({
                                user_master_id : props.row.user_master_id,
                                request_on : "Whatsapp"
                            })}} ></i> :
                            <a
                            href={
                                "https://wa.me/" +
                                whatsupno +
                                "?text=I%20came%20from%20Xperts%20Legal%20Website"
                            }
                            target="_blank"
                            rel="noreferrer"
                            className={
                                whatsupno === ""
                                ? "social-lst btn-disabled"
                                : "social-lst social-wapp"
                            }
                            >
                                <i className="social-wapp-icon"></i>
                            </a>
                ) ) : (
                    ""
                  )}

                  {props.page === "Lowyer" || props.userProfile ? (
                    <Modal
                      btnCoverClass="social-lst"
                      title="Email to the Lawyer"
                      btnTitle=""
                      btnClass="email-icon"
                      id="AddLogoChildForm"
                      child={sendEmail}
                      data={props.row}
                      request_from  = { props.userProfile ? "" : "Listing"}
                    // onChange={onChangeProfile}
                    />
                  ) : (
                    ""
                  )}
                  {props.row.face_book_profile_link && props.row.user_user_type !=="Gc" && (
                    <a
                    href={formatUrlLink(props.row.face_book_profile_link)}
                      target="blank"
                      rel="noreferrer noopener"
                      className="social-lst social-fb"
                    >
                      <i className="social-fb-icon"></i>
                    </a>
                  )}
                  {props.row.twitter_profile_link && props.row.user_user_type !=="Gc" && (
                    <a
                    href={formatUrlLink(props.row.twitter_profile_link)}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="social-lst social-tt"
                    >
                      <i className="social-tt-icon"></i>
                    </a>
                  )}
                  {props.row.linked_in_link  && (
                    <a
                    href={formatUrlLink(props.row.linked_in_link )}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="social-lst social-link"
                    >
                      <i className="social-link-icon"></i>
                    </a>
                  )}
                  {props.row.you_tube_link && props.row.user_user_type !=="Gc" && (
                    <a
                    href={formatUrlLink(props.row.you_tube_link )}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="social-lst social-yt"
                    >
                      <i className="social-yt-icon"></i>
                    </a>
                  )}
                </div>
              }
              <div className="organigation-block">
                <div className="emp-award img">
                  {/* <img
                  src={
                    props.row.rank_documents &&
                    props.row.rank_documents[0].user_document
                  }
                  alt=""
                /> */}
                  {props.row.user_organization_logo ? (
                    <img
                      src={props.row.user_organization_logo}
                      alt={
                        props.row.alt_for_org_image
                          ? props.row.alt_for_org_image
                          : ""
                      }
                    />
                  ) : (
                    ""
                  )}
                </div>
                {props.row.user_address1 && (
                  <div className="organization-address d-flex flex-column">

                    <label style={{ color: "#1678fb" }}>
                      {props.row.user_organizatin_name
                        ? props.row.user_organizatin_name
                        : ""}
                    </label>
                    <span>

                      {props.row.userFullAddress1 ? props.row.userFullAddress1 : ""}
                      {props.row.userFullAddress2 ?", "+props.row.userFullAddress2: ""}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
    </>
  );
};
export default EmployeeCard;
