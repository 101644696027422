
import { useSetRecoilState } from "recoil";
import { curRouteDetailsState } from "../../../../Helpers/Recoil";
import { useEffect, useState } from "react";
import { apiRequest } from "../../../../Helpers/General";
import swal from "sweetalert";
const Settings = (props) => {
  let id = props.id
  ? props.id
  : props.userProfile && props.userProfile.user_master_id;
  const setCurRouteDetails = useSetRecoilState(curRouteDetailsState);
  useEffect(() => {
    setCurRouteDetails(props.componentInfo);
  });
  const [SettingsData, setSettingsData] = useState([]);
  const [isPageUpdating, setIsPageUpdating] = useState(true);
  const [updateSettings, setupdateSettings] = useState(false);
  const [job, setJob] = useState();
  const [userEvent, setUserEvent] = useState();
  const [publication, setpublication] = useState();
  const [other, setother] = useState();
  useEffect(() => {
    const GetMembership = async () => {
      try {
        const apiSetting = {
          method: "GET",
          apiParams: { user_id: id, },
        };
        const apiResult = await apiRequest("get_user_settings", apiSetting);

        if (apiResult.data.settings.success === "1") {
          setSettingsData(apiResult.data.data[0]);
          setUserEvent(apiResult.data.data[0].event_notification === "0" ? false : true)
          setJob(apiResult.data.data[0].user_weekly_job_notification === "0" ? false : true)
          setpublication(apiResult.data.data[0].user_weekly_publication_notification === "0" ? false : true);
          setother(apiResult.data.data[0].user_other_notification === "0" ? false : true)
          setIsPageUpdating(false);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsPageUpdating(false);
      }
    };
    if (isPageUpdating) {
      GetMembership();
    }
  }, [isPageUpdating, id]);
useEffect(()=>{
  if(updateSettings){
  onUpdate();
  }
  // eslint-disable-next-line
},[job,publication,other,userEvent])

  const onUpdate = async (data) => {
    setupdateSettings(false)
    let parms = {
      user_id: id,
      setting_id: SettingsData && SettingsData.user_settings_id,
      event_notification: userEvent ? 1 : 0,
      weekly_job_notification: job ? 1 : 0,
      weekly_publication_notification: publication ? 1 : 0,
      other_notification: other ? 1 : 0,
    }
    try {
      const apiSetting = {
        method: "POST",
        apiParams: parms,
      };
      const apiResult = await apiRequest("edit_user_settings", apiSetting);
      if (apiResult.data.settings.success === "1") {

        swal({
          title: "Success",
          text: apiResult.data.settings.message,
          icon: "success",
          button: {
            text: "Done",
            closeModal: true,
          },
          dangerMode: false,
        })
      }else{
        swal({
          title: "Error",
          text: apiResult.data.settings.message,
          icon: "warning",
          button: {
            text: "Close",
            closeModal: true,
          },
          dangerMode: true,
        }) 
      }
    } catch (error) {
      console.log("error", error);
    } finally {

    }
  }
  return (
    <div className="common-inner-profile-page inner-page settings-page after-login-page ">
         <div className="row">
        <div className="col-lg-12">
          <div className="inner-head d-flex align-item-center afterlogin-inner-head">
            <div className="inner-head-left">
              <div className="page-title  text-start">
                <h2>Settings</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
       <div className="row">

                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="event-lst-box d-flex justify-content-between align-items-center settings-content">
                  <div className="card-itm">
                      <div className="card">
                        <div className="card-content d-flex justify-content-between">
                          <div className="card-title">
                          Events related notifications
                          </div>
                          <div className="card-action">
                            <div className="switch-btn" onClick={() => {
                              debugger
                              setUserEvent(!userEvent);
                              setupdateSettings(true);
                           
                            }}>
                              <input
                                type="checkbox"
                                id="switchEvent"
                                // checked={job}
                                defaultChecked={userEvent}
                                className="switch-input-btn"
                              />
                              <label
                                htmlFor="switch"
                                className="switch-input-label"
                              >
                                Toggle
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-itm">
                      <div className="card">
                        <div className="card-content d-flex justify-content-between">
                          <div className="card-title">
                            Weekly Job Notifications
                          </div>
                          <div className="card-action">
                            <div className="switch-btn" onClick={() => {
                              setJob(!job);
                              setupdateSettings(true);
                           
                            }}>
                              <input
                                type="checkbox"
                                id="switchJob"
                                // checked={job}
                                defaultChecked={job}
                                className="switch-input-btn"
                              />
                              <label
                                htmlFor="switch"
                                className="switch-input-label"
                              >
                                Toggle
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-itm">
                      <div className="card">
                        <div className="card-content d-flex justify-content-between">
                          <div className="card-title">
                            Weekly Publication Notifications
                          </div>
                          <div className="card-action">
                            <div className="switch-btn" onClick={() => {
                              setpublication(!publication);
                              setupdateSettings(true);
                            }}>
                              <input
                                type="checkbox"
                                id="switchPublication"
                                defaultChecked={publication}
                                // checked={publication}
                                className="switch-input-btn"
                              />
                              <label
                                htmlFor="switch"
                                className="switch-input-label"
                              >
                                Toggle
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-itm">
                      <div className="card">
                        <div className="card-content d-flex justify-content-between">
                          <div className="card-title">Other Notifications</div>
                          <div className="card-action">
                            <div className="switch-btn" onClick={() => {
                              setother(!other);
                              setupdateSettings(true);
                            }} >
                              <input
                                type="checkbox"
                                id="switchOther"
                                defaultChecked={other}
                                // checked={other}
                                className="switch-input-btn"
                              />
                              <label
                                htmlFor="switch"
                                className="switch-input-label"
                              >
                                Toggle
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
    </div>
  );
};

export default Settings;
