import { useSetRecoilState } from "recoil";
import { curRouteDetailsState } from "../../../../Helpers/Recoil";
import { useEffect, useState } from "react";
import TableData from "../../../../componants/Table/TableData";
import { apiRequest } from "../../../../Helpers/General";
import { useHistory } from "react-router";
import moment from "moment";
import Loader from "../../../../componants/Loader/Loader";
const Membership = (props) => {
  const history = useHistory();
  let id = props.id
    ? props.id
    : props.userProfile && props.userProfile.user_master_id;
  let user_country_id = props && props.userProfile && props.userProfile.country_id;
  const setCurRouteDetails = useSetRecoilState(curRouteDetailsState);
  useEffect(() => {
    setCurRouteDetails(props.componentInfo);
  });

  const [MebershipDetails, setMebershipDetails] = useState([]);
  const [isPageUpdating, setIsPageUpdating] = useState(true);
  useEffect(() => {
    const GetMembership = async () => {
      try {
        const apiSetting = {
          method: "GET",
          apiParams: { country_id: user_country_id, user_id: id },
        };
        const apiResult = await apiRequest("user_membership_info", apiSetting);
        if (apiResult.data.settings.success === "1") {
          setMebershipDetails(apiResult.data.data.return_data);
          setIsPageUpdating(false);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsPageUpdating(false);
      }
    };
    if (isPageUpdating) {
      GetMembership();
    }
  }, [isPageUpdating, id]);

  const colModal = [
    {
      dataField: "package_start_date",
      text: "Date",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "30%", textAlign: "left" };
      },
      formatter: (cellContent, row) => {
        return (
          <div className="text-left">
            {moment(cellContent).format("MMMM Do YYYY")}
          </div>
        );
      },
    },
    {
      dataField: "package_name",
      text: "Plan",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "20%", textAlign: "center" };
      },
      formatter: (cellContent, row) => {
        return (
        
            <div className="text-center d-flex flex-column align-items-start">{cellContent}  
            
            {row.invoice_pdf !== "" && 
            <a
            href={row.invoice_pdf}
            target="_blank"
            rel="noreferrer"
            title="Download Invoice"
            className="custom-downlod-invoice"
          >Download Invoice</a>
          
          } 
        </div>
        );
      },
    },
    {
      dataField: "user_paid_amount",
      text: "Amount",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "30%", textAlign: "center" };
      },
      formatter: (cellContent, row) => {
        return <div className="text-center">{row.user_paid_currency_symbol+''+cellContent}</div>;
      },
    },

    {
      dataField: "user_payment_status",
      text: "Status",
      headerStyle: (colum, colIndex) => {
        return { width: "20%", textAlign: "center" };
      },
      classes: "actionCell",
      formatter: (cellContent, row) => {
        let redirectTo =
          "backend/invoice.html/" +
          row.user_membership_id +
          "/" +
          props.userProfile.user_master_id;
        return (
          <div className="custom-listview-action">
            <div className="apl-badge apl-badge-paid custom-btn-link">
              {cellContent}
            </div>
            {row.package_name !== "Free Plan" &&   <a
              href={redirectTo}
              target="_blank"
              rel="noreferrer"
              title="Download Receipt"
              className="download-link btn-green custom-btn-link"
            >
              {/* <img src={download} alt="" /> */}
              <i className="mdi mdi-download"></i>
            </a>}
          </div>
        );
      },
    },
  ];
  const colModal2 = [
    // {
    //   dataField: "package_name",
    //   text: "Level",
    //   sort: true,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: "25%", textAlign: "start" };
    //   },
    //   formatter: (cellContent, row) => {
    //     return <div className="text-left">{cellContent}</div>;
    //   },
    // },
    {
      dataField: "package_name",
      text: "Billing",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "25%", textAlign: "center" };
      },
      formatter: (cellContent, row) => {
        return (
          <div className="text-center d-flex flex-column align-items-start">{cellContent}  
          
          {row.invoice_pdf !== "" && 
          <a
          href={row.invoice_pdf}
          target="_blank"
          rel="noreferrer"
          title="Download Invoice"
          className="custom-downlod-invoice"
        >Download Invoice</a>
        
        } 
      </div>
        );
      },
    },
    
    {
      dataField: "package_end_date",
      text: "Expiration",
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: "30%", textAlign: "center" };
      },
      formatter: (cellContent, row) => {
        return (
          <div className="text-center">
            {moment(cellContent).format("MMMM Do YYYY, h:mm:ss A")}
          </div>
        );
      },
    },

    {
      dataField: "",
      text: "Action",
      headerStyle: (colum, colIndex) => {
        return { width: "20%", textAlign: "center" };
      },
      classes: "actionCell",
      formatter: (cellContent, row) => {
        let redirectTo =
          "backend/invoice.html/" +
          row.user_membership_id +
          "/" +
          props.userProfile.user_master_id;
        return (
          <div className="custom-listview-action">
            {/* <Link
              to="/MembershipUpgrade"
              className="btn-border btn-view"
            >
              <i className="mdi mdi-arrow-right md-24"></i> Upgrade
            </Link> */}
            
            <button
              className="btn-border btn-view"
              onClick={() => upgradePlan(row)}
            >
              <i className="mdi mdi-arrow-right md-24"></i>
              {row.package_name !== "Platinum Plan" ? "Upgrade" : "Change Plan"}
            </button>
           {row.package_name !== "Free Plan" && <a
              href={redirectTo}
              target="_blank"
              rel="noreferrer"
              title="Download Receipt"
              className="download-link btn-green custom-btn-link"
            >
              <i className="mdi mdi-download"></i>
            </a>}
          </div>
        );
      },
    },
  ];
  const upgradePlan = (data = {}) => {
    localStorage.setItem("currentPlan", JSON.stringify(data));
    history.push("/MembershipUpgrade");
  };
  const expandRow = {
    renderer: (row) => <></>,
    showExpandColumn: true,
  };
  if (isPageUpdating) {
    return <Loader />;
  } else {
    return (
      <div className="common-inner-profile-page inner-page membership-page after-login-page">
        <div className="row">
          <div className="page-title  text-start secondary-page-title">
            <h2>My Membership</h2>
          </div>
          <div className="booking-table custom-table without-pagging">
             { MebershipDetails && MebershipDetails.active_plan ? "" :
                  <div className="mb-2 d-flex flex-column align-items-end">
                    <button className="btn-border btn-view" onClick={() => upgradePlan()}>
                      <i className="mdi mdi-arrow-right md-24"></i> Upgrade
                    </button>
                  </div> }
            <TableData
              key="package_name"
              keyField="package_name"
              colModal={colModal2}
              data={
                MebershipDetails && MebershipDetails.active_plan
                  ? MebershipDetails.active_plan
                  : []
              }
              expandRow={expandRow}
            />
          </div>
        </div>
        <div className="row">
          <div className="page-title  text-start secondary-page-title">
            <h2>Past Subscriptions</h2>
          </div>
          <div className="booking-table custom-table without-pagging">
            <TableData
              kay="user_transaction_id"
              keyField="user_transaction_id"
              colModal={colModal}
              data={
                MebershipDetails && MebershipDetails.prev_plan
                  ? MebershipDetails.prev_plan
                  : []
              }
              expandRow={expandRow}
            />
          </div>
        </div>
      </div>
    );
  }
};

export default Membership;
