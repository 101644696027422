import React from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import { apiRequest, countryId } from "../../Helpers/General";
import { useState } from "react";
const SendEmail = (props) => {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const changeMobile = (value) => {
    if (value) {
      let number = value.trim();
      if (isValidPhoneNumber(number) === true) {
        setValue("mobile", number);
        clearErrors("mobile");
      } else {
        setError("mobile", {
          type: "invalid",
          message: "Invalid Mobile number.",
        });
      }
    } else {
      setError("mobile", {
        type: "required",
        message: "Mobile number is required.",
      });
    }
  };
  const onSubmit = async (data) => {
    setLoading(true);
    let parms = {
      user_id: props && props.data.user_master_id,
      user_type: props && props.data.user_user_type,
      first_name: data.first_name,
      last_name: data.last_name,
      email_id: data.email,
      phone_code: "",
      mobile_number: data.mobile,
      decription: data.description,
      subject_line: data.title,
      country_id: countryId ? countryId : "102",
      request_from : props && props?.request_from
    };
    try {
      const apiSetting = {
        method: "POST",
        apiParams: parms,
      };
      const apiResult = await apiRequest("contact_lawyer_and_gcs", apiSetting);
      if (apiResult.data.settings.success === "1") {
        swal({
          title: "Success",
          text: apiResult.data.settings.message,
          icon: "success",
          button: {
            text: "Done",
            closeModal: true,
          },
          dangerMode: false,
        });
        props.closeModal();
      } else {
        swal({
          title: "Error",
          text: apiResult.data.settings.message,
          icon: "warning",
          button: {
            text: "Close",
            closeModal: true,
          },
          dangerMode: true,
        });
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }


  };
  return (
    <div className="send-email-block">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">

          <div className="form-block">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="form-row">
                  <label
                    htmlFor="inputFirstName"
                    className="float-start mb-1 d-flex"
                  >
                    First Name <em>*</em>
                  </label>
                  <div className="form-input position-relative">
                    <input
                      type="text"
                      id="inputFirstName"
                      className="form-control"
                      placeholder="First Name"
                      {...register("first_name", {
                        required: "First Name is required",
                      })}
                    />
                    <div className="input-err">
                      {errors?.first_name?.message}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                {" "}
                <div className="form-row">
                  <label
                    htmlFor="inputLastName"
                    className="float-start mb-1 d-flex"
                  >
                    Last Name <em>*</em>
                  </label>
                  <div className="form-input position-relative">
                    <input
                      type="text"
                      id="inputLastName"
                      className="form-control"
                      placeholder="Last Name"
                      {...register("last_name", {
                        required: "Last Name is required",
                      })}
                    />
                    <div className="input-err">
                      {errors?.last_name?.message}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-row">
              <label htmlFor="inputEmail" className="float-start mb-1 d-flex">
                Email <em>*</em>
              </label>
              <div className="form-input position-relative">
                <input
                  type="text"
                  id="inputEmail"
                  className="form-control"
                  placeholder="Email"
                  {...register("email", {
                    required: "Email Address is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Enter a valid Email",
                    },
                  })}
                />
                <div className="input-err">{errors?.email?.message}</div>
              </div>
            </div>
            <div className="form-row">
              <label
                htmlFor="inputFirstName"
                className="float-start mb-1 d-flex"
              >
                Phone Number <em>*</em>
              </label>
              <div className="form-input-div form-input position-relative">
                <div className="input-group">
                  <PhoneInput
                    id="mobile"
                    placeholder="Phone Number"
                    defaultCountry="IN"
                    onChange={changeMobile}
                    rules={{
                      ...register("mobile", {
                        required: "Phone Number is required",
                      }),
                    }}
                  />
                </div>
                <div className="input-err">{errors?.mobile?.message}</div>
              </div>
            </div>
            <div className="form-row">
              <label
                htmlFor="inputFirstName"
                className="float-start mb-1 d-flex"
              >
                Title / Subject Line <em>*</em>
              </label>
              <div className="form-input position-relative">
                <input
                  type="text"
                  id="inputFirstName"
                  className="form-control"
                  placeholder="Title"
                  defaultValue="Need counsel on "
                  {...register("title", { required: "Title is required" })}
                />
                <div className="input-err">{errors?.title?.message}</div>
              </div>
            </div>
            <div className="form-row">
              <label
                htmlFor="inputFirstName"
                className="float-start mb-1 d-flex"
              >
                Description of the issue <em>*</em>
              </label>
              <div className="form-input position-relative">
                <textarea
                  id="inputFirstName"
                  className="form-control custom-textarea"
                  placeholder="Description of the issue"
                  {...register("description", {
                    required: "Description is required",
                  })}
                />
                <div className="input-err">
                  {errors?.description?.message}
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 profile-action-area">
            <div className="form-row mt-4 text-center">
              {loading ? <button
                form="contact-person-add-hook-form"
                className="w-20 btn btn-primary m-auto">
                <div className="spinner-border text-white  text-center" role="status">
                  <span className="sr-only">Loading...</span>
                </div> </button> : <button className="btn-primary m-auto" type="submit">
                Submit
              </button>}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SendEmail;
