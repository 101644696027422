import React from "react";
import ReactDOM from "react-dom";
import { RecoilRoot } from "recoil";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { imgSvg } from "./Helpers/General";
import ContextProvider from "./Context/index";
ReactDOM.render(
  <>
    <ContextProvider>
      <RecoilRoot>
        <App />
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </RecoilRoot>
    </ContextProvider>
  </>,
  document.getElementById("root")
);
imgSvg();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
document.addEventListener("contextmenu", (event) => event.preventDefault());
document.onkeydown = function (e) {
  e = e || window.event; //Get event
  if (e.ctrlKey) {
    var c = e.which || e.key; //Get key code
    switch (c) {
      case 83: //Block Ctrl+S
        e.preventDefault();
        e.stopPropagation();
        break;
      default:
        break;
    }
  }
};
