import { useSetRecoilState } from "recoil";
import { curRouteDetailsState } from "../../../../Helpers/Recoil";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import iconTime from "./../../../../asset/svg/icon-time.svg";
import { apiRequest } from "../../../../Helpers/General";
import NoRecord from "../../../../componants/NoRecord/NoRecord";
import Modal from "../../../../componants/Modal/Modal";
import AddPublication from "./AddPublication";
import InactiveRequest from "./InactiveRequest";
import moment from "moment";
import Loader from "../../../../componants/Loader/Loader";
//import DeleteActionBox from "../../../../componants/Modal/DeleteActionBox";
import swal from "sweetalert";
const Publication = (props) => {

  let id = props.id
    ? props.id
    : props.userProfile && props.userProfile.user_master_id;
    let user_country_id = props && props.userProfile && props.userProfile.country_id;
  const setCurRouteDetails = useSetRecoilState(curRouteDetailsState);
  const [PublicationData, setPublicationData] = useState([]);
  const [isPageUpdating, setIsPageUpdating] = useState(true);
  const [pageIndex, setpageIndex] = useState("1");
  const [totalRecords, setTotalRecords] = useState(0);
  // eslint-disable-next-line
  const [doRefresh, setDoRefresh] = useState(false);
  useEffect(() => {
    setCurRouteDetails(props.componentInfo);
    const GetPublication = async () => {
      try {
        const apiSetting = {
          method: "GET",
          apiParams: {
            country_id: user_country_id, user_id: id, page_index: pageIndex,
            logged_user_id: props.userProfile ? props.userProfile.user_master_id : "0"
          },
        };

        const apiResult = await apiRequest(
          "get_publication_master_list",
          apiSetting
        );
        if (apiResult.data.settings.success === "1") {
          if (pageIndex > 1) {
            PublicationData.push(...(apiResult.data.data.publication_list));
            setIsPageUpdating(false);
          } else {
            setPublicationData(apiResult.data.data.publication_list);
            setTotalRecords(apiResult.data.settings.count);
            setIsPageUpdating(false);
          }
        } else {
          setPublicationData([]);
          setTotalRecords(0);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsPageUpdating(false);
      }
    };
    if (isPageUpdating || doRefresh) {
      GetPublication();
    }
    // eslint-disable-next-line
  }, [isPageUpdating, id, doRefresh]);

  /* const deletePublication = (rowId) => {
    const apiSetting = {
      method: "POST",
      apiParams: {
        user_id: id,
        publication_master_id: rowId,
        mode: "delete"
      },
    };
    DeleteActionBox(
      "delete_publication",
      apiSetting,
      doRefresh,
      setDoRefresh,
    );
  } */
  let loadMore = Math.ceil(totalRecords / 20);
  if (isPageUpdating) {
    return <Loader />
  } else {
    return (
      <div className="common-inner-profile-page inner-page publication-page after-login-page">
        <div className="row">
          <div className="col-lg-12">
            <div className="inner-head d-flex align-item-center afterlogin-inner-head">
              <div className="inner-head-left">
                <div className="page-title  text-start">
                  <h2>Publications</h2>
                </div>
              </div>
              {!props.type && (
                <div className="inner-header-rgt ms-auto d-flex align-items center">
                  <div className="emp-card-itm">
                    {/*       <Link
                  to={"/AddPublication"}
                  className="btn-green custom-btn-link"
                >
                  <img src={iconPlus} alt="" /> New Publication
                </Link>  */}

                    <Modal
                      btnCoverClass="btn-green custom-btn-link"
                      title="Add Publication"
                      btnTitle="New Publication"
                      btnClass="add-icon"
                      id={id}
                      child={AddPublication}
                      setIsPageUpdating={setIsPageUpdating}
                    // onChange={onChangeProfile}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="common-page-content publication-content">
              <div className="event-lst-box d-flex justify-content-between flex-column">
                {PublicationData.length > 0 ? (
                  PublicationData.map((row, key) => {
                    let plainPubTitle = row.master_data.publicaton_title.replace(/[^a-z0-9" "]/gi,"").substring(0, 180).split(" ").filter(Boolean).join("-").toLowerCase();
                        
                    if(plainPubTitle.includes("?") === true){
                      plainPubTitle = plainPubTitle.split("?").join("-");  
                    }
                    plainPubTitle = plainPubTitle +" "+ row.master_data.user_name.trim();
                    plainPubTitle = plainPubTitle.replace(/[^a-z0-9" "]/gi,"").split(" ").filter(Boolean).join("-").toLowerCase();
                    const pubLinkurl =  "/publications-details/" + plainPubTitle  + "-" +  row.master_data.publication_master_id

                    if (props.type && (row.master_data.publicaton_approval_status === 'Pending' || row.master_data.publicaton_approval_status === 'Rejected')) {
                      return "";
                    } else {
                      return (
                        // <LazyLoad
                        //   key={props.id}
                        //   height={200}
                        //   // once={true}
                        //   placeholder={<Spinner />}
                        // >
                        <div className={"card-itm inactive_req_status_" + row.master_data.inactive_req_status.toLowerCase() + " status_" +row.master_data.publication_status.toLowerCase()  } key={key}>
                          <div className="card">
                            <div className="row">
                              <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                                <div className="card-content">
                                  {row.category_data && row.category_data.map((item, index) => (
                                    <span className="practice-type" key={index}>
                                      {item.category_name}
                                      {row.category_data.length > index + 1
                                        ? " / "
                                        : ""}
                                    </span>
                                  ))}
                                  <div className="card-title">
                                    <Link
                                      to={pubLinkurl}
                                    >
                                      {row.master_data.publicaton_title}
                                    </Link>
                                  </div>
                                  <p className="common-paragraph">
                                    {row.master_data.publicaton_about}
                                  </p>
                                  <div className="publication-posted-by d-flex flex-row">
                                    {/* {!props.type && <div className="posted-pic">
                                        <img
                                          src={row.master_data.vProfilePic}
                                          alt=""
                                        />
                                      </div>} */}
                                    <div className="posted-content">
                                      {/* {!props.type &&    <div className="publication-by">
                                          {row.master_data.user_name}
                                        </div>} */}
                                      <div className="pub-status d-flex">
                                        <div className="publication-date">
                                          <i className="">
                                            <img
                                              src={iconTime}
                                              className="svg"
                                              alt=""
                                            />
                                          </i>
                                          Posted On :
                                          {moment(
                                            row.master_data
                                              .publicaton_publication_date
                                          ).format("DD/MM/YYYY")}
                                        </div>
                                        {!props.type && <div className="publication-date">
                                          <i className="">
                                            <img
                                              src={iconTime}
                                              className="svg"
                                              alt=""
                                            />
                                          </i>
                                          Status :
                                          <span
                                            className={
                                              "badge-" +
                                              row.master_data.publicaton_approval_status.toLowerCase()
                                            }
                                          >
                                            {
                                              row.master_data
                                                .publicaton_approval_status
                                            }
                                          </span>
                                        </div>}
                                        {!props.type && <div className="posted-status ml-4">
                                           
                                            <i className="">
                                              <img
                                                src={iconTime}
                                                className="svg"
                                                alt=""
                                              />
                                            </i>
                                            publication Status : 
                                            <span
                                              className={
                                               ( row.master_data.publication_status === "Inactive" ? "badge-publication-inactive btn-border" : "badge-publication-active")
                                              }
                                              onClick={()=>{
                                                row.master_data.publication_status === "Inactive" &&
                                                swal({
                                                  title:"",
                                                   text:"Reason of inactive : " +row.master_data.publication_inactive_reason,
                                                   icon: "info",
                                                   button: {
                                                    text: "Close",
                                                    closeModal: true,
                                                  },
                                                  dangerMode: true,
                                                });
                                              }}
                                            >
                                              {
                                                row.master_data
                                                  .publication_status
                                              }
                                            </span>
                                          </div>}

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                {/* <div className="mt-4 mr-2">
                                <button type="button" className="btn-border btn-view btn-edit custom-btn-link" onClick={()=> deletePublication(row.master_data.publication_master_id)}>
                                      <i className="mdi mdi-delete"></i> Delete
                                      </button> 
                                      </div> */}
                                <div className="card-footer d-flex flex-column border-0">
                                  <div className=" d-flex">
                                    {!props.type && row.master_data.publication_status === "Active" && <div className="card-action px-md-2">
                                      <Modal
                                        btnCoverClass="btn-border btn-view btn-edit custom-btn-link"
                                        title="Edit Publication"
                                        btnTitle="Edit"
                                        btnClass="edit-icon"
                                        id={id}
                                        child={AddPublication}
                                        setIsPageUpdating={setIsPageUpdating}
                                        dataid={row.master_data.publication_master_id}
                                      // onChange={onChangeProfile}
                                      />
                                    </div>}

                                    {!props.type && row.master_data.publication_status === "Active" && <div className="card-action ml-2">
                                      <Modal
                                        btnCoverClass="btn-border btn-view btn-edit custom-btn-link"
                                        title={row.master_data.inactive_req_status === "Pending" ? "Request For Inactive Publication is Pending " : "Request For Inactive Publication"}
                                        btnTitle="Inactive"
                                        btnClass="mdi mdi-delete"
                                        id={id}
                                        disabled={row.master_data.inactive_req_status === "Pending" ? true : false}
                                        requestType="Publication"
                                        child={InactiveRequest}
                                        setIsPageUpdating={setIsPageUpdating}
                                        dataid={row.master_data.publication_master_id}
                                      // onChange={onChangeProfile}
                                      />
                                    </div>}

                                  </div>
                                  {/* <div className="emp-award">
                                    <img
                                      src={row.master_data.user_organization_logo || `${process.env.PUBLIC_URL}/asset/images/no-image.jpg`}
                                      alt={row.master_data.alt_for_org_image ? row.master_data.alt_for_org_image : ""}
                                    />
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        // </LazyLoad>
                      )
                    }
                  })
                ) : (
                  <NoRecord pageName="Publications" />
                )}
              </div>
              <div className="footer-action">
                <h3>
                  Count : <span>{PublicationData.length} / {totalRecords}</span>
                </h3>

                {loadMore > pageIndex && <button
                  // ref={ref} 
                  className="btn-primary" onClick={() => {
                    setIsPageUpdating(true);
                    setpageIndex((parseInt(pageIndex) + 1).toString())
                  }}>Load More</button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Publication;
