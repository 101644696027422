import React from "react";
import { useForm } from "react-hook-form";

const ContactPerson = (props) => {
  /* const data = props.recordData || "";
  const id   = data.prospect_tax_information_id || ""; */
  const id ='';
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  // eslint-disable-next-line
  const regexUrl = new RegExp('http[s]?://(?:[a-zA-Z]|[0-9]|[$-_@.&+]|[!*\(\),]|(?:%[0-9a-fA-F][0-9a-fA-F]))+'); 
  const onSubmit = async (data) => {

    let formData = props.previesData || [];
    let row_id   =   id !=='' ?  id :  (formData.length + 1) * -1; 
    formData.push({
      ref_id            : row_id,
      ref_title        : data.rank_title.trim(),
      ref_link   : data.credential_link,
    });

    props.setLineInfo(formData);
    props.setDoRefresh(Math.random());
    props.closeModal();
  };
console.log(errors)
  return (
    <div
      className="ReactModal-namechange-content-container ReactModal-Content-Container"
      id="name_change_form"
    >
      <div className="ReactModal-middle-container">
        <form
          id="contact-person-add-hook-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <input
            type="hidden"
            id="inputId"
            className="form-control"
            {...register("id")}
          />
          {/* <div className="form-group">
            <div className="profile-content width75">  */}
          <div className="form-group">
            <div className="form-row mb-3 ">
              <label htmlFor="inputRankTitle" className="float-start mb-1">
                References Title<em>*</em>
              </label>
              <div className="input-group">
                <input
                  type="text"
                  id="inputRankTitle"
                  className="form-control"
                  placeholder="Title"
                  {...register("rank_title", {
                    required: " Title is required ",
                  })}
                />
              </div>
              <div className="input-err">{errors?.rank_title?.message}</div>
            </div>
          </div>
          <div className="form-group">
            <div className="form-row mb-3 ">
              <label htmlFor="inputCredentialLink" className="float-start mb-1">
                References URL<em>*</em>
              </label>
              <div className="input-group">
                <input
                  type="url"
                  id="inputCredentialLink"
                  className="form-control"
                  placeholder="https://example.com"
                  {...register('credential_link', {
                    required: " References URL is required ",
                    pattern: {
                        value: regexUrl,
                        message: 'Please enter a valid URL',
                    },
                })}
                />
              </div>
              <div className="input-err">
                {errors?.credential_link?.message}
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="ReactModal-Footer">
        <div className="ReactModal-ActionBlock profile-action-area">
          <div className="btn-grp m-auto">
            <button
              form="contact-person-add-hook-form"
              className="w-20 btn btn-primary m-auto"
              onClick={handleSubmit(onSubmit)}
            >
              <i className="mdi mdi-check"></i> Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPerson;
