import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { curRouteDetailsState } from "../../../Helpers/Recoil";
import { apiRequest } from "../../../Helpers/General";
import swal from "sweetalert";
import logoBlue from "../../../asset/images/logo-blue.png";
import iconReset from "../../../asset/images/icon-lock.png";
const ResetPassword = (props) => {

  const setCurRouteDetails = useSetRecoilState(curRouteDetailsState);
  useEffect(() => {
    setCurRouteDetails(props.componentInfo);
  });
  const history = useHistory();
  const {
    register,
    handleSubmit,
    getValues,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm();
  const [passwordShown, setPasswordShown] = useState(false);
  const [eyeClass, setEyeClass] = useState("mdi mdi-eye-off");
  const [confirmpasswordShown, setconfirmpasswordShown] = useState(false);
  const [eyeClassCpass, seteyeClassCpass] = useState("mdi mdi-eye-off");
  const togglePasswordVisiblity = (type) => {   
      setPasswordShown(passwordShown ? false : true);
      setEyeClass(
        passwordShown ? "mdi mdi-eye-off" : "mdi mdi-eye text-danger"
      );
  };

  const toggleconformPasswordVisiblity = () => {
    setconfirmpasswordShown(confirmpasswordShown ? false : true);
    seteyeClassCpass(
      confirmpasswordShown ? "mdi mdi-eye-off" : "mdi mdi-eye text-danger"
    );
  };
  const onSubmit = async (data) => {
    if(data.password===data.confirm_password){
    const apiSetting = {
      method: "POST",
      apiParams: {
        email_id: data.email,
        secret_code: data.secret_code,
        password: data.password,
      }, 
    };
    const apiResult = await apiRequest("reset_password", apiSetting);
    if (apiResult.data.settings.success === "1") {
      swal({
        title: "Success",
        text: apiResult.data.settings.message,
        icon: "success",
        button: {
          text: "Done",
          closeModal: true,
        },
        dangerMode: false,
      });

      history.push("/ThankYou");
    } else {
      swal({
        title: "Error",
        text: apiResult.data.settings.message,
        icon: "warning",
        button: {
          text: "Close",
          closeModal: true,
        },
        dangerMode: true,
      });
    }
  }else{
    setError("confirm_password", {
      type: "invalid",
      message: "password not match.",
    });
  }
  };
  const onchangeConfirmpassword = (e) => {
    let pass = getValues("password");
    if (pass === e.target.value) {
      clearErrors("confirm_password");
    } else {
      setError("confirm_password", {
        type: "invalid",
        message: "password not match.",
      });
    }
  };
  const onchangePassword = (e) => {
    let pass = getValues("confirm_password");
    if (pass === e.target.value) {
      clearErrors("confirm_password");
    } else {
      setError("confirm_password", {
        type: "invalid",
        message: "password not match.",
      });
    }
  };
  return (
    <div className="inner-page resetpassword-page">
      <div className="container">
        <div className="row">
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="row">
                <div className="reset-head d-flex flex-column align-items-center w-100 text-center">
                  <div className="logo mb-5">
                    <img src={logoBlue} onClick={()=> history.push("/")} alt="" />
                  </div>
                  <div className="reset-icon mt-2 mb-5">
                  <img src={iconReset} alt="" />
                    </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="form-row mb-4 email-row">
                  <label htmlFor="inputEmail" className="float-start mb-1">
                    Email
                  </label>
                  <div className="form-input-div">
                    <input
                      type="email"
                      id="input_reset_Email"
                      className="form-control"
                      placeholder="Email address"
                      autoFocus
                      {...register("email", {
                        required: "Enter your Email",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "Enter a valid Email",
                        },
                      })}
                    />
                    <div className="input-err">
                      {/* errors will return when field validation fails  */}
                      {errors?.email?.message}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="form-row mb-3  custom-dropdown">
                  <label
                    htmlFor="inputCurrentPassword"
                    className="float-start mb-1 d-flex"
                  >
                    Secret Code
                  </label>
                  <div className="form-input-div">
                    <input
                      type="text"
                      id="inputscret_code"
                      className="form-control"
                      placeholder=" Secret Code"
                      {...register("secret_code", {
                        required: "Secret Code is required",
                      })}
                    />

                    <div className="input-err">
                      {/* errors will return when field validation fails  */}
                      {errors?.secret_code?.message}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="form-row mb-3 width25 mWidth50 custom-dropdown">
                  <label
                    htmlFor="inputCurrency"
                    className="float-start mb-1 d-flex"
                  >
                    New Password
                  </label>
                  <div className="form-input-div">
                    <input
                      type={passwordShown ? "text" : "password"}
                      id="inputnew_Password"
                      autoComplete="new-password"
                      className="form-control"
                      placeholder="New Password"
                      {...register("password", {
                        required: "New password required",
                        minLength: {
                          value: 6,
                          message: "Minimum 6 characters",
                        },
                      })}
                      onChange={onchangePassword}
                    />
                    <i
                      onClick={togglePasswordVisiblity}
                      className={eyeClass + " mdi password-eye"}
                    ></i>
                    <div className="input-err">
                      {/* errors will return when field validation fails  */}
                      {errors?.password?.message}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="form-row mb-3 custom-dropdown">
                  <label
                    htmlFor="inputConfirmpassword"
                    className="float-start mb-1 d-flex"
                  >
                    Confirm New Password
                  </label>
                  <div className="form-input-div">
                    <input
                      type={confirmpasswordShown ? "text" : "password"}
                      id="inputconfirm_new_Password"
                      className="form-control"
                      placeholder="Password"
                      {...register("confirm_password", {
                        required: "Confirm Password is required",
                      })}
                      onChange={onchangeConfirmpassword}
                    />
                    <i
                      onClick={toggleconformPasswordVisiblity}
                      className={eyeClassCpass + " mdi password-eye"}
                    ></i>
                    <div className="input-err">
                      {/* errors will return when field validation fails  */}
                      {errors?.confirm_password?.message}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="search-action">
                <button
                  type="submit"
                  className="btn-primary btn-search"
                  onClick={handleSubmit(onSubmit)}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default ResetPassword;
