import React, { useState, useEffect, useContext } from "react";
import { MasterContext } from "../../../../Context";

import iconSearch from "./../../../../asset/svg/icon-search.svg";

const ArticleSearch = (props) => {
  const [SearchText, setSearchText] = useState('');
  const [filterData, setfilterData] = useState([]);
  const { categorymasterdata } = useContext(MasterContext);

  useEffect(() => {
    if (categorymasterdata) {
      setfilterData(categorymasterdata)
    }
    if (props.selectedValue) {
      setSearchText(props.selectedValue)
    }

  }, [categorymasterdata, props.selectedValue])
  const searchData = (e) => {
    let Text = e.target.value;
    setSearchText(e.target.value)
    if (e.target.value === "") {
      props.OnChange(e.target.value)
    }
    const filtered = categorymasterdata.filter(country => {
      return country.category_name.toLowerCase().includes(Text.toLowerCase())
    })
    setfilterData(filtered)
  }
  const OnValueSelect = (e) => {
    props.OnChange(e.category_master_id)
    setSearchText(e.category_name)
  }
  const resetData = () => {
    setSearchText('')
    props.OnChange("")
    // setfilterData(categorymasterdata);
  }
  return (
    <>
      <div className="col-sm-12 col-xs-12 order-1 mobile-place">
        <div className="right-search-block">
          <div className="right-block-title">
            <h2>Law Articles <span>by Practice Area</span></h2>
          </div>
          <div className="search-filter">
            <div className="search-field">
              <div className="form-row">
                <div className="form-input-div">
                  <input
                    type="text"
                    placeholder="Search"
                    value={SearchText}
                    className="form-control"
                    onChange={searchData}
                  />
                  <i className="search-icon">
                    <img
                      src={iconSearch}
                      className="svg"
                      alt=""
                    />
                  </i>
                  {SearchText && <button className="reset-btn" onClick={resetData}>Clear Search</button>}
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-content">
              <ul >
                {filterData.map((row, key) => (

                  <li key={key} onClick={() => OnValueSelect(row)} >
                    <a href="/" onClick={e => e.preventDefault()}>{row.category_name} </a>
                  </li>

                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ArticleSearch;
