import noRecord from './../../asset/images/no-record.png'
import './no-record.css'
const NoRecord = (props) => {
  return (
    <div className="no-record-block">
            <img src={noRecord} alt="No Record Found" />
            <h3><span>{props.pageName}</span></h3>
    </div>
  );
};

export default NoRecord;
