import Landing from './LoginNotRequired/Landing/Landing';
import Employment from './LoginNotRequired/Pages/Employment/Employment'
import EmploymentDetail from './LoginNotRequired/Pages/Employment/EmploymentDetail';
import Networking from './LoginNotRequired/Pages/Networking/Networking';
import Events from './LoginNotRequired/Pages/Events/Events'
import Publications from './LoginNotRequired/Pages/Publications/Publications'
import LawyerDirectory from './LoginNotRequired/Pages/LawyerDirectory/LawyerDirectory'
import GcDirectory from './LoginNotRequired/Pages/GcDirectory/GcDirectory'
import Ranking from './LoginNotRequired/Pages/Ranking/Ranking'
import Pricing from './LoginNotRequired/Pages/Pricing/Pricing'
import ContactUs from './LoginNotRequired/Pages/ContactUs/ContactUs'; 
import Faqs from './LoginNotRequired/Pages/Faqs/Faqs';
import ThankYou from './LoginNotRequired/Auth/ThankYou';
import ThankYouContactUs from './LoginNotRequired/Auth/ThankYouContactUs';
import ResetPassword from './LoginNotRequired/Auth/ResetPassword';
import Forgotpassword from './LoginNotRequired/Auth/ForgotPassword';
import About from './LoginNotRequired/Pages/About/About';
import NetworkingDetail from './LoginNotRequired/Pages/Networking/NetworkingDetail';
import EventDetail from './LoginNotRequired/Pages/Events/EventDetail';
import Login from './LoginNotRequired/Auth/Login';
import GcSignupForm from './LoginNotRequired/Auth/GcSignupForm';
import Home from './LoginRequired/Home/Home';
import MyProfile from './LoginRequired/MyProfile/MyProfile';
import EditProfile from './LoginRequired/MyProfile/EditProfile/EditProfile';
import Membership from './LoginRequired/MyProfile/Membership/Membership';
import Publication from './LoginRequired/MyProfile/Publication/Publication';
import Photos from './LoginRequired/MyProfile/Photos/Photos';
import Videos from './LoginRequired/MyProfile/Videos/Videos';
import Bookings from './LoginRequired/MyProfile/Bookings/Bookings';
import JobOpportunity from './LoginRequired/MyProfile/JobOpportunity/JobOpportunity';
import Settings from './LoginRequired/MyProfile/Settings/Settings';
import ChangePassword from './LoginRequired/MyProfile/ChangePassword';
import PublicationsDetails from './LoginNotRequired/Pages/Publications/PublicationsDetails';
import GcDirectoryDetail from './LoginNotRequired/Pages/GcDirectory/GcDirectoryDetail';
import LawyerDirectoryDetail from './LoginNotRequired/Pages/LawyerDirectory/LawyerDirectoryDetail';
import MembershipCheckout from './LoginNotRequired/Pages/MembershipCheckout/MembershipCheckout';
import AddJob from './LoginRequired/MyProfile/JobOpportunity/AddJob';
import AddPublication from './LoginRequired/MyProfile/Publication/AddPublication';
import MembershipUpgrade from './LoginRequired/MyProfile/Membership/MembershipUpgrade';
import ViewProfile from './LoginRequired/MyProfile/ViewProfile/ViewProfile';
import PrivacyPolicy from './LoginNotRequired/Pages/PrivacyPolicy/PrivacyPolicy';
import TermsCondition from './LoginNotRequired/Pages/TermsCondition/TermsCondition';
import UpgradeRanking from './LoginRequired/MyProfile/UpgradeRanking/UpgradeRanking';
import UpgradeRankingRandom from './LoginRequired/MyProfile/UpgradeRanking/UpgradeRankingRandom';
import Sitemap from './LoginNotRequired/Pages/Sitemap/Sitemap';
const RoutesArr = [
  {
    title : "Home",
    path: "/",
    component: Landing,
    className: "home-header main-header",
    menuItem:false,
    loginRequred:false,
  },
  {
    title : "Sign In",
    path: "/login",
    component: Login,
    className: "signin-header inner-header",
    menuItem:false,
    loginRequred:false,
  },
  {
    title : "FAQs",
    path: "/Faqs",
    component:Faqs,
    className: "faqs-header inner-header",
    menuItem:false,
    loginRequred:false,
  },
  {
    title : "Contact Us",
    path: "/ContactUs",
    component: ContactUs,
    className: "contact-header inner-header",
    menuItem:false,
    loginRequred:false,
  },
  {
    title : "Layout",
    path: "/",
    component: Home,
    className: "home-header main-header",
    menuItem:false,
    loginRequred:false,
  },
  {
    title : "My Profile",
    path: "/MyProfile",
    component: MyProfile,
    className: "myprofile-header inner-header",
    menuItem:false,
    loginRequred:false,
  },
  {
    title : "View Profile",
    path: "/ViewProfile",
    component: ViewProfile,
    className: "viewprofile-header myprofile-header inner-header",
    menuItem:false,
    loginRequred:false,
  },
  {
    title : "Edit Profile",
    path: "/EditProfile",
    component: EditProfile,
    className: "editprofile-header myprofile-header inner-header",
    menuItem:false,
    loginRequred:true,
  },
  {
    title : "My Profile",
    path: "/Membership",
    component: Membership,
    className: "membership-header myprofile-header inner-header",
    menuItem:false,
    loginRequred:true,
  },
  {
    title : "Membership",
    path: "/MembershipUpgrade",
    component: MembershipUpgrade,
    className: "membershipupgrade-header myprofile-header inner-header",
    menuItem:false,
    loginRequred:true,
  },
  {
    title : "My Profile",
    path: "/Publication",
    component: Publication,
    className: "publications-header myprofile-header inner-header",
    menuItem:false,
    loginRequred:true,
  },
  {
    title : "Publications",
    path: "/publications-details/:id",
    component: PublicationsDetails,
    className: "publications-detail-header myprofile-header inner-header",
    menuItem:false,
    loginRequred:false,
  },
  {
    title : "My Profile",
    path: "/Photos",
    component: Photos,
    className: "photos-header myprofile-header inner-header",
    menuItem:false,
    loginRequred:true,
  },
  {
    title : "My Profile",
    path: "/Videos",
    component: Videos,
    className: "videos-header myprofile-header inner-header",
    menuItem:false,
    loginRequred:true,
  },
  {
    title : "My Profile",
    path: "/Bookings",
    component: Bookings,
    className: "bookings-header myprofile-header inner-header",
    menuItem:false,
    loginRequred:true,
  },

  {
    title : "My Profile",
    path: "/JobOpportunity",
    component: JobOpportunity,
    className: "jobopportunity-header myprofile-header inner-header",
    menuItem:false,
    loginRequred:true,
  },
  {
    title : "My Profile",
    path: "/Settings",
    component: Settings,
    className: "setting-header myprofile-header inner-header",
    menuItem:false,
  },
  {
    title : "Thank You",
    path: "/ThankYou",
    component: ThankYou,
    className: "setting-header myprofile-header inner-header",
    menuItem:false,
    loginRequred:false,
  },
  {
    title : "Thank You",
    path: "/thank-you",
    component: ThankYouContactUs,
    className: "setting-header myprofile-header inner-header",
    menuItem:false,
    loginRequred:false,
  },
  {
    title : "Forgot Password",
    path: "/forgotpassword",
    component: Forgotpassword,
    className: "myprofile-header inner-header",
    menuItem:false,
    loginRequred:false,
  },
  {
    title : "Setup Gc Profile",
    path: "/gcsignupform",
    component: GcSignupForm,
    className: "gcsignupform-header inner-header",
    menuItem:false,
    loginRequred:false,
  },
  {
    title : "Membership Checkout",
    path: "/MembershipCheckout",
    component: MembershipCheckout,
    className: "membership-checkout-header inner-header",
    menuItem:false,
    loginRequred:false,
  },  
  {
    title : "About Us",
    path: "/about",
    component: About,
    className: "about-header inner-header",
    menuItem:true,
    loginRequred:false,
  },
  {
    title : "Privacy Policy",
    path: "/PrivacyPolicy",
    component: PrivacyPolicy,
    className: "privacy-policy-header gdirectory-header inner-header",
    menuItem:false,
    loginRequred:false,
  },
  {
    title : "Terms of Service",
    path: "/TermsCondition",
    component: TermsCondition,
    className: "terms-header ldirectory-header inner-header",
    menuItem:false,
    loginRequred:false,
  },
  {
    title : "Networking",
    path: "/networking-details",
    component: Networking,
    className: "networking-header inner-header",
    menuItem:true,
    loginRequred:false,
  },
  {
    title : "Networking",
    path: "/networking-details/:id",
    component: NetworkingDetail,
    className: "networking-detail-header networking-header inner-header",
    menuItem:false,
    loginRequred:false,
  },
  {
    title : "Events",
    path: "/Events",
    component: Events,
    className: "events-header inner-header",
    menuItem:true,
    loginRequred:false,
  },
  {
    title : "Events",
    path: "/Events/:type",
    component: Events,
    className: "events-header inner-header",
    menuItem:false,
    loginRequred:false,
  },
  {
    title : "Events",
    path: "/event-detail/:id",
    component: EventDetail,
    className: "events-detail-header inner-header",
    menuItem:false,
    loginRequred:false,
  },
  {
    title : "Publications",
    path: "/publications",
    component: Publications,
    className: "publications-header inner-header",
    menuItem:true,
    loginRequred:false,
  },
 
  {
    title : "Lawyer Directory",
    path: "/lawyers-directory",
    component: LawyerDirectory,
    className: "ldirectory-header inner-header",
    menuItem:true,
    loginRequred:false,
  },
  {
    title : "Lawyer Directory",
    path: "/lawyers-directory/:country",
    component: LawyerDirectory,
    className: "ldirectory-header inner-header",
    menuItem:false,
    loginRequred:false,
  },
  {
    title : "Lawyer Directory",
    path: "/lawyers-directory/:country/:state",
    component: LawyerDirectory,
    className: "ldirectory-header inner-header",
    menuItem:false,
    loginRequred:false,
  },
  {
    title : "Lawyer Directory",
    path: "/lawyers-directory/:country/:state/:city",
    component: LawyerDirectory,
    className: "ldirectory-header inner-header",
    menuItem:false,
    loginRequred:false,
  },
  {
    title : "Lawyer Directory",
    path: "/lawyers-directory/:country/:state/:city/:practice_area",
    component: LawyerDirectory,
    className: "ldirectory-header inner-header",
    menuItem:false,
    loginRequred:false,
  },
  {
    title : "Lawyer Directory",
    path: "/lawyer-profile/:id",
    component: LawyerDirectoryDetail,
    className: "gdirectorydetail-header ldirectory-header inner-header",
    menuItem:false,
    loginRequred:false,
  },
  {
    title : "GC Directory",
    path: "/general-counsel",
    component: GcDirectory,
    className: "gdirectory-header inner-header",
    menuItem:true,
    loginRequred:false,
  },
  {
    title : "GC Directory",
    path: "/general-counsel/:id",
    component: GcDirectoryDetail,
    className: "gdirectorydetail-header gdirectory-header inner-header",
    menuItem:false,
    loginRequred:false,
  },
  
  {
    title : "Employment",
    path: "/employment",
    component: Employment,
    className: "employment-header inner-header",
    menuItem:true,
    loginRequred:false,
  },
  {
    title : "Employment",
    path: "/employment-detail/:id",
    component: EmploymentDetail,
    className: "employment-header employment-header inner-header",
    menuItem:false,
    loginRequred:false,
  },
  {
    title : "Grading",
    path: "/ranking",
    component: Ranking,
    className: "ranking-header inner-header",
    menuItem:true,
    loginRequred:false,
  },
  {
    title : "Pricing",
    path: "/pricing",
    component: Pricing,
    className: "pricing-header inner-header",
    menuItem:true,
    loginRequred:false,
    hideAfterLogin:true,
  },

  {
    title : "ChangePassword",
    path: "/change-password",
    component: ChangePassword,
    className: "changepassowrd-header ldirectory-header inner-header",
    menuItem:false,
    loginRequred:true,
  },
  {
    title : "Reset Password",
    path: "/reset-password",
    component: ResetPassword,
    className: "resetpassowrd-header",
    menuItem:false,
    loginRequred:false,
  },
  
  {
    title : "My Profile",
    path: "/add-job",
    component: AddJob,
    className: "addjob-header myprofile-header inner-header",
    menuItem:false,
    loginRequred:true,
  },
  {
    title : "My Profile",
    path: "/edit-job/:id",
    component: AddJob,
    className: "addjob-header myprofile-header inner-header",
    menuItem:false,
    loginRequred:true,
  },
  {
    title : "My Profile",
    path: "/add-publication",
    component: AddPublication,
    className: "addpublication-header myprofile-header inner-header",
    menuItem:false,
    loginRequred:true,
  },
  // {
  //   title : "My Profile",
  //   path: "/EditPublication/:id",
  //   component: AddPublication,
  //   className: "addpublication-header myprofile-header inner-header",
  //   menuItem:false,
  //   loginRequred:true,
  // },
  {
    title : "Upgrade Grading",
    path: "/UpgradeRanking/:id",
    component: UpgradeRanking,
    className: "upgraderanking-header myprofile-header inner-header",
    menuItem:false,
    loginRequred:true,
  },
  {
    title : "Upgrade Grading",
    path: "/Upgraderankingrandom/:id",
    component: UpgradeRankingRandom,
    className: "upgraderanking-header myprofile-header inner-header",
    menuItem:false,
    loginRequred:true,
  },
  {
    title : "My Profile",
    path: "/EditPublication/:id",
    component: AddPublication,
    className: "addpublication-header myprofile-header inner-header",
    menuItem:false,
    loginRequred:true,
  },
  {
    title : "Sitemap",
    path: "/sitemap",
    component: Sitemap,
    className: "sitemap inner-header",
    menuItem:false,
    loginRequred:false,
  },

  
];
export default RoutesArr;