import React, { useEffect, useState, lazy, Suspense } from "react";

import "../../Css/common-inner.css";
import Loader from "../../../componants/Loader/Loader";
// import Testimonial from "./Testimonial";
// import HowItWorks from "./HowItWorks";
import CoreValues from "./CoreValues";
import EventTabs from "./EventTabs";
import HomeSearch from "./HomeSearch";
import BannerImg from "../../../asset/images/banner-image.png";
import howItWorkbackVector from "../../../asset/images/background/howitwork-bg.svg";
import { useSetRecoilState } from "recoil";
import { curRouteDetailsState } from "../../../Helpers/Recoil";
import { apiRequest } from "../../../Helpers/General";
import moment from "moment";
import NoRecord from "../../../componants/NoRecord/NoRecord";
import { directoryMobile } from "../../../Helpers/General";
import DocumentMeta from "react-document-meta";
const Testimonial = lazy(() => import("./Testimonial"));
const HowItWorks = lazy(() => import("./HowItWorks"));

const Landing = (props) => {
  directoryMobile();
  const setCurRouteDetails = useSetRecoilState(curRouteDetailsState);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [coreValue, setCoreValue] = useState([]);
  const [labels, setLabels] = useState([]);
  const [eventDataSeminar, setEventDataSeminar] = useState([]);
  const [eventDataConfrence, setEventDataConfrence] = useState([]);
  const [eventDataSkillDevlopment, setEventDataSkillDevlopment] = useState([]);
  // const [getGeneralData, setGetGeneralData] = useState([]);
  const [howItWorks, setHowItWorks] = useState([]);
  const [networkMasterData, setNetworkMasterData] = useState([]);
  const [gettestimonalData, setGettestimonalData] = useState([]);
  const [homeMetaData, setHomeMetaData] = useState([]);
  useEffect(() => {
    setCurRouteDetails(props.componentInfo);
  });
  useEffect(() => {
    const GetData = async () => {
      const apiSetting = {
        method: "GET",
        apiParams: {
          current_date: moment().format("YYYY-MM-DD H:m:s"),
          country_id: "",
        },
      };
      const apiResult = await apiRequest("get_homepage_detail", apiSetting);
      if (apiResult.data.settings.success === "1") {
        setLabels(
          apiResult.data.data.final_general_data.general_master_final_data
        );
        setCoreValue(apiResult.data.data.core_values);
        setEventDataSeminar(apiResult.data.data.event_data_seminar);
        setEventDataConfrence(apiResult.data.data.event_data_conference);
        setEventDataSkillDevlopment(
          apiResult.data.data.event_data_skill_development
        );
        setEventDataSeminar(apiResult.data.data.event_data_seminar);
        // setGetGeneralData(apiResult.data.data.get_general_data);
        setHowItWorks(apiResult.data.data.how_it_works.slice(0, 3));
        setGettestimonalData(
          apiResult.data.data.get_testimonal_data.slice(0, 3)
        );
        setNetworkMasterData(apiResult.data.data.network_master_data);
        setHomeMetaData(apiResult.data.data.home_meta_data[0]);

        setIsPageLoading(false);
      }
    };

    if (isPageLoading) {
      GetData();
    }
  }, [isPageLoading]);

  const meta = {
    title: homeMetaData && homeMetaData.meta_title,
    description: homeMetaData && homeMetaData.meta_description,
    meta: {
      charset: "utf-8",
      name: {
        keywords: homeMetaData && homeMetaData.meta_key_words,
      },
    },
  };

  if (isPageLoading) {
    return <Loader />;
  }
  return (
    <DocumentMeta {...meta}>
      <>
        <section className="banner-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 order-1 banner-image-col">
                <div className="banner-image-block">
                  <div className="banner-image" data-aos="zoom-in-right">
                    <img
                      src={BannerImg}
                      alt={
                        labels.HOME_HEADER.alt_for_image
                          ? labels.HOME_HEADER.alt_for_image
                          : labels.HOME_HEADER.gm_heading
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 g-0">
                <div className="banner-content d-flex flex-column h-100 row">
                  <div className="banner-title">
                    <h1 data-aos="zoom-in-bottom">
                      {labels.HOME_HEADER.gm_heading}
                    </h1>

                    <p>{labels.HOME_HEADER.gm_short_description}</p>
                  </div>

                  <HomeSearch page="Landing" row={true} OnChange={onchange} />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="event-section" id="mobileEventMenu">
          <div className="container">
            <div className="row tabs_wrapper">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                <div className="page-title">
                  <small>
                    {labels.HOME_EVENTS.gm_description
                      ? labels.HOME_EVENTS.gm_description
                      : "Don't Miss Lates"}
                  </small>

                  <h2>{labels.HOME_EVENTS.gm_heading}</h2>

                  <p className="common-paragraph">
                    {labels.HOME_EVENTS.gm_short_description}
                  </p>
                </div>
                <div className="tabs-links">
                  <ul
                    className="nav nav-tabs tabs flex-row d-flex m-auto w-auto"
                    id="myTab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link "
                        id="network-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#networkmeeting"
                        type="button"
                        role="tab"
                        aria-controls="network-meeting"
                        aria-selected="false"
                      >
                        Networking Meetings
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="webinartab"
                        data-bs-toggle="tab"
                        data-bs-target="#webinar"
                        type="button"
                        role="tab"
                        aria-controls="webinar"
                        aria-selected="false"
                      >
                        Webinars
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="conferencestab"
                        data-bs-toggle="tab"
                        data-bs-target="#conferences"
                        type="button"
                        role="tab"
                        aria-controls="conferences"
                        aria-selected="true"
                      >
                        Conferences
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="skilltab"
                        data-bs-toggle="tab"
                        data-bs-target="#skill"
                        type="button"
                        role="tab"
                        aria-controls="skill"
                        aria-selected="false"
                      >
                        Skill Development Programs
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="tab_container tab-content" id="myTabContent">
                  <div
                    className="tab-pane tab_content network-meeting-tab-content"
                    id="networkmeeting"
                    role="tabpanel"
                    aria-labelledby="network-tab"
                  >
                    <div className="tab-inner-content">
                      <h2 className="mobile-view active">
                        Networking Meetings
                      </h2>
                      <div className="event-tabs-content mt-2 mobile-ftr-row">
                        <div className="event-lst-box d-flex justify-content-between with-event-date">
                          {networkMasterData.length > 0 ? (
                            <EventTabs data={networkMasterData} />
                          ) : (
                            <NoRecord pageName="Networking meetings not scheduled/ No Networking meetings" />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane tab_content"
                    id="webinar"
                    role="tabpanel"
                    aria-labelledby="webinar-tab"
                  >
                    <div className="tab-inner-content">
                      <h2 className="mobile-view">Webinar</h2>
                      <div className="event-tabs-content mt-2 mobile-ftr-row">
                        <div className="event-lst-box d-flex justify-content-between with-event-date">
                          {eventDataSeminar.length > 0 ? (
                            <EventTabs data={eventDataSeminar} />
                          ) : (
                            <NoRecord pageName="Webinars not scheduled / No Webinars" />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane tab_content   show active"
                    id="conferences"
                    role="tabpanel"
                    aria-labelledby="conferences-tab"
                  >
                    <div className="tab-inner-content">
                      <h2 className="mobile-view">Conferences</h2>
                      <div className="event-tabs-content mt-2 mobile-ftr-row">
                        <div className="event-lst-box d-flex justify-content-between with-event-date">
                          {eventDataConfrence.length > 0 ? (
                            <EventTabs data={eventDataConfrence} />
                          ) : (
                            <NoRecord pageName="Conferences not scheduled / No Conferences" />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane tab_content"
                    id="skill"
                    role="tabpanel"
                    aria-labelledby="skill-tab"
                  >
                    <div className="tab-inner-content">
                      <h2 className="mobile-view">Skill Development</h2>
                      <div className="event-tabs-content mt-2 mobile-ftr-row">
                        <div className="event-lst-box d-flex justify-content-between with-event-date">
                          {eventDataSkillDevlopment.length > 0 ? (
                            <EventTabs data={eventDataSkillDevlopment} />
                          ) : (
                            <NoRecord pageName="Skill development programs not scheduled / No Skill development programs" />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="howitwork-section">
          <div className="background-vector">
            <img src={howItWorkbackVector} className="svg" alt="" />
          </div>
          <div className="container">
            <div className="row tabs_wrapper">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="page-title text-center">
                  <small>
                    {labels.HOME_HOW_IT_WORKS.gm_description
                      ? labels.HOME_HOW_IT_WORKS.gm_description
                      : "Simple Process"}
                  </small>
                  <h2 className="title-text">
                    {labels.HOME_HOW_IT_WORKS.gm_heading}
                  </h2>

                  <p className="common-paragraph">
                    {labels.HOME_HOW_IT_WORKS.gm_short_description}
                  </p>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                <div className="custom-content-box">
                  <div className="custom-lst-box">
                    {/* <HowItWorks data={howItWorks} /> */}
                    <Suspense fallback={<Loader />}>
                      <HowItWorks data={howItWorks} />
                    </Suspense>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="core-section">
          <div className="container">
            <div className="row tabs_wrapper">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="page-title text-center">
                  <small>
                    {" "}
                    {labels.HOME_CORE_VALUES.gm_description
                      ? labels.HOME_CORE_VALUES.gm_description
                      : "Our Guiding Principles"}
                  </small>
                  <h2>{labels.HOME_CORE_VALUES.gm_heading}</h2>
                  <p className="common-paragraph">
                    {labels.HOME_CORE_VALUES.gm_short_description}
                  </p>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="core-content">
                  <div className="event-lst-box d-flex justify-content-between with-event-date">
                    <CoreValues data={coreValue} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="testimonial-section">
          <div className="container">
            <div className="row tabs_wrapper">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="page-title text-center">
                  <small>
                    {labels.HOME_TESTIMONIALS.gm_description
                      ? labels.HOME_TESTIMONIALS.gm_description
                      : "Customer Stories"}
                  </small>

                  <h2>{labels.HOME_TESTIMONIALS.gm_heading}</h2>

                  <p className="common-paragraph">
                    {labels.HOME_TESTIMONIALS.gm_short_description}
                  </p>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                <div className="custom-content-box">
                  <div className="custom-lst-box">
                  <Suspense fallback={<Loader />}>
                      <Testimonial data={gettestimonalData} />
                    </Suspense>
                    {/* <Testimonial data={gettestimonalData} /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </DocumentMeta>
  );
};
export default Landing;
