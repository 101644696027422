import { useState, useEffect, useContext, useRef } from "react";
import SelectBox from "../../../componants/SelectBox/SelectBox";
import { MasterContext } from "../../../Context/index";
import { apiRequest } from "../../../Helpers/General";
import { useHistory } from "react-router";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import ReactToast from "../../../componants/Toast/ReactToast";
import { Col, Row, Select } from "antd";

const HomeSearch = (props) => {
  const stateCityRef = useRef([]);
  const history = useHistory();
  var date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const [startDate, setStarDate] = useState(
    moment(firstDay).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment(lastDay).format("YYYY-MM-DD"));
  const [selectedCountry, setSelectedCountry] = useState(
    (props.searchParam && props.searchParam.country) || [
      {
        country_id: "102",
        country: "India",
        country_code: "IN",
        country_dial_code: "+91",
        country_slug: "india"
      },
    ]
  );

  const [selectedStateCity, setSelectedStateCity] = useState(
    (props.searchParam && props.searchParam.statecity) || []
  );
  const [statecityOption, setstatecityOption] = useState([]);
  const [selectedArea, setselectedArea] = useState(
    props.searchParam && props.searchParam.Area
  );
  const [selectedCourt, setselectedCourt] = useState(
    props.searchParam && props.searchParam.Court
  );
  const [selectedName, setselectedName] = useState(
    props.searchParam && props.searchParam.Name
  );
  const { countrydata, courtMasterData, expertiseMasterData } =
    useContext(MasterContext);

  const [getStateDefault, setGetStateDefault] = useState(true);
  const [filtercourtData, setFiltercourtData] = useState([]);
  const [filteredOption, setFilteredOption] = useState(courtMasterData);
  const [lawyerVerified, setLawyerVerified] = useState((props.searchParam && props.searchParam.lawyerVerified) || false);
  
  const changeCountry = async (e, isTrue) => {
    let default_select = false;
    if (e.length <= 0) {
      e = selectedCountry;
      default_select = true;
    }
    setSelectedCountry(e);
    if (!isTrue) {

      if (selectedStateCity.length > 0) {
        stateCityRef.current && stateCityRef.current.clear();
        setSelectedStateCity([]);
      }
    }

    if (e[0] && e[0].country_id && !default_select) {
      const court_Data = courtMasterData.filter((row1) => {
        return row1.cm_country_id && row1.cm_country_id === e[0].country_id;
      });
      setFiltercourtData(court_Data);

      const apiSetting = {
        method: "GET",
        apiParams: { country_id: e[0].country_id },
      };

      const apiResult = await apiRequest("get_city_master", apiSetting);
      if (apiResult.data.settings.success === "1") {
        const city_state_data = apiResult.data.data.filter((row1) => {
          return (row1.city = row1.city + " - " + row1.state);
        });
        setstatecityOption(city_state_data);
        setGetStateDefault(false);
      } else {
        setstatecityOption([]);
        setGetStateDefault(false);
      }
    } else {
      setstatecityOption([]);
      setGetStateDefault(false);
      setFiltercourtData([]);
    }
  };
  const changeState = async (e, isTrue) => {
    setSelectedStateCity(e);
    if (e[0] && e[0].state_id) {
      const courtData = courtMasterData.filter((row) => {
        return row.cm_state_id && row.cm_state_id === e[0].state_id;
      });
      setFiltercourtData(courtData);

    } else {
      setFiltercourtData(courtMasterData);
    }
  };
  useEffect(() => {
    if (getStateDefault) {
      changeCountry(selectedCountry, true);
    }
    if (props.searchParam && props.searchParam.country) {
      changeCountry(props.searchParam.country, true);
    }

    if (props.searchParam && props.searchParam.statecity) {
      changeState(props.searchParam.statecity, true);
    }
  }, []);
  const OnSearch = () => {

    let selectedData = {
      country: selectedCountry,
      statecity: selectedStateCity,
      Area: selectedArea,
      Court: selectedCourt,
      Name: selectedName,
      startDate: startDate,
      endDate: endDate,
      lawyerVerified : lawyerVerified,
      pageIndex: 1,
    };
    if (props.page === "Landing") {
      if (
        selectedData.country === undefined && 
        selectedData.statecity === undefined && 
        selectedData.Area === undefined && 
        selectedData.Court === undefined && 
        selectedData.Name === undefined
      ) {
        ReactToast({ type: "error", message: "Select Dropdown to Search" });
        return false;
      } else {
        localStorage.setItem("searchParam", JSON.stringify(selectedData));
        let query_str = '';
        if (selectedCountry && selectedCountry[0]?.country_slug) {
          query_str += selectedCountry[0].country_slug + "/";
        }
        if (selectedStateCity && selectedStateCity[0]?.state_slug) {
          query_str += selectedStateCity[0].state_slug + "/";
          query_str += selectedStateCity[0].city_slug + "/";
        }
        if (selectedArea && selectedArea[0]?.expertise_slug) {
          query_str += selectedArea[0].expertise_slug + "/";
        }
        history.push("/lawyers-directory/" + query_str);
      }
    } else {
      localStorage.setItem("searchParam", JSON.stringify(selectedData));
      props.OnChange(selectedData);
    }
  };
  const onDateChange = (event, picker) => {
    let start = moment(picker.startDate).format("YYYY-MM-DD");
    let end = moment(picker.endDate).format("YYYY-MM-DD");
    setStarDate(start);
    setEndDate(end);
  };
  function filterData(input) {
    if (input !== "") {
      // Split input into individual words
      const inputWords = input.trim().toLowerCase().split(' ');
      // Arrays to store exact and partial matches
      const exactMatches = [];
      const partialMatches = [];
      const otherMatches = [];
      const data = filtercourtData.length > 0 ? filtercourtData : courtMasterData;
      data.forEach(item => {
        const courtName = item.court_name.toLowerCase();
        const index = courtName.indexOf(input);
        let matchCount = 0;
        inputWords.forEach(word => {
          if (courtName.includes(word)) {
            matchCount++;
          }
        });
        // Calculate percentage based on the number of matched words
        const percentage = Math.floor((matchCount / inputWords.length) * 100);
        if (percentage > 0) {
          if (index === 0) {
            // Exact match found
            exactMatches.push({ ...item, percentage });
          } else if (index > 0) {
            // Partial match found
            partialMatches.push({ ...item, percentage });
          } else {
            // Check if any keyword is included in the module name
            const isMatched = inputWords.some(word => courtName.includes(word));
            if (isMatched) {
              otherMatches.push({ ...item, percentage });
            }
          }
        }
      });
      // Sort exact matches to be displayed first
      exactMatches.sort((a, b) => {
        const aIndex = a.court_name.toLowerCase().indexOf(input);
        const bIndex = b.court_name.toLowerCase().indexOf(input);
        return aIndex - bIndex;
      });
      // Sort partial matches alphabetically
      partialMatches.sort((a, b) => {
        return a.court_name.localeCompare(b.court_name);
      });
      // Combine all matches
      const allMatches = exactMatches.concat(partialMatches, otherMatches);
      allMatches.sort((a, b) => b.percentage - a.percentage);
      return allMatches.filter(item => item.percentage > 50);
    } else {
      return filtercourtData.length > 0 ? filtercourtData : courtMasterData
    }
  }

  const handleSearch = (value) => {
    setFilteredOption(filterData(value))
  }

  useEffect(() => {
    if (filtercourtData.length > 0) {
      setFilteredOption(filtercourtData)
    } else {
      setFilteredOption(courtMasterData)
    }
  }, [filtercourtData, courtMasterData])

  const onCourtChange = (val) => {
    setselectedCourt(courtMasterData.filter(item => item?.court_master_id === val))
  }

  return (
    <div className="search-block" >
      <div className={props.row ? "row" : ""}>
        <div className="form-group col-lg-12" >
          <div className="form-row custom-dropdown" >
            {
              props.row && (
                <label htmlFor="inputCurrency" className="float-start mb-1 d-flex">Country</label>
              )}
            <div className="form-input position-relative" >
              <SelectBox
                className="mdi mdi-chevron-down"
                name="country"
                labelKey="country"
                valueKey="country_id"
                defaultSelected={selectedCountry}
                defaultValue={selectedCountry}
                options={countrydata}
                OnChange={changeCountry}
                placeholder="Select Country"
                isCancel={false}
              />
              <div className="input-err" > </div>
            </div>
          </div>

          < div className="form-row ustom-dropdown" >
            {
              props.row && (
                <label
                  htmlFor="inputCurrency"
                  className="float-start mb-1 d-flex"
                >
                  City / State
                </label>
              )
            }
            < div className="form-input position-relative" >
              <SelectBox
                className="mdi mdi-chevron-down city_state_id"
                name="statcity"
                labelKey="city"
                valueKey="city_state_id"
                defaultSelected={selectedStateCity}
                defaultValue={selectedStateCity}
                options={statecityOption}
                OnChange={changeState}
                placeholder="City / State"
                ref={stateCityRef}
              />
              <div className="input-err" > </div>
            </div>
          </div>

          {
            props.type !== "GC" && props.type !== "Employment" && (
              <div className="form-row custom-dropdown court-of-practice-drop-down" >
                {
                  props.row && (
                    <label
                      htmlFor="inputCurrency"
                      className="float-start mb-1 d-flex"
                    >
                      Court of Practice
                    </ label >
                  )
                }
                <div className="form-input position-relative court-of-practice-select-wrapper" >
                  {/**
                <SelectBox
                  className="mdi mdi-chevron-down"
                  name="state_dropdown "
                  labelKey="court_name"
                  valueKey="court_master_id"
                  defaultSelected={selectedCourt}
                  defaultValue={selectedCourt}
                  onChange={(val) => setselectedCourt(val)}
                  options={filtercourtData || courtMasterData}
                  placeholder="Court of Practice"
                />
                 */}
                  < Select
                    showSearch
                    allowClear
                    style={{ width: 200 }}
                    name="state_dropdown "
                    optionFilterProp="children"                    
                    defaultValue={selectedCourt ? selectedCourt[0]?.court_master_id : null}
                    onSearch={handleSearch}
                    placeholder="Court of Practice"
                    onChange={onCourtChange}
                    dropdownStyle={{ width: "500px" }}
                    options={filteredOption}
                    optionRender={(option) => (
                      <Row>
                        <Col style={{ fontFamily: "Poppins", fontSize: "13px" }} span={12} > {option.label} </Col>
                        
                 {option?.data?.percentage && <Col className="match-per-val" align="right" style={{ color: option?.data?.percentage > 50 ? "#0459CF" : "gray" }} span={12}>{option.data.percentage + " %"}</Col>}
                 
                      </Row>
                    )}
                    fieldNames={{ label: "court_name", value: "court_master_id" }}
                    filterOption={false}
                  />

                  <div className="input-err" > </div>
                </div>
              </div>
            )}
          {
            props.type !== "GC" && props.type !== "Employment" && (
              <div className="form-row custom-dropdown" >
                {
                  props.row && (
                    <label
                      htmlFor="inputCurrency"
                      className="float-start mb-1 d-flex"
                    >
                      Practice Area
                    </ label >
                  )
                }
                <div className="form-input position-relative" >
                  <SelectBox
                    className="mdi mdi-chevron-down"
                    name="state_dropdown "
                    labelKey="expertise_name"
                    valueKey="expertise_master_id"
                    defaultSelected={selectedArea}
                    defaultValue={selectedArea}
                    OnChange={(e) => setselectedArea(e)}
                    options={expertiseMasterData}
                    placeholder="Practice Area"
                  />
                  <div className="input-err" > </div>
                </div>
              </div>
            )}

          {
            props.type !== "GC" && props.type !== "Employment" && (
              <div className="form-row custom-dropdown lawyer_input_wrap" >
                {
                  props.row && (
                    <label
                      htmlFor="inputCurrency"
                      className="float-start mb-1 d-flex"
                    >
                      Lawyer Name
                    </ label >
                  )
                }
                <div className="form-input form-input-div position-relative" >
                  <input
                    type=""
                    className="form-control"
                    name="Lawyer_Name"
                    placeholder="Name"
                    defaultValue={selectedName}
                    onChange={(e) => setselectedName(e.target.value)}
                  />
                  < div className="input-err" > </div>
                </div>
              </div>
            )}
          {
            props.type === "Employment" && (
              <div className="form-row custom-dropdown" >
                {
                  props.row && (
                    <label
                      htmlFor="inputCurrency"
                      className="float-start mb-1 d-flex"
                    >
                      Date
                    </label>
                  )
                }
                < div className="form-input form-input-div position-relative" >
                  <DateRangePicker
                    initialSettings={
                      {
                        startDate: moment().startOf("month").toDate(),
                        endDate: moment().endOf("month").toDate(),
                        locale: {
                          format: "DD/MM/YYYY",
                        },

                        ranges: {
                          Today: [moment().toDate(), moment().toDate()],
                          Yesterday: [
                            moment().subtract(1, "days").toDate(),
                            moment().subtract(1, "days").toDate(),
                          ],
                          "Last 7 Days": [
                            moment().subtract(6, "days").toDate(),
                            moment().toDate(),
                          ],
                          "Last 30 Days": [
                            moment().subtract(29, "days").toDate(),
                            moment().toDate(),
                          ],
                          "This Month": [
                            moment().startOf("month").toDate(),
                            moment().endOf("month").toDate(),
                          ],
                          "Last Month": [
                            moment().subtract(1, "month").startOf("month").toDate(),
                            moment().subtract(1, "month").endOf("month").toDate(),
                          ],
                        },
                      }
                    }
                    onApply={onDateChange}
                  >
                    <input type="text" className="form-control" />
                  </DateRangePicker>
                  < div className="input-err" > </div>
                </div>
              </div>
            )
          }
          {
            props.page == "Lowyer" && (
              <div className="form-input form-input-div position-relative mt-2">
                <div className="form-group">
                    <div className="checkbox">
                        <label className="d-flex align-items-center verified-lawyer-filter">
                            <input 
                                type="checkbox" 
                                id="verified-lawyer" 
                                className="me-2" 
                                name="verified_lawyer" 
                                defaultChecked={lawyerVerified}
                                onChange={() => setLawyerVerified(!lawyerVerified)}                              
                            /> Only Verified Lawyers
                        </label>
                    </div>
                </div>
              </div>
            )
          }
          <div className="search-action" >
            <button type="submit" className="btn-primary" onClick={OnSearch} >
              {props.row ? "Search" : "Apply"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSearch;
