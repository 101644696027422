import Modal from "../Modal/Modal";
import NoRecord from "../../componants/NoRecord/NoRecord"
import {formatDate} from "../../Helpers/General"
const Linetable = (props) => {
  let labels = props.labels || "";
  let title = props.title;
  var lineInfo = props.lineInfo ? props.lineInfo : "";
  let colspan = props.colspan || 7;
  let information = props.information || '';
  let moduleCode   = props.moduleCode || '' ;
  const DeleteAwards = (event) => {
    event.preventDefault();
    try {
      let id = parseInt(event.target.getAttribute("data-id"));
      let newData = lineInfo.filter(row => parseInt(row.awards_id) !== parseInt(id));
      props.setLineInfo(newData);

    } catch (error) {
      console.log("Linetable -> DeleteAwards : ", error);
    }

  }
  const DeletePublicationRef = (event) => {
    event.preventDefault();
    try {
      let id = parseInt(event.target.getAttribute("data-id"));
      let newData = lineInfo.filter(row => parseInt(row.ref_id) !== parseInt(id));
      props.setLineInfo(newData);

    } catch (error) {
      console.log("Linetable -> DeleteAwards : ", error);
    }

  }
  const deleteUserAwards = (event) => {
    event.preventDefault();
    try {
      let id = parseInt(event.target.getAttribute("data-id"));
      let newData = lineInfo.filter(row => parseInt(row.user_awards_id) !== parseInt(id));
      props.setLineInfo(newData);

    } catch (error) {
      console.log("Linetable -> DeleteAwards : ", error);
    }

  }
  const deleteEventAttended = (event) => {
    event.preventDefault();
    try {
      let id = parseInt(event.target.getAttribute("data-id"));
      let newData = lineInfo.filter(row => parseInt(row.out_meeting_id) !== parseInt(id));
      props.setLineInfo(newData);

    } catch (error) {
      console.log("Linetable -> DeleteEventAttended : ", error);
    }

  }
  const deleteEducation = (event) => {
    event.preventDefault();
    try {
      let id = parseInt(event.target.getAttribute("data-id"));
      let newData = lineInfo.filter(row => parseInt(row.Education_id) !== parseInt(id));
      props.setLineInfo(newData);

    } catch (error) {
      console.log("Linetable -> deleteEducation : ", error);
    }

  }
  const deleteUserEducation = (event) => {
    event.preventDefault();
    try {
      let id = parseInt(event.target.getAttribute("data-id"));
      let newData = lineInfo.filter(row => parseInt(row.user_education_id) !== parseInt(id));
      props.setLineInfo(newData);

    } catch (error) {
      console.log("Linetable -> deleteEducation : ", error);
    }

  }
  const deleteOrganisationHistory = (event) => {
    event.preventDefault();
    try {
      let id = parseInt(event.target.getAttribute("data-id"));
      let newData = lineInfo.filter(row => parseInt(row.user_work_organisation_id) !== parseInt(id));
      props.setLineInfo(newData);

    } catch (error) {
      console.log("Linetable -> deleteEducation : ", error);
    }

  }
  const DeleteAdditionalRanking = (event) => {
    event.preventDefault();
    try {
      let id = parseInt(event.target.getAttribute("data-id"));
      let newData = lineInfo.filter(row => parseInt(row.user_rankings_id) !== parseInt(id));
      props.setLineInfo(newData);

    } catch (error) {
      console.log("Linetable -> DeleteAdditionalRanking : ", error);
    }

  }

  const deleteActionDocument = (event) => {
    event.preventDefault();
    try {
      let id = parseInt(event.target.getAttribute("data-id"));
      let newData = lineInfo.filter(row => parseInt(row.document_id) !== parseInt(id));
      props.setLineInfo(newData);

    } catch (error) {
      console.log("Linetable -> deleteActionDocument : ", error);
    }

  }
  return (
    <div className="formview-child-table">
      <div className="formview-child-top-title">
        <div className="page-title">
          {/* <h1>{title}</h1> */}
        </div>
        {props.addBtn === "true" &&

          <div className="custom-head-action d-flex justify-content-end mb-4">
            <div className="custom-tooltip"><i className="mdi mdi-alert-circle-outline"></i>
              <span className="tooltiptext"> {information}</span>
            </div>
            <div className="custom-btn-group custom-btn-secondary-grp">
              <Modal
                btnCoverClass="btn-primary"
                title={title}
                btnTitle="Add"
                btnClass="mdi mdi-plus"
                id="AddChildForm"
                setDoRefresh={props.setDoRefresh} 
                child={props.childComponant}
                setLineInfo={props.setLineInfo}
                previesData={props.lineInfo}
                materialData={props.materialData}
                currency={props.currency}
                {...props}
              />
            </div>
          </div>
        }
      </div>
      <div className="formview-child-mdl-content">
        <div className="custom-table-with-thead">
          <table className="table table-striped table-hover table-bordered align-middle ">
            <thead className="table-light">

              <tr >
                {labels.map((row, index) => (
                  <th key={index} className="text-center">{row}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {title === "Awards" && lineInfo.length > 0 ?
                lineInfo.map((row, key) => (

                  <tr key={key}>
                    <td className="text-center">{row.award_name}</td>
                    <td className="text-center">{row.year}</td>
                    <td className="text-center">
                      <div className="custom-listview-action justify-content-center"  >
                        <button onClick={DeleteAwards} data-id={row.awards_id}
                          className="btn btn-delete mdi mdi-delete">
                        </button>
                      </div>
                    </td>

                  </tr>
                ))
                : moduleCode === "Addition Ranking" && lineInfo.length > 0 ?
                  lineInfo.map((row, key) => (
                    <tr key={key}>
                      <td className="text-center">{row.user_rank_title}</td>
                      <td className="text-center">{row.user_credential_link}</td>
                      <td className="text-center">
                        <div className="custom-listview-action justify-content-center"  >
                          <button onClick={DeleteAdditionalRanking} data-id={row.user_rankings_id}
                            className="btn btn-delete mdi mdi-delete">
                          </button>
                        </div>
                      </td>

                    </tr>
                  ))
                  : title === "Educational Qualifications" && lineInfo.length > 0 ?
                    lineInfo.map((row, key) => (
                      <tr key={key}>
                        <td className="text-center">{row.univercity_name}</td>
                        <td className="text-center">{row.degree_name}</td>
                        <td className="text-center">{row.from}</td>
                        <td className="text-center">{row.to}</td>
                        <td className="text-center">
                          <div className="custom-listview-action justify-content-center"  >
                            <button onClick={deleteEducation} data-id={row.Education_id}
                              className="btn btn-delete mdi mdi-delete">
                            </button>
                          </div>
                        </td>

                      </tr>
                    ))
                    : title === "Document" && lineInfo.length > 0 ?
                      lineInfo.map((row, key) => (
                        <tr key={key}>
                          <td className="text-center">{row.document_name}</td>
                          <td className="text-center">{row.document_category_name}</td>
                          <td className="text-center">{row.remark}</td>

                          <td className="text-center">
                            <div className="custom-listview-action justify-content-center"  >
                              <button onClick={deleteActionDocument} data-id={row.document_id}
                                className="btn btn-delete mdi mdi-delete">
                              </button>
                            </div>
                          </td>

                        </tr>
                      ))
                    : moduleCode === "publication"  && lineInfo.length > 0  ?
                    
                        lineInfo.map((row, key) => {
                  
                          return(
                          <tr key={key}>
                            <td className="text-center">{row.ref_title}</td>
                            <td className="text-center">{row.ref_link}</td>
                            <td className="text-center">
                      <div className="custom-listview-action justify-content-center"  >
                        <button onClick={DeletePublicationRef} data-id={row.ref_id}
                          className="btn btn-delete mdi mdi-delete">
                        </button>
                      </div>
                    </td>
                          </tr>
                        )})
                        : moduleCode === "userAwards"  && lineInfo.length > 0  ?
                    
                        lineInfo.map((row, key) => (
                          <tr key={key}>
                            <td className="text-center">{row.user_award_name}</td>
                            <td className="text-center">{row.user_award_year}</td>
                            <td className="text-center">
                              <div className="custom-listview-action justify-content-center"  >
                                <button onClick={deleteUserAwards} data-id={row.user_awards_id}
                                  className="btn btn-delete mdi mdi-delete">
                                </button>
                              </div>
                            </td>

                          </tr>
                        ))
                        : moduleCode === "userEducation"  && lineInfo.length > 0  ?
                    
                        lineInfo.map((row, key) => (
                          <tr key={key}>
                            <td className="text-center">{row.user_university_name}</td>
                            <td className="text-center">{row.user_degree_name}</td>
                            <td className="text-center">{row.user_from_year}</td>
                            <td className="text-center">{row.user_to_year}</td>
                            <td className="text-center">
                              <div className="custom-listview-action justify-content-center"  >
                                <button onClick={deleteUserEducation} data-id={row.user_education_id}
                                  className="btn btn-delete mdi mdi-delete">
                                </button>
                              </div>
                            </td>

                          </tr>
                        ))
                        : moduleCode === "organisationHistory"  && lineInfo.length > 0  ?
                    
                        lineInfo.map((row, key) => (
                          <tr key={key}>
                            <td className="text-center">{row.user_designation}</td>
                            <td className="text-center">{row.user_org_title}</td>
                            <td className="text-center">{row.user_current_org}</td>
                            <td className="text-center">{formatDate("Date",row.user_from_date)}</td>
                            <td className="text-center">{row.user_to_date === '0000-00-00' ? 'At Present' : formatDate("Date",row.user_to_date)}</td>
                            <td className="text-center">
                              <div className="custom-listview-action justify-content-center"  >
                                <button onClick={deleteOrganisationHistory} data-id={row.user_work_organisation_id}
                                  className="btn btn-delete mdi mdi-delete">
                                </button>
                              </div>
                            </td>

                          </tr>
                        ))

                  : moduleCode === "eventattendent"  && lineInfo.length > 0  ?
                    
                  lineInfo.map((row, key) => (
                    <tr key={key}>
                      <td className="text-center">{row.event_name}</td>
                      <td className="text-center">{formatDate("Date",row.attened_date)}</td>
                      <td className="text-center">{row.venue}</td>                     
                      <td className="text-center">
                        <div className="custom-listview-action justify-content-center"  >
                          <button onClick={deleteEventAttended} data-id={row.out_meeting_id}
                            className="btn btn-delete mdi mdi-delete">
                          </button>
                        </div>
                      </td>

                    </tr>
                  ))

            : <tr><td className="text-center no-record-td" colSpan={colspan}><NoRecord /></td></tr>
              }


            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
export default Linetable;