import { useEffect, useState, useContext } from "react";
import { useSetRecoilState } from "recoil";
import { curRouteDetailsState } from "../../../../Helpers/Recoil";
import EventCard from "../../../../componants/EventCard/EventCard";
import { apiRequest, formatDate } from "../../../../Helpers/General";
import iconSearch from "./../../../../asset/svg/icon-search.svg";
import Pagination from "react-bootstrap-4-pagination";
import NoRecord from "../../../../componants/NoRecord/NoRecord";
import { useParams } from "react-router";

import Loader from "../../../../componants/Loader/Loader";
import DocumentMeta from "react-document-meta";
import { MasterContext } from "../../../../Context";
import SelectBox from "../../../../componants/SelectBox/SelectBox";
const Events = (props) => {
  const eventClickFunction = (event, id) => {
    var h2s = document.querySelectorAll("#" + id + " h2");
    if (event.target && event.target.classList.contains("active")) {
      event.target.classList.remove("active");
    } else {
      for (var i = 0; i < h2s.length; i++) {
        h2s[i].classList.remove("active");
      }
      if (event.target && event.target.nodeName === "H2") {
        event.target.classList.add("active");
      }
    }
  };

  let { type } = useParams();
  const setCurRouteDetails = useSetRecoilState(curRouteDetailsState);
  const { genralMasterData } = useContext(MasterContext);
  let eventLabel = genralMasterData.EVENTS;
  const [eventData, setEventData] = useState([]);
  const [eventMetaData, setEventMetaData] = useState([]);
  const [isPageUpdating, setIsPageUpdating] = useState(true);
  const [filterData, setFilterData] = useState([]);
  const [meetingFor, setMeetingFor] = useState("Domestic");
  const [eventType, setEventType] = useState("seminar");
  const [paginationData, setPaginationData] = useState([]);
  const [pageIndex, setpageIndex] = useState("1");
  useEffect(() => {
    setCurRouteDetails(props.componentInfo);
  });
  useEffect(() => {
    if (type) {
      if (type === "Webinar") {
        setEventType("seminar");
      } else if (type === "Conferences") {
        setEventType("conference");
      } else {
        setEventType("Skill Development");
      }
      setIsPageUpdating(true);
    }
  }, [type]);
  useEffect(() => {
    const GetData = async () => {
      try {
        const apiSetting = {
          method: "POST",
          apiParams: {
            country_id: "102",
            current_date: formatDate("systemDateTime"),
            event_type: eventType,
            event_for: meetingFor,
            page_index: pageIndex,
            user_type: props.userProfile ? props.userProfile.user_type : "",
            event_view_type : selectedValue && selectedValue[0] ? selectedValue[0]['id'] : "Upcoming"
          },
        };

        const apiResult = await apiRequest(
          "get_upcoming_events_list",
          apiSetting
        );

        setPaginationData(apiResult.data.settings);
        if (apiResult.data.settings.success === "1") {
          setEventData(apiResult.data.data.upcoming_event_list);
          setEventMetaData(apiResult.data.data.event_meta_data[0]);
          setFilterData(apiResult.data.data.upcoming_event_list);
          setIsPageUpdating(false);
        } else {
          setEventData([]);
          setEventMetaData(apiResult.data.data ? apiResult.data.data[0] : []);
          setFilterData([]);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsPageUpdating(false);
      }
    };
    if (isPageUpdating) {
      GetData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPageUpdating, eventType, meetingFor, pageIndex]);
  const onSearch = (e) => {
    let Text = e.target.value;
    if (Text) {
      const filtered = eventData.filter((row) => {
        return (
          (row.meeting_type &&
            row.meeting_type.toLowerCase().includes(Text.toLowerCase())) ||
          (row.meeting_title &&
            row.meeting_title.toLowerCase().includes(Text.toLowerCase())) ||
          (row.meeting_description &&
            row.meeting_description.toLowerCase().includes(Text.toLowerCase()))
        );
      });
      setFilterData(filtered);
    } else {
      setFilterData(eventData);
    }
  };

  let paginationConfig = {
    totalPages: Math.ceil(paginationData.count / 20),
    currentPage: parseInt(pageIndex),
    showMax: 5,
    size: "lg",
    // threeDots: true,
    // prevNext: true,
    onClick: function (index) {
      setpageIndex(index.toString());
      setIsPageUpdating(true);
    },
  };

  const meta = {
    title: eventMetaData && eventMetaData.meta_title,
    description: eventMetaData && eventMetaData.meta_description,
    meta: {
      charset: "utf-8",
      name: {
        keywords: eventMetaData && eventMetaData.meta_key_words,
      },
    },
  };

    let meetingType = [
        {'id' : 'Upcoming','val' : 'Upcoming'},
        {'id' : 'Archive','val' : 'Archive'}
    ];

    const[selectedValue,setSelectedValue] = useState([{'id' : 'Upcoming','val' : 'Upcoming'}]);
    const changeEventType = async(e) => {
        if(e.length > 0) {
            setSelectedValue(e);
        } else {
            setSelectedValue([{'id' : 'Upcoming','val' : 'Upcoming'}]);
        }
        setIsPageUpdating(true);
    }

  return (
    <DocumentMeta {...meta}>
      <div className="inner-page events-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner-head d-flex align-item-center">
                <div className="inner-head-left">
                  <div className="page-title  text-start">
                    <h2>
                      {eventLabel && eventLabel.gm_heading
                        ? eventLabel.gm_heading
                        : "Event Topics"}
                    </h2>
                  </div>
                </div>
                <div className="inner-header-rgt ms-auto d-flex align-items center">
                  <div className="head-tabs d-flex">
                    <ul className="nav nav-tabs tabs" id="myTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link domestic-tab-link active"
                          id="domestic-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#domestic"
                          type="button"
                          role="tab"
                          aria-controls="domestic"
                          aria-selected="false"
                          onClick={() => {
                            setMeetingFor("Domestic");
                            setEventType("Seminar");
                            setIsPageUpdating(true);
                          }}
                        >
                          Domestic
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link international-tab-link"
                          id="international-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#international"
                          type="button"
                          role="tab"
                          aria-controls="international"
                          aria-selected="true"
                          onClick={() => {
                            setMeetingFor("International");
                            setEventType("Seminar");
                            setIsPageUpdating(true);
                          }}
                        >
                          International
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="search-field">
                    <div className="form-row">
                      <div className="form-input-div">
                        <input
                          type="text"
                          placeholder="Search"
                          className="form-control"
                          onChange={onSearch}
                        />
                        <i className="search-icon">
                          <img src={iconSearch} className="svg" alt="" />
                        </i>
                      </div>
                    </div>
                  </div>
                  <div className="form-row custom-dropdow archive-dropdown">
                        <div className="form-input position-relative">
                        <SelectBox
                            className="mdi mdi-chevron-down"
                            name="event_view_type"
                            labelKey="id"
                            valueKey="val"
                            defaultSelected={selectedValue}
                            defaultValue={selectedValue}
                            options={meetingType}
                            OnChange={changeEventType}
                            placeholder="Select Event Type"
                            isCancel = {false}
                        />
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="common-page-content networking-content">
              <div className="tab_container tab-content" id="myTabContent">
                <div
                  className="tab-pane tab_content fade show active"
                  id="domestic"
                  role="tabpanel"
                  aria-labelledby="domestic-tab"
                >
                  <div className="tab-inner-content">
                    <div className="tabs-links inner-tabs-links">
                      <ul
                        className="nav nav-tabs tabs flex-row d-flex m-auto w-auto"
                        id="myTab2"
                        role="tablist"
                      >
                        <li className="nav-item" role="presentation">
                          <button
                            className={
                              "domestic-tab-link nav-link " +
                              (type
                                ? type === "Webinar"
                                  ? "active"
                                  : ""
                                : "active")
                            }
                            id="webinartab1"
                            data-bs-toggle="tab"
                            data-bs-target="#webinar1"
                            type="button"
                            role="tab"
                            aria-controls="webinar1"
                            aria-selected="false"
                            onClick={() => {
                              setEventType("seminar");
                              setIsPageUpdating(true);
                            }}
                          >
                            Webinars
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className={
                              "domestic-tab-link nav-link" +
                              (type && type === "Conferences" ? " active" : "")
                            }
                            id="conferencestab1"
                            data-bs-toggle="tab"
                            data-bs-target="#conferences1"
                            type="button"
                            role="tab"
                            aria-controls="conferences1"
                            aria-selected="false"
                            onClick={() => {
                              setEventType("conference");
                              setIsPageUpdating(true);
                            }}
                          >
                            Conferences
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className={
                              "domestic-tab-link nav-link" +
                              (type && type === "Skill" ? " active" : "")
                            }
                            id="skilltab1"
                            data-bs-toggle="tab"
                            data-bs-target="#skill1"
                            type="button"
                            role="tab"
                            aria-controls="skill1"
                            aria-selected="false"
                            onClick={() => {
                              setEventType("Skill Development");
                              setIsPageUpdating(true);
                            }}
                          >
                            Skill Development Programs
                          </button>
                        </li>
                      </ul>
                    </div>
                    <div
                      className="inner-tab-content tab_container tab-content"
                      id="mobileDomesticEventMenu"
                      onClick={(event) =>
                        eventClickFunction(event, "mobileDomesticEventMenu")
                      }
                    >
                      <div
                        className="tab-pane tab_content active show"
                        id="webinar1"
                        role="tabpanel"
                        aria-labelledby="webinar-tab"
                      >
                        <div className="tab-inner-content">
                          <h2 className="active mobile-view">Webinar</h2>
                          <div className="event-tabs-content mobile-ftr-row ">
                            <div className="event-lst-box d-flex with-event-date">
                              {filterData.length > 0 ? (
                                filterData.map((row, key) => {
                                  if (isPageUpdating) {
                                    return <Loader key={key} />;
                                  } else {
                                    return (
                                      <EventCard
                                        row={row}
                                        key={key}
                                        title={props.componentInfo.title}
                                      />
                                    );
                                  }
                                })
                              ) : (
                                <NoRecord pageName="Webinars not scheduled / No Webinars" />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane tab_content "
                        id="conferences1"
                        role="tabpanel"
                        aria-labelledby="conferences-tab"
                      >
                        <div className="tab-inner-content">
                          <h2 className="mobile-view">Conferences</h2>
                          <div className="event-tabs-content mobile-ftr-row">
                            <div className="event-lst-box d-flex with-event-date">
                              {filterData.length > 0 ? (
                                filterData.map((row, key) => {
                                  if (isPageUpdating) {
                                    return <Loader key={key} />;
                                  } else {
                                    return (
                                      <EventCard
                                        row={row}
                                        key={key}
                                        title={props.componentInfo.title}
                                      />
                                    );
                                  }
                                })
                              ) : (
                                <NoRecord pageName="Conferences not scheduled / No Conferences" />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane tab_content "
                        id="skill1"
                        role="tabpanel"
                        aria-labelledby="skill-tab"
                      >
                        <div className="tab-inner-content">
                          <h2 className="mobile-view">Skills</h2>
                          <div className="event-tabs-content mobile-ftr-row">
                            <div className="event-lst-box d-flex with-event-date">
                              {filterData.length > 0 ? (
                                filterData.map((row, key) => {
                                  if (isPageUpdating) {
                                    return <Loader key={key} />;
                                  } else {
                                    return (
                                      <EventCard
                                        row={row}
                                        key={key}
                                        title={props.componentInfo.title}
                                      />
                                    );
                                  }
                                })
                              ) : (
                                <NoRecord pageName="Skill development programs not scheduled / No Skill development programs" />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane tab_content fade "
                  id="international"
                  role="tabpanel"
                  aria-labelledby="international-tab"
                >
                  <div className="tab-inner-content">
                    <div className="tabs-links inner-tabs-links">
                      <ul
                        className="nav nav-tabs tabs flex-row d-flex m-auto w-auto"
                        id="myTab2"
                        role="tablist"
                      >
                        <li className="nav-item" role="presentation">
                          <button
                            className={
                              "nav-link " +
                              (type
                                ? type === "Webinar"
                                  ? "active"
                                  : ""
                                : "active")
                            }
                            id="webinartab"
                            data-bs-toggle="tab"
                            data-bs-target="#webinar"
                            type="button"
                            role="tab"
                            aria-controls="webinar"
                            aria-selected="false"
                            onClick={() => {
                              setEventType("seminar");
                              setIsPageUpdating(true);
                            }}
                          >
                            Webinars
                          </button>
                        </li>
                        <li className="nav-item " role="presentation">
                          <button
                            className={
                              "nav-link" +
                              (type && type === "Conferences" ? " active" : "")
                            }
                            id="conferencestab"
                            data-bs-toggle="tab"
                            data-bs-target="#conferences"
                            type="button"
                            role="tab"
                            aria-controls="conferences"
                            aria-selected="false"
                            onClick={() => {
                              setEventType("conference");
                              setIsPageUpdating(true);
                            }}
                          >
                            Conferences
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className={
                              "nav-link" +
                              (type && type === "Skill" ? " active" : "")
                            }
                            id="skilltab"
                            data-bs-toggle="tab"
                            data-bs-target="#skill"
                            type="button"
                            role="tab"
                            aria-controls="skill"
                            aria-selected="false"
                            onClick={() => {
                              setEventType("Skill Development");
                              setIsPageUpdating(true);
                            }}
                          >
                            Skill Development Programs
                          </button>
                        </li>
                      </ul>
                    </div>

                    <div
                      className="inner-tab-content tab_container tab-content"
                      id="mobileInternationalEventMenu"
                      onClick={(event) =>
                        eventClickFunction(
                          event,
                          "mobileInternationalEventMenu"
                        )
                      }
                    >
                      <div
                        className={
                          "tab-pane tab_content fade " +
                          (type
                            ? type === "Webinar"
                              ? "active show"
                              : ""
                            : "active show")
                        }
                        id="webinar"
                        role="tabpanel"
                        aria-labelledby="webinar-tab"
                      >
                        <div className="tab-inner-content">
                          <h2 className="active mobile-view">Webinar</h2>
                          <div className="event-tabs-content mobile-ftr-row ">
                            <div className="event-lst-box d-flex with-event-date">
                              {filterData.length > 0 ? (
                                filterData.map((row, key) => {
                                  if (isPageUpdating) {
                                    return <Loader key={key} />;
                                  } else {
                                    return (
                                      <EventCard
                                        row={row}
                                        key={key}
                                        title={props.componentInfo.title}
                                      />
                                    );
                                  }
                                })
                              ) : (
                                <NoRecord pageName="Webinars not scheduled / No Webinars" />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          "tab-pane tab_content " +
                          (type && type === "Conferences" ? "active show" : "")
                        }
                        id="conferences"
                        role="tabpanel"
                        aria-labelledby="conferences-tab"
                      >
                        <div className="tab-inner-content">
                          <h2 className="mobile-view">Conferences</h2>
                          <div className="event-tabs-content mobile-ftr-row ">
                            <div className="event-lst-box d-flex with-event-date">
                              {filterData.length > 0 ? (
                                filterData.map((row, key) => {
                                  if (isPageUpdating) {
                                    return <Loader key={key} />;
                                  } else {
                                    return (
                                      <EventCard
                                        row={row}
                                        key={key}
                                        title={props.componentInfo.title}
                                      />
                                    );
                                  }
                                })
                              ) : (
                                <NoRecord pageName="Conferences not scheduled / No Conferences" />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={
                          "tab-pane tab_content " +
                          (type && type === "Skill" ? "active show" : "")
                        }
                        id="skill"
                        role="tabpanel"
                        aria-labelledby="skill-tab"
                      >
                        <div className="tab-inner-content">
                          <h2 className="mobile-view">Skills</h2>
                          <div className="event-tabs-content mobile-ftr-row ">
                            <div className="event-lst-box d-flex with-event-date">
                              {filterData.length > 0 ? (
                                filterData.map((row, key) => {
                                  if (isPageUpdating) {
                                    return <Loader key={key} />;
                                  } else {
                                    return (
                                      <EventCard
                                        row={row}
                                        key={key}
                                        title={props.componentInfo.title}
                                      />
                                    );
                                  }
                                })
                              ) : (
                                <NoRecord pageName="Skill development programs not scheduled / No Skill development programs" />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {paginationData.count > 0 && <Pagination {...paginationConfig} />}
          </div>
        </div>
      </div>
    </DocumentMeta>
  );
};

export default Events;
