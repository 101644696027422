import iconProfile from "../../../asset/svg/icon-profile.svg";
import iconEditProfile from "../../../asset/svg/icon-edit-profile.svg";
import iconMembership from "../../../asset/svg/icon-membership.svg";
import iconPublication from "../../../asset/svg/icon-publication.svg";
import iconPhoto from "../../../asset/svg/icon-photos.svg";
import iconVideo from "../../../asset/svg/icon-videos.svg";
import iconBooking from "../../../asset/svg/icon-calendar.svg";
import iconJob from "../../../asset/svg/icon-jonopp.svg";
import iconSetting from "../../../asset/svg/icon-settings.svg";

const ProfileNavigation = (props) => {
  return (
    <div className="right-search-block profile-navigation">
      <div className="right-block-title d-flex flex-column">
        <h2 className="mb-5">
          My <span>Navigation</span>
        </h2>
      </div>
      <div className="right-block-content">
        <div className="card">
          <div className="card-content">
            <ul
              className="nav nav-tabs tabs flex-column d-flex"
              id="myTab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="viewprofile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#viewprofileTab"
                  type="button"
                  role="tab"
                  onClick={()=>props.setIsOnPage("Profile")}
                >
                  <img src={iconProfile} className="svg" alt="" /> Profile
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="editprofile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#editprofileTab"
                  type="button"
                  role="tab"
                  onClick={()=>props.setIsOnPage("Edit Profile")}
                >
                  <img src={iconEditProfile} className="svg" alt="" /> Edit
                  Profile
                </button>
              </li>
              {props.userType !== "Gc" && (
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="membership-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#membershipTab"
                    type="button"
                    role="tab"
                    onClick={()=>props.setIsOnPage("Memberships")}
                  >
                    <img src={iconMembership} className="svg" alt="" />{" "}
                    Memberships
                  </button>
                </li>
              )}
              {props.userType !== "Gc" && (
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="publications-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#publicationsTab"
                    type="button"
                    role="tab"
                    onClick={()=>props.setIsOnPage("Publications")}
                  >
                    <img src={iconPublication} className="svg" alt="" />{" "}
                    Publications
                  </button>
                </li>
              )}
              {props.userType !== "Gc" && (
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="photos-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#photosTab"
                    type="button"
                    role="tab"
                    onClick={()=>props.setIsOnPage("Photos")}
                  >
                    <img src={iconPhoto} className="svg" alt="" /> Photos
                  </button>
                </li>
              )}
              {props.userType !== "Gc" && (
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="videos-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#videosTab"
                    type="button"
                    role="tab"
                    onClick={()=>props.setIsOnPage("Videos")}
                  >
                    <img src={iconVideo} className="svg" alt="" /> Videos
                  </button>
                </li>
              )}
              
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="bookings-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#bookingsTab"
                    type="button"
                    role="tab"
                    onClick={()=>props.setIsOnPage("Bookings")}
                  >
                    <img src={iconBooking} className="svg" alt="" /> Bookings
                  </button>
                </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="jobopp-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#joboppTab"
                  type="button"
                  role="tab"
                  onClick={()=>props.setIsOnPage("Job Opportunities")}
                >
                  <img src={iconJob} className="svg" alt="" /> Job Opportunities
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="settings-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#settingsTab"
                  type="button"
                  role="tab"
                  onClick={()=>props.setIsOnPage("Settings")}
                >
                  <img src={iconSetting} className="svg" alt="" /> Settings
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileNavigation;
