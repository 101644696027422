import React, { useState } from "react";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import { apiRequest } from "../../../../Helpers/General";
const UploadVideoForm = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const id = props.dataId || "";
  const [loading, setLoading] = useState(false);
  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const apiSetting = {
        method: "POST",
        apiParams: {
          user_id: id,
          file_type: "Video",
          file_name: data.YouTubevideo_Link,
        },
      };
      const apiResult = await apiRequest("user_gallery_add", apiSetting);
      setLoading(false);
      if (apiResult.data.settings.success === "1") {
        swal({
          title: "Success",
          text: "Video Uploaded Successfully.",
          icon: "success",
          button: {
            text: "Done",
            closeModal: true,
          },
          dangerMode: false,
        });
      } else {
        swal({
          title: "Error",
          text: apiResult.data.settings.message,
          icon: "warning",
          button: {
            text: "Close",
            closeModal: true,
          },
          dangerMode: true,
        });
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
    // let formData = props.previesData;
    // let row_id = formData.length + 1;
    // formData.push({
    //   row_id: row_id,
    //   YouTubevideo_Link: data.YouTubevideo_Link,
    // });
    // props.onChange(formData);
    props.setIsPageUpdating(true);
    props.closeModal();
  };

  return (
    <div
      className="ReactModal-namechange-content-container ReactModal-Content-Container"
      id="name_change_form"
    >
      <div className="ReactModal-middle-container">
        <form
          id="contact-person-add-hook-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <input
            type="hidden"
            id="inputId"
            className="form-control"
            {...register("id")}
          />
          {/* <div className="form-group">
            <div className="profile-content width75">  */}
          <div className="form-group">
            <div className="form-row mb-3 ">
              <label
                htmlFor="inputYouTubevideoLink "
                className="float-start mb-1"
              >
                YouTube video Link <em>*</em>
              </label>
              <div className="input-group">
                <input
                  type="text"
                  id="inputYouTubevideoLink "
                  className="form-control"
                  placeholder="Add Url "
                  {...register("YouTubevideo_Link", {
                    required: "YouTube video link is required",
                    pattern: {
                      value:
                        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|v=|\?v=)([^#]*).*/,
                      message: "Enter a valid Url",
                    },
                  })}
                />
              </div>
              <div className="input-err">
                {errors?.YouTubevideo_Link?.message}
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="ReactModal-Footer">
        <div className="ReactModal-ActionBlock profile-action-area">
          <div className="btn-grp m-auto">
            {loading ? (
              <button
                form="contact-person-add-hook-form"
                className="w-20 btn btn-primary m-auto"
                disabled
              >
                <div
                  className="spinner-border text-white  text-center"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </button>
            ) : (
              <button
                form="contact-person-add-hook-form"
                className="w-20 btn btn-primary m-auto"
                onClick={handleSubmit(onSubmit)}
              >
                <i className="mdi mdi-check"></i> Submit
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadVideoForm;
