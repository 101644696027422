import React, { useEffect, useState,useContext } from "react";
import { useSetRecoilState } from "recoil";
import { curRouteDetailsState } from "../../../../Helpers/Recoil";
import EventCard from "../../../../componants/EventCard/EventCard";
import iconSearch from "./../../../../asset/svg/icon-search.svg";
// import NetworkBg01 from "../../../../asset/images/background/card-background-bg.png";
import Pagination from "react-bootstrap-4-pagination";
import { apiRequest, formatDate } from "../../../../Helpers/General";
import NoRecord from "../../../../componants/NoRecord/NoRecord";
import Loader from "../../../../componants/Loader/Loader";
import DocumentMeta from "react-document-meta";
import { MasterContext } from "../../../../Context";
import SelectBox from "../../../../componants/SelectBox/SelectBox";

const Networking = (props) => {
  const setCurRouteDetails = useSetRecoilState(curRouteDetailsState);
  const{genralMasterData}= useContext(MasterContext);
  let networkingLabel=genralMasterData.NETWORKING
  const [upcomingNetworkData, setUpcomingNetworkData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [isPageUpdating, setIsPageUpdating] = useState(true);
  const [meetingFor, setMeetingFor] = useState("Domestic");
  // eslint-disable-next-line
  const [paginationData, setPaginationData] = useState([]);
  const [pageIndex, setpageIndex] = useState("1");

  const [upcomingNetworkMetaData, setUpcomingNetworkMetaData] = useState([]);

  useEffect(() => {
    setCurRouteDetails(props.componentInfo);
  });
  useEffect(() => {
    const GetData = async () => {
      try {
        const apiSetting = {
          method: "POST",
          apiParams: {
            country_id: "102",
            current_date: formatDate("systemDateTime"),
            network_meeting_for: meetingFor,
            page_index: pageIndex,
            user_type:props.userProfile?props.userProfile.user_type:"",
            meeting_view_type : selectedValue && selectedValue[0] ? selectedValue[0]['id'] : "Upcoming"
          },
        };
        const apiResult = await apiRequest(
          "get_upcoming_network_meetings_list",
          apiSetting
        );
        //   setPaginationData(apiResult.data.settings);
        if (apiResult.data.settings.success === "1") {
          setUpcomingNetworkData(apiResult.data.data.network_meeting_list);
          setUpcomingNetworkMetaData(apiResult.data.data.network_meta_data[0]);
          setFilterData(apiResult.data.data.network_meeting_list);
          setIsPageUpdating(false);
        } else {
          setUpcomingNetworkData([]);
          setUpcomingNetworkMetaData(apiResult.data.data ? apiResult.data.data[0] : []);
          setFilterData([]);
          setIsPageUpdating(false);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsPageUpdating(false);
      }
    };
    if (isPageUpdating) {
      GetData();
    }
    // eslint-disable-next-line
  }, [isPageUpdating, meetingFor, pageIndex]);

  const onSearch = (e) => {
    let Text = e.target.value;
    if (Text) {
      const filtered = upcomingNetworkData.filter((row) => {
        return (
          (row.meeting_type &&
            row.meeting_type.toLowerCase().includes(Text.toLowerCase())) ||
          (row.meeting_title &&
            row.meeting_title.toLowerCase().includes(Text.toLowerCase())) ||
          (row.meeting_description &&
            row.meeting_description.toLowerCase().includes(Text.toLowerCase()))
        );
      });
      setFilterData(filtered);
    } else {
      setFilterData(upcomingNetworkData);
    }
  };
  let paginationConfig = {
    totalPages: Math.ceil(paginationData.count / 20),
    currentPage: parseInt(pageIndex),
    showMax: 5,
    size: "lg",
    threeDots: true,
    prevNext: true,
    onClick: function (page) {
      setpageIndex(page.toString());
      setIsPageUpdating(true);
    },
  };
  const meta = {
    title: upcomingNetworkMetaData && upcomingNetworkMetaData.meta_title,
    description: upcomingNetworkMetaData && upcomingNetworkMetaData.meta_description,
    meta: {
      charset: "utf-8",
      name: {
        keywords: upcomingNetworkMetaData && upcomingNetworkMetaData.meta_key_words,
      },
    },
  };

    let meetingType = [
        {'id' : 'Upcoming','val' : 'Upcoming'},
        {'id' : 'Archive','val' : 'Archive'}
    ];

    const[selectedValue,setSelectedValue] = useState([{'id' : 'Upcoming','val' : 'Upcoming'}]);
    const changeMeetingType = async(e) => {
        if(e.length > 0) {
            setSelectedValue(e);
        } else {
            setSelectedValue([{'id' : 'Upcoming','val' : 'Upcoming'}]);
        }
        setIsPageUpdating(true);
    }

  return (
    <DocumentMeta {...meta}>
      <div className="inner-page networking-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner-head d-flex align-item-center">
                <div className="inner-head-left">
                  <div className="page-title  text-start">
                    <h2>{networkingLabel&&networkingLabel.gm_heading?networkingLabel.gm_heading:"Upcoming Meetings"}</h2>
                  </div>
                </div>
                <div className="inner-header-rgt ms-auto d-flex align-items center">
                  <div className="head-tabs d-flex">
                    <ul className="nav nav-tabs tabs" id="myTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link domestic-tab-link active"
                          id="domestic-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#domestic"
                          type="button"
                          role="tab"
                          aria-controls="domestic"
                          aria-selected="false"
                          onClick={() => {
                            setMeetingFor("Domestic");
                            setIsPageUpdating(true);
                          }}
                        >
                          Domestic
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link international-tab-link"
                          id="international-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#international"
                          type="button"
                          role="tab"
                          aria-controls="international"
                          aria-selected="true"
                          onClick={() => {
                            setMeetingFor("International");
                            setIsPageUpdating(true);
                          }}
                        >
                          International
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="search-field">
                    <div className="form-row">
                      <div className="form-input-div">
                        <input
                          type="text"
                          placeholder="Search"
                          className="form-control"
                          onChange={onSearch}
                        />
                        <i className="search-icon">
                          <img alt="" src={iconSearch} className="svg" />
                        </i>
                      </div>
                    </div>
                  </div>
                    <div className="form-row custom-dropdown archive-dropdown">
                        <div className="form-input position-relative">
                        <SelectBox
                            className="mdi mdi-chevron-down"
                            name="meeting_view_type"
                            labelKey="id"
                            valueKey="val"
                            defaultSelected={selectedValue}
                            defaultValue={selectedValue}
                            options={meetingType}
                            OnChange={changeMeetingType}
                            placeholder="Select Meeting Type"
                            isCancel = {false}
                        />
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="common-page-content networking-content">
              <div
                className="tab_container tab-content network-meeting-tab-content"
                id="myTabContent"
              >
                <div
                  className="tab-pane tab_content fade  show active domestic-tab-pane "
                  id="domestic"
                  role="tabpanel"
                  aria-labelledby="domestic-tab"
                >
                  <div className="tab-inner-content">
                    <div className="event-lst-box d-flex with-event-date flex-row">
                      {filterData.length > 0 ? (
                        filterData.map((row, key) => {
                          if (isPageUpdating) {
                            return <Loader key={key} />
                          } else {

                            return (
                              <EventCard
                                row={row}
                                key={key}
                                title={props.componentInfo.title}
                              />
                            );
                          }
                        })
                      ) : (
                        <NoRecord pageName={'Networking meetings not scheduled/ No Networking meetings'} />
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane tab_content fade international-tab-pane"
                  id="international"
                  role="tabpanel"
                  aria-labelledby="international-tab"
                >
                  <div className="tab-inner-content">
                    <div className="event-lst-box d-flex with-event-date">
                      {filterData.length > 0 ? (
                        filterData.map((row, key) => {
                          if (isPageUpdating) {
                            return <Loader key={key} />
                          } else {

                            return (
                              <EventCard
                                row={row}
                                key={key}
                                title={props.componentInfo.title}
                              />
                            );
                          }
                        })
                      ) : (                        
                        <NoRecord pageName={'Networking meetings not scheduled/ No Networking meetings'} />
                      )}
                    </div>
                  </div>
                </div>

                {paginationData.count > 0 && <Pagination {...paginationConfig} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </DocumentMeta>
  );
};

export default Networking;
