import { useState } from "react";
import Rating from "react-rating";


import iconStar from "../../../../../src/asset/svg/star_yellow.png";
import iconStarEmpty from "../../../../../src/asset/svg/star_gray.png";
import WriteReview from "../../../LoginNotRequired/Auth/WriteReview";
import Modal from "../../../../../src/componants/Modal/Modal";



function Reviewrating(props) {
    const [openModal , setOpenModal] = useState(false);
    return(
        <div className="review-rating-lawyer mt-3">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="common-block-title">
                        <h3>Review & Ratings</h3>
                        
                        <div className="add-review-rating-btn">
                            
                            {props.row.user_user_type == "Lawyer" && !props.userProfile && (
                                <>
                                    <div className="review-block me-2">
                                        <button className="btn-primary m-auto" onClick={() => {
                                            setOpenModal(true);
                                        }}> Write Review</button>
                                    </div>
                                    {openModal && <Modal
                                        title="Write a Review &amp; Ratings"
                                        btnTitle=""
                                        isOpen={true}
                                        btnCoverClass="btn-primary"
                                        btnClass="mdi mdi-plus"
                                        id="addReview"
                                        data={props.row}
                                        setOpenModal={setOpenModal}
                                        button_hide = {true}
                                        child={WriteReview}
                                    /> }
                                </>
                            )}
                            
                        </div>
                    </div>
                    <div className="common-block-content">
                    
                        <div className="review-rating-list">
                            {props.rating && props.rating.map((val,key)=>(
                                <div key={key} className="review-rating-list-element mb-3 pb-3">
                                    <div className="review-block">
                                        <div className="review-info-left">
                                            <div className="reviewer_name">{val.reviewer_name}</div>
                                            <div className="review_post_date"> 
                                             {val.review_date}
                                            </div>
                                        </div>
                                        <div className="review-info-right">
                                            { val.rating > 0 && ( 
                                                <div className="user-review-rating-wrap">
                                                    <div className="rating-count">
                                                        {val.rating}
                                                    </div>
                                                    <div className="star-review">
                                                        <Rating 
                                                            initialRating={val.rating} 
                                                            readonly
                                                            start={0}
                                                            stop={5}
                                                            fractions={2}
                                                            step={1} 
                                                            emptySymbol={<img src={iconStarEmpty}/>} 
                                                            fullSymbol={<img src={iconStar}/>} 
                                                            className="star-wrap"
                                                        />
                                                    </div>
                                                </div>
                                                )}
                                        </div>
                                    </div>
                                    {
                                        val.comment !== "" && (<div className="rating-comment mt-3">{val.comment}</div>)
                                    }
                                </div>
                            ))}
                        </div>
                    
                    </div>
                </div>
            </div>
        
        
        
            
        </div>
    )
}
export default Reviewrating;