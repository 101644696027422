import React from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import { apiRequest } from "../../../Helpers/General";
import { useState } from "react";
import Rating from "react-rating";


import iconStar from "../../../../src/asset/svg/star_yellow.png";
import iconStarEmpty from "../../../../src/asset/svg/star_gray.png";


import { getVariable } from "../../../Helpers/Session";
import ReactToast from "../../../componants/Toast/ReactToast";
const WriteReview = (props) => {
    const profile_access = getVariable('profile_access',true);
    const [user_name,setUserName] = useState(profile_access && profile_access.user_name || "")
    const [mobileNo,setMobileNo] = useState(profile_access && profile_access.mobile_no || "");
    const {
        register,
        handleSubmit,
        setError,
        clearErrors,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: {
            mobile: mobileNo
        }
    });
    
    const [loading, setLoading] = useState(false);
    const [rating ,setRating] = useState(0);
    const [comment ,setComment] = useState("");
  
    const changeMobile = (value) => {
        setMobileNo(value);
        if (value) {
            let number = value.trim();
            if (isValidPhoneNumber(number) === true) {
                setValue("mobile", number);
                clearErrors("mobile");
            } else {
                setError("mobile", {
                    type: "invalid",
                    message: "Invalid Phone number.",
                });
            }
        } else {
            setError("mobile", {
                type: "required",
                message: "Phone number is required.",
            });
        } 
    };
  const onSubmit = async (data) => {
    if(rating <= 0) {
        ReactToast({ type: "error", message: "Rating is required." });
        setLoading(false);
        return false
    }
    setLoading(true);
    let parms = {
        user_master_id: props && props.data.user_master_id,
        rating : rating,
        user_name : data.name,
        comment :data.comment,
        mobile_no : data.mobile,
        email : data.email
    };
    try {
        const apiSetting = {
            method: "POST",
            apiParams: parms,
        };
        console.log(apiSetting);
        const apiResult = await apiRequest("add_edit_review", apiSetting);

        if (apiResult.data.settings.success === "1") {
            swal({
                title: "Success",
                text: apiResult.data.settings.message,
                icon: "success",
                button: {
                    text: "Done",
                    closeModal: true,
                },
                dangerMode: false,
            });
            props.closeModal();
        } else {
            swal({
                title: "Error",
                text: apiResult.data.settings.message,
                icon: "warning",
                button: {
                    text: "Close",
                    closeModal: true,
                },
            dangerMode: true,
            });
        }
    } catch (error) {
        console.log("error", error);
    } finally {
        setLoading(false);
    }

  };

  const handleRating = async (val) => {
    setRating(val);
  }
  
  return (
    <div className="write-rating-block">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">

          <div className="form-block">
            <div className="form-row">
            <div className="form-row">
              <label htmlFor="inputName" className="float-start mb-1 d-flex">
                Name <em>*</em>
              </label>
              <div className="form-input position-relative">
                <input
                  type="text"
                  id="inputName"
                  className="form-control"
                  placeholder="Name"
                  {...register("name", {
                    required: "Name is required",
                  })}
                  value={user_name}
                  onChange={(e) => {setUserName(e.target.value)}}
                />
                <div className="input-err">{errors?.name?.message}</div>
              </div>
            </div>
            </div>
            <div className="form-row">
              <label htmlFor="inputEmail" className="float-start mb-1 d-flex">
                Email
              </label>
              <div className="form-input position-relative">
                <input
                  type="text"
                  id="inputEmail"
                  className="form-control"
                  placeholder="Email"
                  {...register("email", {
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Enter a valid Email",
                    },
                  })}
                />
                <div className="input-err">{errors?.email?.message}</div>
              </div>
            </div>
            <div className="form-row">
              <label
                htmlFor="inputPhoneNumber"
                className="float-start mb-1 d-flex"
              >
                Phone Number <em>*</em>
              </label>
              <div className="form-input-div form-input position-relative">
                <div className="input-group">
                  <PhoneInput
                    id="inputPhoneNumber"
                    placeholder="Phone Number"
                    defaultCountry="IN"
                    value={mobileNo}
                    onChange={changeMobile}
                    name="mobile"
                    rules={{
                      ...register("mobile", {
                        required: "Phone Number is required",
                      }),
                    }}
                  />
                </div>
                <div className="input-err">{errors?.mobile?.message}</div>
              </div>
            </div>
            <div className="form-row">
            <label
                className="float-start mb-1 d-flex"
              >
                Rating <em>*</em>
              </label>
              <div className="form-input position-relative ra mt-2">
                    <Rating
                        initialRating={rating}
                        start={0}
                        stop={5}
                        fractions={2}
                        step={1}
                        emptySymbol={<img src={iconStarEmpty}/>} 
                        fullSymbol={<img src={iconStar}/>} 
                        onChange={handleRating}
                    />
              </div>
            </div>
            <div className="form-row">
              <label
                htmlFor="comment"
                className="float-start mb-1 d-flex"
              >
                Comment <em>*</em>
              </label>
              <div className="form-input position-relative">
                <textarea
                  id="comment"
                  className="form-control custom-textarea"
                  name="comment"
                  placeholder="Comment"
                    {...register("comment", {
                    required: "Comment is required",
                  })}
                />
                <div className="input-err">
                  {errors?.comment?.message}
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 profile-action-area">
            <div className="form-row text-center">
              {loading ? <button
                form="contact-person-add-hook-form"
                className="w-20 btn btn-primary m-auto">
                <div className="spinner-border text-white  text-center" role="status">
                  <span className="sr-only">Loading...</span>
                </div> </button> : <button className="btn-primary m-auto" type="submit">
                Write Review
              </button>}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default WriteReview;
