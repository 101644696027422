import React, { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { curRouteDetailsState } from "../../../Helpers/Recoil";
import imgError from "./../../../asset/images/error-404.png";
import { useHistory } from "react-router";
const NotFound = (props) => {
  const setCurRouteDetails = useSetRecoilState(curRouteDetailsState);
  const history = useHistory();
  useEffect(() => {
    setCurRouteDetails({
      title: "404NotFound",
      className: "events-detail-header inner-header",
      menuItem: false,
    });
  });
  return (
    <div className="inner-page error-404-page">
      <div className="container">
        <div className="row">
          <div className="form">
            <div className="error-content-page text-center">
              <img src={imgError} alt="Error 404 page" />
              <h1>oops! page not found</h1>
              <div className="search-action d-inline-flex">
                <button
                  to="submit"
                  className="btn-primary btn-search"
                  onClick={() => history.push("/")}
                >
                  Back to HOME
                </button>
              </div>
            </div>

            {/* <p className="common-paragraph">We have sent you a temporary password <br /> on your registered email address</p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
