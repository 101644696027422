import { Link } from "react-router-dom";
const SignInModal = (props) => {
  return (
    <div className="inner-page signin-modal-page">
      <div className="page-title text-center">
        <div className="modal-txt">
          <h3>
            <span>Kindly Sign In </span>
            or Register to access this functionality
          </h3>
        </div>
        <div className="modal-action">
          <Link to="/login" className="cmn-btn btn-primary">
            Sign In
          </Link>
          <Link to="/pricing" className="cmn-btn btn-secondary">
           Sign Up as a Member
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SignInModal;
