import ProfileNavigation from "./ProfileNavigation";
import { useSetRecoilState } from "recoil";
import { curRouteDetailsState } from "../../../Helpers/Recoil";
import { useEffect, useState } from "react";
import EmployeeCardDetail from "../../../componants/EmployeeCard/EmployeeCardDetail";
import ProfileStatistics from "../../../componants/ProfileStatistics/ProfileStatistics";
import { apiRequest, formatUrlLink } from "../../../Helpers/General";
import moment from "moment";
import EditProfile from "./EditProfile/EditProfile";
import Membership from "./Membership/Membership";
import Publication from "./Publication/Publication";
import Photos from "./Photos/Photos";
import Videos from "./Videos/Videos";
import Bookings from "./Bookings/Bookings";
import JobOpportunity from "./JobOpportunity/JobOpportunity";
import Settings from "./Settings/Settings";
import { directoryMobile } from "../../../Helpers/General";
import Loader from "../../../componants/Loader/Loader";
import NoRecord from "../../../componants/NoRecord/NoRecord";
import ImageZoom from "react-medium-image-zoom";
import { useHistory } from "react-router";
import DocumentMeta from "react-document-meta";
const MyProfile = (props) => {
  directoryMobile();
  let userData = props.userProfile || [];
  let userType = userData.user_type || "";
  let id = props && props.userProfile && props.userProfile.user_master_id;
  let user_country_id = props && props.userProfile && props.userProfile.country_id;
  const history = useHistory();
  const setCurRouteDetails = useSetRecoilState(curRouteDetailsState);
  useEffect(() => {
    setCurRouteDetails(props.componentInfo);
  });
  const [UserDetail, setUserDetail] = useState([]);
  const [isPageUpdating, setIsPageUpdating] = useState(true);
  const [isOnPage, setIsOnPage] = useState("");
  const [collg, setCollg] = useState(4);
  useEffect(() => {
    if (props.userProfile) {
      var width =  window.innerWidth;
      if(width > 800){
      if (isOnPage) {
        window.scrollTo({ top: 50, behavior: "smooth" });
      }else{
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
    } else {
      history.push("/");
    }
    // eslint-disable-next-line
  }, [isOnPage]);
  useEffect(() => {
    const GetData = async () => {
      try {
        const apiSetting = {
          method: "GET",
          apiParams: { country_id: user_country_id, user_id: id },
        };
        const apiResult = await apiRequest("get_user_profile_info", apiSetting);
        if (apiResult.data.settings.success === "1") {
          setUserDetail(apiResult.data.data.return_data[0]);
          if (
            !apiResult.data.data.return_data[0].rank_data &&
            !apiResult.data.data.return_data[0].awards
          ) {
            setCollg(12);
          } else if (
            (!apiResult.data.data.return_data[0].rank_data &&
              apiResult.data.data.return_data[0].awards) ||
            (apiResult.data.data.return_data[0].rank_data &&
              !apiResult.data.data.return_data[0].awards)
          ) {
            setCollg(6);
          }
          setIsPageUpdating(false);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsPageUpdating(false);
      }
    };
    if (isPageUpdating) {
      GetData();
    }
  }, [isPageUpdating, id]);
  const meta = {
    title: UserDetail && UserDetail.meta_title,
    description: UserDetail && UserDetail.meta_description,
    meta: {
      charset: "utf-8",
      name: {
        keywords: UserDetail && UserDetail.meta_key_words,
      },
    },
  };

  if (isPageUpdating) {
    return <Loader />;
  } else {
    return (
      <DocumentMeta {...meta}>
      <div
        className="inner-page myprofile-page after-login-page directory-detail-page"
        id="directoryMobileMenu"
      >
        <div className="container">
          <div className="row row-reverse">
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 order-1 directory-search">
              <ProfileNavigation
                userType={userType}
                setIsOnPage={setIsOnPage}
              />
            </div>
            <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12">
              <div
                className="common-page-content publication-content"
                id="directoryMobile"
              >
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div
                      className={
                        "event-lst-box d-flex justify-content-between without-shadow " +
                        (UserDetail.expertise_ranking ? "" : "no-ranking-badge")
                      }
                    >
                      {isOnPage !== "Edit Profile" && (
                        <EmployeeCardDetail row={UserDetail} {...props} />
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    {isOnPage !== "Edit Profile" &&
                      UserDetail &&
                      UserDetail.pack_data && (
                        <ProfileStatistics data={UserDetail.pack_data} />
                      )}
                  </div>
                </div>

                <div className="tab_container tab-content" id="myTabContent">
                  <div
                    className="tab-pane tab_content viewrofile-tab-content active"
                    id="viewprofileTab"
                    role="tabpanel"
                    aria-labelledby="viewprofile-tab"
                  >
                    <div className="view-profile-page inner-page common-inner-profile-page after-login-page">
                      <div className="tab-inner-content">
                        <h2
                          className="mobile-view"
                          onClick={() => setIsOnPage("Profile")}
                        >
                          Profile
                        </h2>
                        <div className="event-tabs-content mt-2 mobile-ftr-row">
                          <div className="common-block common-block-merge">
                            <div className="common-block-lft">
                              <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                  <div className="">
                                    <div className="common-block-title">
                                      <h3>About</h3>
                                    </div>
                                  
                                    <div className="common-block-content">
                                      <p className="common-paragraph">
                                        {UserDetail.user_about
                                          ? UserDetail.user_about
                                          : "--"}
                                      </p>
                                    </div>                                                                    
                                  </div>
                                </div>
                              </div>
                              <div className="">
                                <div className="common-block-title">
                                  <h3>Educational Qualifications</h3>
                                </div>
                                {UserDetail.education ?
                                  UserDetail.education.length > 0 && (                                  
                                      <div className="common-block-content">
                                        <div className="common-list-block timeline-list-block">
                                          {UserDetail.education ? (
                                            UserDetail.education.map(
                                              (item, key) => (
                                                <div className="common-list" key={key} >
                                                  <span>{item.user_university_name} </span>
                                                  <label>
                                                    {item.user_degree_name + " - " + item.user_from_year + " - " + item.user_to_year}
                                                  </label>
                                                </div>
                                              )
                                            )
                                          ) : (
                                            <NoRecord pageName={"Educational Qualifications"} />
                                          )}
                                        </div>
                                      </div>                                  
                                  ) : (
                                    <NoRecord pageName={"Educational Qualifications"} />
                                  )}
                              </div>
                              {props.userProfile.user_type === "Gc" && (
                                <div className="">
                                  <div className="common-block-title">
                                    <h3>Events attended</h3>
                                  </div>
                                  <div className="common-block-content">
                                    <div className="common-list-block timeline-list-block timeline-without-doticon">
                                      {UserDetail.event_data ? (
                                        UserDetail.event_data.map(
                                          (item, key) => (
                                            <div className="common-list" key={key} >
                                              <label>{moment(item.attened_date).format("ll")} </label>
                                              <span>{item.event_name}</span>
                                              <label>Venue : {item.venue}</label>
                                            </div>
                                          )
                                        )
                                      ) : (
                                        <NoRecord pageName={"Events attended"} />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="common-block-rgt">
                              {UserDetail.expertise_ranking && (
                                <div className="directory-ranking">
                                  <div className="common-block-title">
                                    <h3>Xperts Legal Grading</h3>
                                  </div>
                                  <div className="emp-award d-flex flex-row ">
                                    {
                                      // props.page === "Lowyer" &&
                                      UserDetail.expertise_ranking &&
                                        UserDetail.expertise_ranking.map(
                                          (item, key) => {
                                            let expertieSName =
                                              UserDetail.expertise.filter(
                                                (row) =>
                                                  row.user_expertise_master_id ===
                                                  item.expertise_master_id
                                              );
                                            return (
                                              <div
                                                className={
                                                  " m-2 emp_ranking ranking-color-" +
                                                  item.rank_level
                                                }
                                                key={key}
                                              >
                                                {" "}
                                                <ImageZoom>
                                                  <img
                                                    className="svg"
                                                    src={
                                                      item.rank_image ||
                                                      `${process.env.PUBLIC_URL}/asset/images/no-image.jpg`
                                                    }
                                                    alt=""
                                                  />
                                                  <h4>
                                                    {expertieSName &&
                                                    expertieSName[0]
                                                      .expertise_name
                                                      ? expertieSName[0]
                                                          .expertise_name
                                                      : ""}
                                                  </h4>
                                                </ImageZoom>
                                              </div>
                                            );
                                          }
                                        )
                                    }
                                  </div>
                                </div>
                              )}

                              {props.userProfile.user_type !== "Gc" && (
                                <div className="">
                                  <div className="common-block-title">
                                    <h3>Additional Grading</h3>
                                  </div>
                                  <div className="common-block-content">
                                    <div className="common-list-block timeline-list-block">
                                      {UserDetail.rank_data ? (
                                          UserDetail.rank_data.map(
                                            (item, key) => (
                                              <div className="common-list" key={key} >
                                                <a href={formatUrlLink(item.user_credential_link)} target="_blank" rel="noreferrer" >
                                                  {item.user_rank_title}
                                                </a>
                                                <label>&nbsp;</label>
                                              </div>
                                            )
                                          )
                                        ) : (
                                          <NoRecord pageName={"Additional Grading"} />
                                      )}
                                    </div>
                                    </div>
                                  </div>  
                                )}

                              {props.userProfile.user_type !== "Gc"  && (
                                  <div className="">
                                    <div className="common-block-title">
                                      <h3>Awards</h3>
                                    </div>
                                    <div className="common-block-content">
                                      <div className="common-list-block timeline-list-block">
                                        {UserDetail.awards ? (
                                          UserDetail.awards.map((item, key) => (
                                            <div
                                              className="common-list"
                                              key={key}
                                            >
                                              <span>
                                                {item.user_award_name}
                                              </span>
                                              <label>
                                                {item.user_award_year}
                                              </label>
                                            </div>
                                          ))
                                        ) : (
                                          <NoRecord pageName={"Awards"} />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}

                              {props.userProfile.user_type === "Gc" && (
                                  <div className="">
                                    <div className="common-block-title">
                                      <h3>Employment Information</h3>
                                    </div>
                                    <div className="common-block-content">
                                      <div className="common-list-block timeline-list-block">
                                        {UserDetail.work_org ? (
                                          UserDetail.work_org.map(
                                            (item, key) => (
                                              <div className="common-list" key={key}>
                                                <span> {item.user_org_title} </span>
                                                <label> {item.user_designation} </label>
                                                <label>
                                                  {moment(item.user_from_date).format("MMM YYYY")}
                                                  {item.user_current_org === "Yes" || item.user_to_date === "" ? " - Present"  : " - " + moment(item.user_to_date).format("MMM YYYY")}
                                                </label>                                                
                                              </div>
                                            )
                                          )
                                        ) : (
                                          <NoRecord
                                            pageName={"Employment Information"}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-inner-content d-none">
                        <h2
                          className="mobile-view"
                          onClick={() => setIsOnPage("Profile")}
                        >
                          Profile
                        </h2>
                        <div className="event-tabs-content mt-2 mobile-ftr-row">
                          {UserDetail.user_about && (
                            <div className="row">
                              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="common-block">
                                  <div className="common-block-title">
                                    <h3>About</h3>
                                  </div>
                                  <div className="common-block-content">
                                    <p className="common-paragraph" >
                                      {/* {UserDetail.user_about
                                        ? UserDetail.user_about
                                        : "--"} */}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {UserDetail &&
                            (UserDetail.user_year_of_enrollment ||
                              UserDetail.user_bar_council) && (
                              <div className="row">
                                <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12">
                                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="common-block">
                                      <div className="common-block-title">
                                        <h3>Practice Information</h3>
                                      </div>
                                      <div className="common-block-content">
                                        <div className="common-list-block">
                                          {UserDetail.user_year_of_enrollment && (
                                            <div className="common-list">
                                              <label>Enrolment</label>
                                              <span>
                                                {UserDetail.user_year_of_enrollment
                                                  ? UserDetail.user_year_of_enrollment
                                                  : "--"}
                                              </span>
                                            </div>
                                          )}
                                          {UserDetail.user_bar_council && (
                                            <div className="common-list">
                                              <label>Bar Council</label>
                                              <span>
                                                {UserDetail.user_bar_council
                                                  ? UserDetail.user_bar_council
                                                  : "--"}
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12">
                                  <div className="row">
                                    {/* <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                  <div className="common-block">
                                    <div className="common-block-title">
                                      <h3>Birth Date</h3>
                                    </div>
                                    <div className="common-block-content">
                                      <div className="common-list-block">
                                        <div className="common-list">
                                          <span className="font-large">
                                            {UserDetail.user_birth_date !==
                                              "" &&
                                              UserDetail.user_birth_date !==
                                                "0000-00-00" ?
                                              moment(
                                                UserDetail.user_birth_date
                                              ).format("DD"):"--"}
                                          </span>
                                          <label>
                                            {UserDetail.user_birth_date !==
                                              "" &&
                                              UserDetail.user_birth_date !==
                                                "0000-00-00" &&
                                              moment(
                                                UserDetail.user_birth_date
                                              ).format("MMMM YYYY")}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div> */}
                                    {/* <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                  <div className="common-block">
                                    <div className="common-block-title">
                                      <h3>Experience</h3>
                                    </div>
                                    <div className="common-block-content">
                                      <div className="common-list-block">
                                        <div className="common-list">
                                          <span className="font-large">
                                            {UserDetail.user_experience?UserDetail.user_experience:"--"}
                                          </span>
                                          {UserDetail.user_experience && (
                                            <label>Years</label>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div> */}
                                  </div>
                                </div>
                              </div>
                            )}

                          {UserDetail.education &&
                            UserDetail.education.length > 0 && (
                              <div className="row">
                                {props.userProfile.user_type !== "Gc" ? (
                                  <div
                                    className={
                                      "col-lg-" +
                                      collg +
                                      " col-md-6 col-sm-12 col-xs-12"
                                    }
                                  >
                                    <div className="common-block">
                                      <div className="common-block-title">
                                        <h3>Educational Qualifications</h3>
                                      </div>
                                      <div className="common-block-content">
                                        <div className="common-list-block timeline-list-block">
                                          {UserDetail.education &&
                                            UserDetail.education.map(
                                              (item, key) => (
                                                <div
                                                  className="common-list"
                                                  key={key}
                                                >
                                                  <span>
                                                    {item.user_university_name}
                                                  </span>
                                                  <label>
                                                    {item.user_degree_name +
                                                      " - " +
                                                      item.user_from_year +
                                                      " - " +
                                                      item.user_to_year}
                                                  </label>
                                                </div>
                                              )
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className={
                                      "col-lg-" +
                                      collg +
                                      " col-md-6 col-sm-12 col-xs-12"
                                    }
                                  >
                                    <div className="common-block">
                                      <div className="common-block-title">
                                        <h3>Educational Qualifications</h3>
                                      </div>
                                      <div className="common-block-content">
                                        <div className="common-list-block timeline-list-block">
                                          {UserDetail.education ? (
                                            UserDetail.education.map(
                                              (item, key) => (
                                                <div
                                                  className="common-list"
                                                  key={key}
                                                >
                                                  <span>
                                                    {item.user_university_name}
                                                  </span>
                                                  <label>
                                                    {item.user_degree_name +
                                                      " - " +
                                                      item.user_from_year +
                                                      " - " +
                                                      item.user_to_year}
                                                  </label>
                                                </div>
                                              )
                                            )
                                          ) : (
                                            <NoRecord
                                              pageName={
                                                "Educational Qualifications"
                                              }
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {props.userProfile.user_type !== "Gc" &&
                                  UserDetail.awards && (
                                    <div
                                      className={
                                        "col-lg-" +
                                        collg +
                                        " col-md-6 col-sm-12 col-xs-12"
                                      }
                                    >
                                      <div className="common-block">
                                        <div className="common-block-title">
                                          <h3>Awards</h3>
                                        </div>
                                        <div className="common-block-content">
                                          <div className="common-list-block timeline-list-block">
                                            {UserDetail.awards ? (
                                              UserDetail.awards.map(
                                                (item, key) => (
                                                  <div
                                                    className="common-list"
                                                    key={key}
                                                  >
                                                    <span>
                                                      {item.user_award_name}
                                                    </span>
                                                    <label>
                                                      {item.user_award_year}
                                                    </label>
                                                  </div>
                                                )
                                              )
                                            ) : (
                                              <NoRecord pageName={"Awards"} />
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                {props.userProfile.user_type !== "Gc" &&
                                  UserDetail.rank_data && (
                                    <div
                                      className={
                                        "col-lg-" +
                                        collg +
                                        " col-md-6 col-sm-12 col-xs-12"
                                      }
                                    >
                                      <div className="common-block">
                                        <div className="common-block-title">
                                          <h3>Additional Rankings</h3>
                                        </div>
                                        <div className="common-block-content">
                                          <div className="common-list-block timeline-list-block">
                                            {UserDetail.rank_data ? (
                                              UserDetail.rank_data.map(
                                                (item, key) => (
                                                  <div
                                                    className="common-list"
                                                    key={key}
                                                  >
                                                    <a
                                                     href={formatUrlLink(item.user_credential_link)}
                                                      target="_blank"
                                                      rel="noreferrer"
                                                    >
                                                      {item.user_rank_title}
                                                    </a>
                                                    <label>&nbsp;</label>
                                                  </div>
                                                )
                                              )
                                            ) : (
                                              <NoRecord
                                                pageName={"Additional Rankings"}
                                              />
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                {UserDetail.expertise_ranking && (
                                  <div
                                    className={
                                      "col-lg-12 col-md-12 col-sm-12 col-xs-12 "
                                    }
                                  >
                                    <div className="common-block directory-ranking">
                                      <div className="common-block-title">
                                        <h3>Xperts Legal Grading</h3>
                                      </div>
                                      <div className="emp-award d-flex flex-row ">
                                        {
                                          // props.page === "Lowyer" &&
                                          UserDetail.expertise_ranking &&
                                            UserDetail.expertise_ranking.map(
                                              (item, key) => {
                                                let expertieSName =
                                                  UserDetail.expertise.filter(
                                                    (row) =>
                                                      row.user_expertise_master_id ===
                                                      item.expertise_master_id
                                                  );
                                                return (
                                                  <div
                                                    className={
                                                      " m-2 emp_ranking ranking-color-" +
                                                      item.rank_level
                                                    }
                                                    key={key}
                                                  >
                                                    {" "}
                                                    <ImageZoom>
                                                      <img
                                                        className="svg"
                                                        src={
                                                          item.rank_image ||
                                                          `${process.env.PUBLIC_URL}/asset/images/no-image.jpg`
                                                        }
                                                        alt=""
                                                      />
                                                      <h4>
                                                        {expertieSName &&
                                                        expertieSName[0]
                                                          .expertise_name
                                                          ? expertieSName[0]
                                                              .expertise_name
                                                          : ""}
                                                      </h4>
                                                    </ImageZoom>
                                                  </div>
                                                );
                                              }
                                            )
                                        }
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}

                          {UserDetail.expertise && (
                            <div className="row">
                              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="common-block">
                                  <div className="common-block-title">
                                    <h3>Areas Of Expertise</h3>
                                  </div>
                                  <div className="common-block-content">
                                    <div className="common-badges-list">
                                      {UserDetail.expertise ? (
                                        UserDetail.expertise.map(
                                          (item, key) => (
                                            <div
                                              className="common-gray-badge"
                                              key={key}
                                            >
                                              {item.user_expertise_name}
                                            </div>
                                          )
                                        )
                                      ) : (
                                        <NoRecord
                                          pageName={"Areas Of Expertise"}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {UserDetail.court_data && (
                            <div className="row">
                              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="common-block">
                                  <div className="common-block-title">
                                    <h3>Courts of Practice</h3>
                                  </div>
                                  <div className="common-block-content">
                                    <div className="common-badges-list">
                                      {UserDetail.court_data ? (
                                        UserDetail.court_data.map(
                                          (item, key) => (
                                            <div
                                              className="common-gray-badge"
                                              key={key}
                                            >
                                              {item.user_court_name}
                                            </div>
                                          )
                                        )
                                      ) : (
                                        <NoRecord
                                          pageName={"Courts of Practice"}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {UserDetail.lang_data && (
                            <div className="row">
                              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="common-block">
                                  <div className="common-block-title">
                                    <h3>Languages Known </h3>
                                  </div>
                                  <div className="common-block-content">
                                    <div className="common-badges-list">
                                      {UserDetail.lang_data ? (
                                        UserDetail.lang_data.map(
                                          (item, key) => (
                                            <div
                                              className="common-gray-badge"
                                              key={key}
                                            >
                                              {item.vLanguageName}
                                            </div>
                                          )
                                        )
                                      ) : (
                                        <NoRecord pageName={"Languages Known"} />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {UserDetail.work_org &&
                            props.userProfile.user_type === "Gc" && (
                              <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                  <div className="common-block">
                                    <div className="common-block-title">
                                      <h3>Employment Information</h3>
                                    </div>
                                    <div className="common-block-content">
                                      <div className="common-list-block timeline-list-block">
                                        {UserDetail.work_org ? (
                                          UserDetail.work_org.map(
                                            (item, key) => (
                                              <div className="common-list" key={key}>
                                                <span>{item.user_org_title}</span>
                                                <label>{item.user_designation}</label>
                                                <label> 
                                                  {moment(item.user_from_date).format("MMM YYYY")} 
                                                  {item.user_current_org === "Yes" || item.user_to_date === "" ? " - Present"  : " - " + moment(item.user_to_date).format("MMM YYYY")}
                                                </label>
                                              </div>
                                            )
                                          )
                                        ) : (
                                          <NoRecord pageName={"Employment Information"} />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {UserDetail.event_data && (
                                  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                                    <div className="common-block">
                                      <div className="common-block-title">
                                        <h3>Events Attended</h3>
                                      </div>
                                      <div className="common-block-content">
                                        <div className="common-list-block timeline-list-block timeline-without-doticon">
                                          {UserDetail.event_data ? (
                                            UserDetail.event_data.map(
                                              (item, key) => (
                                                <div className="common-list" key={key} >
                                                  <label>
                                                    {moment(
                                                      item.attened_date
                                                    ).format("ll")}
                                                  </label>
                                                  <span>{item.event_name}</span>
                                                  <label> Venue : {item.venue} </label>
                                                </div>
                                              )
                                            )
                                          ) : (
                                            <NoRecord pageName={"Events Attended"} />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane tab_content editrofile-tab-content "
                    id="editprofileTab"
                    role="tabpanel"
                    aria-labelledby="editprofile-tab"
                  >
                    <div className="tab-inner-content">
                      <h2
                        className="mobile-view"
                        onClick={() => setIsOnPage("Edit Profile")}
                      >
                        Edit Profile
                      </h2>
                      <div className="event-tabs-content mt-2 mobile-ftr-row">
                        {isOnPage === "Edit Profile" && (
                          <EditProfile userProfile={props.userProfile} />
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane tab_content memberships-tab-content"
                    id="membershipTab"
                    role="tabpanel"
                    aria-labelledby="memberships-tab"
                  >
                    {userType !== "Gc" && (
                      <div className="tab-inner-content">
                        <h2
                          className="mobile-view"
                          onClick={() => setIsOnPage("Memberships")}
                        >
                          Memberships
                        </h2>
                        <div className="event-tabs-content mt-2 mobile-ftr-row">
                          {isOnPage === "Memberships" && (
                            <Membership id={id} {...props} />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className="tab-pane tab_content publications-tab-content"
                    id="publicationsTab"
                    role="tabpanel"
                    aria-labelledby="publications-tab"
                  >
                    {userType !== "Gc" && (
                      <div className="tab-inner-content">
                        <h2
                          className="mobile-view"
                          onClick={() => setIsOnPage("Publications")}
                        >
                          Publications
                        </h2>
                        <div className="event-tabs-content mt-2 mobile-ftr-row">
                          {isOnPage === "Publications" && (
                            <Publication id={id} {...props} />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className="tab-pane tab_content photos-tab-content"
                    id="photosTab"
                    role="tabpanel"
                    aria-labelledby="photos-tab"
                  >
                    {userType !== "Gc" && (
                      <div className="tab-inner-content">
                        <h2
                          className="mobile-view"
                          onClick={() => setIsOnPage("Photos")}
                        >
                          Photos
                        </h2>
                        <div className="event-tabs-content mt-2 mobile-ftr-row">
                          {isOnPage === "Photos" && (
                            <Photos id={id} {...props} />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className="tab-pane tab_content videos-tab-content"
                    id="videosTab"
                    role="tabpanel"
                    aria-labelledby="videos-tab"
                  >
                    {userType !== "Gc" && (
                      <div className="tab-inner-content">
                        <h2
                          className="mobile-view"
                          onClick={() => setIsOnPage("Videos")}
                        >
                          Videos
                        </h2>
                        <div className="event-tabs-content mt-2 mobile-ftr-row">
                          {isOnPage === "Videos" && (
                            <Videos id={id} {...props} />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    className="tab-pane tab_content bookings-tab-content"
                    id="bookingsTab"
                    role="tabpanel"
                    aria-labelledby="bookings-tab"
                  >
                    <div className="tab-inner-content">
                      <h2
                        className="mobile-view"
                        onClick={() => setIsOnPage("Bookings")}
                      >
                        Bookings
                      </h2>
                      <div className="event-tabs-content mt-2 mobile-ftr-row">
                        {isOnPage === "Bookings" && <Bookings {...props} />}
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane tab_content jobopp-tab-content"
                    id="joboppTab"
                    role="tabpanel"
                    aria-labelledby="jobopp-tab"
                  >
                    <div className="tab-inner-content">
                      <h2
                        className="mobile-view"
                        onClick={() => setIsOnPage("Job Opportunities")}
                      >
                        Job Opportunities
                      </h2>
                      <div className="event-tabs-content mt-2 mobile-ftr-row">
                        {isOnPage === "Job Opportunities" && (
                          <JobOpportunity id={id} {...props} />
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane tab_content settings-tab-content"
                    id="settingsTab"
                    role="tabpanel"
                    aria-labelledby="settings-tab"
                  >
                    <div className="tab-inner-content">
                      <h2
                        className="mobile-view"
                        onClick={() => setIsOnPage("Settings")}
                      >
                        Settings
                      </h2>
                      <div className="event-tabs-content mt-2 mobile-ftr-row">
                        {isOnPage === "Settings" && <Settings {...props} />}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </DocumentMeta>
    );
  }
};

export default MyProfile;
