import React, { useState, useEffect } from "react";
import Modal from "../../../../componants/Modal/Modal";
import UploadPhotos from "./UploadPhotos";
import { useSetRecoilState } from "recoil";
import { curRouteDetailsState } from "../../../../Helpers/Recoil";
import { apiRequest } from "../../../../Helpers/General";
import DeleteActionBox from "../../../../componants/Modal/DeleteActionBox";
import NoRecord from "../../../../componants/NoRecord/NoRecord";
//import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import Viewer from "react-viewer";
import Loader from "../../../../componants/Loader/Loader";


const Photos = (props) => {
  let id = props.id
    ? props.id
    : props.userProfile && props.userProfile.user_master_id;
  let user_country_id = props && props.userProfile && props.userProfile.country_id;
  const [FilesUrls, setFilesUrls] = useState([]);
  const [doRefresh, setDoRefresh] = useState(false);
  const setCurRouteDetails = useSetRecoilState(curRouteDetailsState);
  useEffect(() => {
    setCurRouteDetails(props.componentInfo);
  });
  const [isPageUpdating, setIsPageUpdating] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [visible, setVisible] = useState(false);
  const [image, setImage] = useState([]);

  useEffect(() => {
    const GetPhotos = async () => {
      try {
        const apiSetting = {
          method: "GET",
          apiParams: { country_id: user_country_id, user_id: props.userProfile ? props.userProfile.user_master_id : 0, file_type: "Photo", posted_by: props.id },
        };
        const apiResult = await apiRequest(
          "get_user_photo_and_video_info",
          apiSetting
        );
        if (apiResult.data.settings.success === "1") {
          let data = apiResult.data.data.return_data
          setFilesUrls(data);
          setIsPageUpdating(false);
          let images = [];
          // eslint-disable-next-line
          data && data.map((item) => {
            images.push({
              src: item.user_file_name,
              title: item.user_gallery_id
            })

          })
          setImage(images)
        }
        else{
          setFilesUrls([])
          setImage([])
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsPageUpdating(false);
      }
    };
    if (isPageUpdating || doRefresh) {
      GetPhotos();
    }
  }, [isPageUpdating, doRefresh, props.userProfile, props.id]);

  const deleteAction = (Gallary_id) => {
    const apiSetting = {
      method: "POST",
      apiParams: {
        country_id: "102",
        user_id: id,
        gallery_id: Gallary_id,
        file_type: "Photo",
      },
    };
    DeleteActionBox(
      "delete_user_photo_video",
      apiSetting,
      doRefresh,
      setDoRefresh
    );
  };
  if(isPageUpdating){
    return <Loader/>
  }else{
  return (
    <div className="common-inner-profile-page inner-page photo-profile-page photos-page after-login-page">
      <div className="row">
        <div className="col-lg-12">
          <div className="inner-head d-flex align-item-center afterlogin-inner-head">
            <div className="inner-head-left">
              <div className="page-title  text-start">
                <h2>Photos</h2>
              </div>
            </div>
            {!props.type && (
              <div className="inner-header-rgt ms-auto d-flex align-items center">
                <div className="emp-card-itm">
                  <Modal
                    btnCoverClass="btn-green custom-btn-link"
                    title="Upload A Photo"
                    btnTitle="Upload New Photo"
                    btnClass="add-icon"
                    id="AddLogoChildForm"
                    child={UploadPhotos}
                    setLineInfo={setFilesUrls}
                    previesData={FilesUrls}
                    setFilesUrls={setFilesUrls}
                    dataId={id}
                    setIsPageUpdating={setIsPageUpdating}
                  // onChange={onChangeProfile}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <i className="add-icon"></i>
      <div className="publication-content">
        <div className="row">
          {FilesUrls.length > 0 ? (
            // eslint-disable-next-line
            FilesUrls.map((item, key) => {
              if (item.user_file_name) {
                return (
                  <div
                    className="col-lg-4 col-md-4 col-sm-6 col-xs-6"
                    key={key}
                  >
                    <div className={"other-photo status_" + (item.user_approval_status ? item.user_approval_status.toLowerCase():"")}>
                      {!props.type && (
                        <button
                          onClick={() => deleteAction(item.user_gallery_id)}
                        >
                          <i className="mdi mdi-delete"></i>
                        </button>
                      )}
                      <div className="img-wrap img-upload" onClick={() => {
                        setVisible(true);
                        setActiveIndex(key);
                      }}>
                        <img
                          htmlFor="photo-upload"
                          className="photo-upload-img"
                          src={item.user_file_name}
                          alt={item.alt_for_gallery ? item.alt_for_gallery : ""}

                        />

                      </div>
                    </div>
                  </div>
                );
              }
            })
          ) : (
            <NoRecord pageName="Photos" />
          )}

        </div>

        <Viewer
          width={"250px"}
          visible={visible}
          onClose={() => {
            setVisible(false);
          }}
          rotatable={false}
          scalable={false}
          images={image}
          activeIndex={activeIndex}
          disableMouseZoom={true}
          noImgDetails={true}
          
        />
      </div>
    </div>
  );
  }
};

export default Photos;
