import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import React, { useEffect,useState,useRef } from "react";
import { useSetRecoilState } from "recoil";
import { curRouteDetailsState } from "../../../Helpers/Recoil";
import { apiRequest } from "../../../Helpers/General";
import { getVariable } from "../../../Helpers/Session";
import swal from "sweetalert";
const ChangePassword = (props) => {
  const setCurRouteDetails = useSetRecoilState(curRouteDetailsState);
  useEffect(() => {
    
    setCurRouteDetails(props.componentInfo);
  })
  const history = useHistory();
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm();
  const userProfile = getVariable("user");
  const [passwordShown, setPasswordShown] = useState(false);
  const [eyeClass, setEyeClass] = useState("mdi mdi-eye-off");
  const [confirmpasswordShown, setconfirmpasswordShown] = useState(false);
  const [eyeClassCpass, seteyeClassCpass] = useState("mdi mdi-eye-off");
  const [currentpasswordshow, setCurrentPasswordShow] = useState(false);
  const [eyecurrentpass, seteyeCurrentPass] = useState("mdi mdi-eye-off");
  const togglePasswordVisiblity = (type) => {
    if (type === "Current") {
      setCurrentPasswordShow(passwordShown ? false : true);
      seteyeCurrentPass(
        passwordShown ? "mdi mdi-eye-off" : "mdi mdi-eye text-danger"
      );
    } else {
      setPasswordShown(passwordShown ? false : true);
      setEyeClass(
        passwordShown ? "mdi mdi-eye-off" : "mdi mdi-eye text-danger"
      );
    }
  };

  const toggleconformPasswordVisiblity = () => {
    setconfirmpasswordShown(confirmpasswordShown ? false : true);
    seteyeClassCpass(
      confirmpasswordShown ? "mdi mdi-eye-off" : "mdi mdi-eye text-danger"
    );
  };
  const onSubmit = async (data) => {
    const apiSetting = {
      method: "POST",
      apiParams: {
        current_password: data.current_password,
        new_password: data.password,
        confirm_new_password: data.confirm_password,
        user_master_id: userProfile && userProfile.user_master_id,
      },
    };

    const apiResult = await apiRequest("user_change_password", apiSetting);
    if (apiResult.data.settings.success === "1") {
      swal({
        title: "Success",
        text: apiResult.data.settings.message,
        icon: "success",
        button: {
          text: "Done",
          closeModal: true,
        },
        dangerMode: false,
      });
      
      history.push("/ThankYou");
    }else{
      swal({
        title: "Error",
        text: apiResult.data.settings.message,
        icon: "warning",
        button: {
          text: "Close",
          closeModal: true,
        },
        dangerMode: true,
      });
    }
  };
  const onchangeConfirmpassword = (e) => {
    let pass = getValues("password");
    if (pass === e.target.value) {
      clearErrors("confirm_password");
    } else {
      setError("confirm_password", {
        type: "invalid",
        message: "Confirm password not match.",
      });
    }
  };
  const password = useRef({});
  password.current = watch("password", "");
  return (
    <div className="inner-page changepasword-page">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 m-auto">
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
              <div className="row mt-5">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="form-row mb-3 width25 mWidth50 custom-dropdown">
                    <label
                      htmlFor="inputCurrentPassword"
                      className="float-start mb-1 d-flex"
                    >
                      Current Password <em>*</em>
                    </label>
                    <div className="form-input-div">
                      <input
                        type={currentpasswordshow ? "text" : "password"}
                        id="inputPassword"
                        className="form-control"
                        placeholder="Current Password"
                        {...register("current_password", {
                          required: "Current Password is required",
                          minLength: {
                            value: 6,
                            message: "Minimum 6 characters",
                          },
                        })}
                      />
                      <i
                        onClick={() => togglePasswordVisiblity("Current")}
                        className={eyecurrentpass + " mdi password-eye"}
                      ></i>
                      <div className="input-err">
                        {/* errors will return when field validation fails  */}
                        {errors?.current_password?.message}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="form-row mb-3 width25 mWidth50 custom-dropdown">
                    <label
                      htmlFor="inputCurrency"
                      className="float-start mb-1 d-flex"
                    >
                      Enter New Password <em>*</em>
                    </label>
                    <div className="form-input-div">
                      <input
                        type={passwordShown ? "text" : "password"}
                        id="inputPassword"
                        className="form-control"
                        placeholder="New Password"
                        {...register("password", {
                          required: "New password required",
                          minLength: {
                            value: 6,
                            message: "Minimum 6 characters",
                          },
                        })}
                      />
                      <i
                        onClick={togglePasswordVisiblity}
                        className={eyeClass + " mdi password-eye"}
                      ></i>
                      <div className="input-err">
                        {/* errors will return when field validation fails  */}
                        {errors?.password?.message}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="form-row mb-3 width25 mWidth50 custom-dropdown">
                    <label
                      htmlFor="inputConfirmpassword"
                      className="float-start mb-1 d-flex"
                    >
                      Confirm Password <em>*</em>
                    </label>
                    <div className="form-input-div">
                      <input
                        type={confirmpasswordShown ? "text" : "password"}
                        id="inputPassword"
                        className="form-control"
                        placeholder="Password"
                        {...register("confirm_password", {
                          required: "Confirm Password is required",
                          validate: value => value === password.current || "Password and Confirm Password not matched."
                        })}
                        onChange={onchangeConfirmpassword}
                      />
                      <i
                        onClick={toggleconformPasswordVisiblity}
                        className={eyeClassCpass + " mdi password-eye"}
                      ></i>
                      <div className="input-err">
                        {/* errors will return when field validation fails  */}
                        {errors?.confirm_password?.message}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="search-action">
              <button
                type="submit"
                className="btn-primary btn-search"
                onClick={handleSubmit(onSubmit)}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChangePassword;
