import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ImageUppload from "../../../../componants/ImageUpload/ImageUpload";
import { apiRequest } from "../../../../Helpers/General";
import swal from "sweetalert";
import ReactToast from "../../../../componants/Toast/ReactToast";
const UploadPhotos = (props) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const id = props.dataId || "";
  const data = props.recordData || null;
  const [FilesUrls, setFilesUrls] = useState();
  const [loading, setLoading] = useState(false);
  const onSubmit = async (data) => {
    setLoading(true);
    try {
      if (FilesUrls) {
        const apiSetting = {
          method: "POST",
          apiParams: { user_id: id, file_type: "Photo" },
          fileInput: { file_name: { 0: FilesUrls } },
        };

        const apiResult = await apiRequest("user_gallery_add", apiSetting);
        setLoading(false);
        if (apiResult.data.settings.success === "1") {
          swal({
            title: "Success",
            text: "Photo Uploaded Successfully.",
            icon: "success",
            button: {
              text: "Done",
              closeModal: true,
            },
            dangerMode: false,
          });
        } else {
          swal({
            title: "Error",
            text: apiResult.data.settings.message,
            icon: "warning",
            button: {
              text: "Close",
              closeModal: true,
            },
            dangerMode: true,
          });
        }
      } else {
        ReactToast({
          type: "error",
          message: "Please select Photo.",
        });
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
    props.setIsPageUpdating(true);
    props.closeModal();
  };
  const onChange = (file, src) => {
    setFilesUrls(file);
  };
  return (
    <div
      className="ReactModal-namechange-content-container ReactModal-Content-Container"
      id="name_change_form"
    >
      <div className="ReactModal-middle-container">
        <form id="documents-add-hook-form" onSubmit={handleSubmit(onSubmit)}>
          <input
            type="hidden"
            id="inputId"
            className="form-control"
            defaultValue={data && id}
            {...register("id")}
          />

          <div className="form-group"></div>
          <div className="form-group">
            <div className="form-row mb-3 ">
              <label
                htmlFor="inputAttachments"
                className="float-start mb-1"
              ></label>
              <div className="input-group">
                {/* <ImageUploadMultiple
                  type="text"
                  name=""
                  id="inputAttachments"
                  className="form-control"
                  placeholder="Image Upload"
                  {...register("imageuploads")}
                  setValue={setValue}
                  setFilesUrls={setFilesUrls}
                  // existingFileArr={existingFiles}
                  //   dataId={id}
                  //   toBeDeleted={toBeDeleted}
                  //   setToBeDeleted={setToBeDeleted}
                /> */}
                <ImageUppload
                  type="text"
                  name="attachments"
                  id="inputAttachments"
                  className="form-control"
                  placeholder="Document Attachments"
                  {...register("attachments")}
                  setValue={setValue}
                  onChange={onChange}
                  dataId={id}
                  suportedFormate="jpg, jpeg, png, bmp"
                  // setImgSrc={setFilesUrls}
                  // toBeDeleted={toBeDeleted}
                  // setToBeDeleted={setToBeDeleted}
                />
              </div>
              <div className="input-err">{errors?.document_name?.message}</div>
            </div>
          </div>
        </form>
      </div>
      <div className="ReactModal-Footer">
        <div className="ReactModal-ActionBlock profile-action-area">
          <div className="btn-grp m-auto">
            {loading ? (
              <button
                form="documents-add-hook-form"
                className="w-20 btn btn-primary m-auto"
                disabled
              >
                <div
                  className="spinner-border text-white  text-center"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </button>
            ) : (
              <button
                form="documents-add-hook-form"
                className="w-20 btn btn-primary m-auto"
                onClick={handleSubmit(onSubmit)}
              >
                <i className="mdi mdi-check"></i> Submit
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default UploadPhotos;
