import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { curRouteDetailsState } from "../../../../Helpers/Recoil";
import { Link } from "react-router-dom";
import iconTime from "./../../../../asset/svg/icon-time.svg";
import iconDate from "./../../../../asset/svg/icon-date.svg";
// import iconArrowLft from "./../../../../asset/svg/icon-arrow-left.svg";
import { useParams } from "react-router-dom";
import { apiRequest, formatDate } from "../../../../Helpers/General";
import moment from "moment";
import Modal from "../../../../componants/Modal/Modal";
import ApplicationForm from "./ApplicationForm";
import NoRecord from "../../../../componants/NoRecord/NoRecord";
import Loader from "../../../../componants/Loader/Loader";
import DocumentMeta from "react-document-meta";
import swal from "sweetalert";
import { getVariable } from "../../../../Helpers/Session";
const EmploymentDetail = (props) => {
  let { id } = useParams();
  let ID = id.split("-").pop();
  const userProfile = getVariable("user");
  const setCurRouteDetails = useSetRecoilState(curRouteDetailsState);
  useEffect(() => {
    setCurRouteDetails(props.componentInfo);
  });
  const [employmentDetail, setEmploymentDetail] = useState([]);
  const [nearEmploymentDetail, setNearEmploymentDetail] = useState([]);
  const [isPageUpdating, setIsPageUpdating] = useState(true);
  const [doRefresh, setDoRefresh] = useState(0);
  useEffect(() => {
    const GetData = async () => {
      try {
        const apiSetting = {
          method: "GET",
          apiParams: {
            job_master_id: ID,
            logged_user_email: (userProfile && userProfile.email) || "",
          },
        };
        const apiResult = await apiRequest("get_job_detail", apiSetting);

        if (apiResult.data.settings.success === "1") {
          if (
            apiResult.data.data.get_job_detail[0].jm_job_status !==
              "Inactive" ||
            apiResult.data.data.get_job_detail[0].job_user_master_id ===
              (props.userProfile.user_master_id || 0)
          ) {
            setEmploymentDetail(apiResult.data.data.get_job_detail[0]);
            setNearEmploymentDetail(
              apiResult.data.data.near_job_data.job_master_data
            );
            setIsPageUpdating(false);
          }
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsPageUpdating(false);
      }
    };
    if (isPageUpdating || id) {
      GetData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPageUpdating, id]);
  const meta = {
    title: employmentDetail.meta_title && employmentDetail.meta_title,
    description:
      employmentDetail.meta_description && employmentDetail.meta_description,
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          employmentDetail.meta_key_words && employmentDetail.meta_key_words,
      },
    },
  };
  if (isPageUpdating) {
    return <Loader />;
  } else if (employmentDetail.length === 0) {
    return (
      <div className="inner-page employment-detail-page directory-detail-page">
        <NoRecord pageName="Employment Found" />
      </div>
    );
  } else {
    return (
      <DocumentMeta {...meta}>
        <div className="inner-page employment-detail-page directory-detail-page">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                <div className="common-page-content publication-content">
                  <div className="row">
                    <div className="card-itm without-shadow">
                      <div className="card">
                        <div className="card-content row">
                          <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                            <div className="card-title">
                              {employmentDetail.job_designation}
                            </div>
                            <p className="common-paragraph font-medium">
                              {employmentDetail.job_organizatin_name}
                            </p>
                            <p className="common-paragraph">
                              {employmentDetail.job_location}{" "}
                              {employmentDetail.job_city_name
                                ? ", " + employmentDetail.job_city_name
                                : ""}
                              {" , "}
                              {employmentDetail.job_state_name}
                              {" , "}
                              {employmentDetail.job_country_name}
                            </p>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            {employmentDetail.job_user_master_id !==
                              (props.userProfile
                                ? props.userProfile.user_master_id
                                : "0") && (
                              <div className="apply-btn ms-auto float-end">
                                {props.userProfile &&
                                employmentDetail.job_application_deadline >=
                                  formatDate("systemDate") &&
                                employmentDetail.jm_job_status !== "Inactive" &&
                                employmentDetail.apply_count === "0" ? (
                                  <Modal
                                    recordData={employmentDetail}
                                    title="Application Form"
                                    id="NameChangeFormModelEdit"
                                    btnTitle="Apply Now"
                                    btnCoverClass="btn-green"
                                    userData={props && props.userProfile}
                                    doRefresh={doRefresh}
                                    setDoRefresh={setDoRefresh}
                                    setIsPageUpdating={setIsPageUpdating}
                                    child={ApplicationForm}
                                  />
                                ) : employmentDetail.apply_count !== "0" ? (
                                  <button type="button" className="btn-green">
                                    <i className={props.btnClass}></i>Already
                                    Applied
                                  </button>
                                ) : employmentDetail.job_application_deadline <
                                    formatDate("systemDate") ||
                                  employmentDetail.jm_job_status ===
                                    "Inactive" ? (
                                  <button type="button" className="btn-green">
                                    <i className={props.btnClass}></i>Closed
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn-green"
                                    onClick={() =>
                                      swal({
                                        title: "info",
                                        text: "Kindly Sign in or Sign up as a member to apply",
                                        icon: "info",
                                        button: {
                                          text: "Close",
                                          closeModal: true,
                                        },
                                        dangerMode: true,
                                      })
                                    }
                                  >
                                    <i className={props.btnClass}></i> Apply Now
                                  </button>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="card-footer justify-content-between d-flex flex-row">
                          <div className="publication-date">
                            <i className="">
                              <img src={iconTime} className="svg" alt="" />
                            </i>
                            Posted On :{" "}
                            {moment(employmentDetail.jjob_posted_date).format(
                              "DD/MM/YYYY"
                            )}
                          </div>
                          <div className="application-date  ms-auto">
                            <i className="">
                              <img
                                src={iconDate}
                                className="svg"
                                alt=""
                                width="12px"
                              />
                            </i>
                            Application Closes :{" "}
                            {moment(
                              employmentDetail.job_application_deadline
                            ).format("DD/MM/YYYY")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="card employment-card">
                      <div className="card-title">
                        <h2>JOB DESCRIPTION</h2>
                      </div>
                      <div className="card-contnet">
                        <p className="common-paragraph">
                          {employmentDetail.job_description}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="card employment-card">
                      <div className="card-title">
                        <h2>qualification</h2>
                      </div>
                      <div className="card-contnet">
                        <ul>
                          <li>
                            {employmentDetail.job_education_qualification}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="card employment-card">
                      <div className="card-title">
                        <h2>EXPERIENCE (IN YEARS)</h2>
                      </div>
                      <div className="card-contnet">
                        <ul>
                          <li>{employmentDetail.job_minimum_experiecnce}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <div className="right-search-block">
                  <div className="right-block-title d-flex flex-column">
                    <h2 className="mb-5">
                      Similar Jobs <span>in your location</span>
                    </h2>
                    <div className="card">
                      <div className="card-content">
                        <ul>
                          {nearEmploymentDetail &&
                          nearEmploymentDetail.length > 0 ? (
                            nearEmploymentDetail.map((item, key) => {
                              // if(item.application_deadline > formatDate("systemDate"))
                              return (
                                <li key={key}>
                                  <div className="card-itm without-shadow">
                                    <div className="card">
                                      <div className="card-content">
                                        <div className="card-title">
                                          {item.designation}
                                        </div>
                                        {/* <p className="common-paragraph font-medium">
                                    {item.location}
                                  </p> */}
                                        {/* <p className="common-paragraph">  {item.job_state}
                                    {", "}
                                    {item.job_country}</p> */}
                                      </div>
                                      <div className="card-footer justify-content-between d-flex flex-row">
                                        <div className="publication-date">
                                          <i className="">
                                            <img
                                              src={iconTime}
                                              className="svg"
                                              alt=""
                                            />
                                          </i>
                                          Application Closes :{" "}
                                          {moment(
                                            item.application_deadline
                                          ).format("DD/MM/YYYY")}
                                        </div>
                                        <div className="card-action">
                                          <Link
                                            to={
                                              "/employment-detail/" +
                                              item.designation
                                                .split(" ")
                                                .join("-")
                                                .toLowerCase() +
                                              "-" +
                                              item.master_id
                                            }
                                            className="btn-border btn-view btn-back"
                                          >
                                            <i className="back-icon"></i>
                                            View Details
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              );
                            })
                          ) : (
                            <NoRecord pageName="Similar Jobs" />
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DocumentMeta>
    );
  }
};

export default EmploymentDetail;
