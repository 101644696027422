import React, { useState } from "react";
import "./ImageUpload.css";
import pdf from '../../../src/asset/icons/pdf.png'
import swal from "sweetalert";
import doc from "../../../src/asset/icons/word.png"

const ImageUpload = React.forwardRef((props, ref) => {
  const [showPreview,setShowPreview] = useState();
  const [imgSrc, setImgSrc] = useState(
    props.imgSrc ||
      `${process.env.PUBLIC_URL}/asset/images/profilePlaceHolder.jpg`
  );
 
  const photoUpload = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    
    const isExtValid = props.type === "Document" ? true : validateFileExt(file);
    if(isExtValid){
    reader.onloadend = () => {
      setImgSrc(reader.result);
    };
    setShowPreview(file);
    reader.readAsDataURL(file);
    props.onChange(file);
    console.log("file",file)
  }else{
    swal({
      title: "Error",
      text: "File format not supported",
      icon: "warning",
      button: {
        text: "Close",
        closeModal: true,
      },
      dangerMode: true,
    });
  }
  };
  const validateFileExt =  (fileObjects) => {
    let result = false;
      const typeArr = fileObjects.type.split("/");
      if (typeArr[0] === "image") result = true;
      if (typeArr[1] === "jpg") result = true;
      else if (typeArr[1] === "jpeg") result = true;
      else if (typeArr[1] === "png") result = true;
      else if (typeArr[1] === "bmp") result = true;
    
    return result;
  };
  let type =showPreview && showPreview.name.split('.').pop();
  return props.name === "profile_pic" || props.name === "user_org_logo"||showPreview ? (
    <label className="label custom-file-upload fas">
      <div className="img-wrap img-upload">
        <img
          htmlFor="photo-upload"
          className="photo-upload-img"
          src={type === "pdf" ? pdf : type === "doc" || type === "docx"   ? doc: imgSrc}
          alt=""
        />
      </div>
      <input
        ref={ref}
        id="photo-upload"
        name={props.name}
        type="file"
        onChange={photoUpload}
      />
    </label>
  ) : (
    <label className="filelabel custom-file-upload fas w-100">

      <i className="attachment-icon"></i>
      <span className="title">Just click for browse</span>
      <span className="title">File format supported :-  ({props.suportedFormate && props.suportedFormate})</span>
      <input
        className="form-control FileUpload1"
        ref={ref}
        id="photo-upload"
        name={props.name}
        type="file"
        onChange={photoUpload}
      />
    </label>
  );
});
export default ImageUpload;
