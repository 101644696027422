import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { curRouteDetailsState } from "../../../Helpers/Recoil";
import { apiRequest } from "../../../Helpers/General";
import swal from "sweetalert";
import Loader from "../../../componants/Loader/Loader";
import { useHistory } from "react-router-dom";
const Forgotpassword = (props) => {
  const setCurRouteDetails = useSetRecoilState(curRouteDetailsState);
  const history = useHistory();
  useEffect(() => {
    if(props.userProfile) {
      history.push("/myprofile");
    }
    setCurRouteDetails(props.componentInfo);
  });
  const [isPageUpdating, setIsPageUpdating] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    setIsPageUpdating(true);
    const apiSetting = { method: "POST", apiParams: { email_id: data.email } };
    const apiResult = await apiRequest("user_forgot_password", apiSetting);
    if (apiResult.data.settings.success === "1") {
      swal({
        title: "Success",
        text: apiResult.data.settings.message,
        icon: "success",
        button: {
          text: "Done",
          closeModal: true,
        },
        dangerMode: false,
      });
      setIsPageUpdating(false);
      // history.push("/ResetPassword");
    } else {
      swal({
        title: "Error",
        text: apiResult.data.settings.message,
        icon: "warning",
        button: {
          text: "Close",
          closeModal: true,
        },
        dangerMode: true,
      });
      setIsPageUpdating(false);
    }
  };
  if (isPageUpdating) {
    return <Loader />
  } else {
    return (
      <div className="inner-page login-page">
        <div className="container">
          <div className="row">
            <div className="login-main-page d-flex alignitems:Center place-content-center h-100">
              <div className="login-form m-auto d-flex align-items-center flex-column">
                <h1 className="d-flex flex-column mb-3 w-100">Forgot Password</h1>
                <form className="form" onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="form-row mb-3 width25 mWidth50 custom-dropdown">
                      <label
                        htmlFor="inputCurrency"
                        className="float-start mb-1 d-flex"
                      >
                        Email Address
                      </label>
                      <div className="form-input position-relative">
                        <input
                          type="email"
                          id="inputEmail"
                          className="form-control"
                          placeholder="Enter Email Address"
                          autoFocus
                          {...register("email", {
                            required: "Email is required",
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              message: "Enter a valid Email",
                            },
                          })}
                        />
                        <div className="input-err"> {errors?.email?.message}</div>

                      </div>
                    </div>
                  </div>
                </form>
                <div className="search-action">
                  <button
                    type="submit"
                    className="btn-primary btn-search"
                    onClick={handleSubmit(onSubmit)}
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
export default Forgotpassword;
