import React, { Fragment, useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
// import { useParams } from "react-router-dom";s
import "./SelectBox.css";
const SelectBox = React.forwardRef((props, ref) => {
  const [isLoading, setIsLoading] = useState(true);
  const [defaultSelected, setDefaultSelected] = useState([]);

  useEffect(() => {
    if (
      props.defaultValue &&
      props.defaultValue !== "" &&
      props.defaultValue !== 0 &&
      props.defaultValue !== "0"
    ) {
      if (props.options && props.options.length > 0 && isLoading) {
        props.setValue && props.setValue(props.name, props.defaultSelected);
        setDefaultSelected(props.defaultSelected);
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [props, isLoading]);

  if (isLoading)
    return (
      <div className="input-group mb-3">
        <input
          disabled
          type="loader"
          className="form-control"
          placeholder={props.placeholder}
        />
        <span className="input-group-text loading-icon" id="basic-addon1">
          <i className="myloader"></i>
        </span>
      </div>
    
    );
  return (
    <Typeahead
      {...props}
      clearButton = { props?.isCancel === false ? false : true }
      defaultSelected={defaultSelected}
      defaultValue={props.defaultValue}
      multiple={props.multiple}
      onSearch={(query) => true}
      isLoading={isLoading}
      id="type_of_business"
      onChange={props.OnChange}
      options={props.options || []}
      labelKey={props.labelKey}
      ref={ref}
      placeholder={props.placeholder}
      positionFixed={true}
      filterBy={
        props.labelKey === "country" || props.labelKey === "state" || props.name === "currency_id_select" || props.name === "package_master_id_select" || props.name == "meeting_view_type" || props.name == "event_view_type"
          ? (option, props) => {
              if (props.selected.length > 0) {
                return true;
              } else if (option && option) {
                if (props.labelKey === "country") {
                    return option.country
                      .toLowerCase()
                      .includes(props.text.toLowerCase());
                }
                if (props.labelKey === "state") {
                    return option.state
                      .toLowerCase()
                      .includes(props.text.toLowerCase());
                }
                if(props.name == "meeting_view_type" || props.name == "event_view_type") {
                    return option.val
                        .toLowerCase()
                        .includes(props.text.toLowerCase());
                } if (props.name == "package_master_id_select") {
                    return option.package_name
                        .toLowerCase()
                        .includes(props.text.toLowerCase());
                }
              } else return true;
            }
          : []
      }      
      renderMenuItemChildren={(option: Option) =>
        props.valueKey === "city_state_id" ? (
          <div className="show_city_state_name">
            {option.city.split(" - ")[0]}
            <div className="small_state_name">
              <small>{option.state}</small>
            </div>
          </div>
        ) : (
          <Fragment>{option[props.labelKey]}</Fragment>
        )
      }
    />
  );
});
export default SelectBox;
