import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import EventCard from "../../../componants/EventCard/EventCard";

const EventTabs = (props) => {
  return (
    <>
      <Splide
        options={{
          type   : 'slide',
          arrows: false,
          gap: "30px",
          width: "100%",
          perPage: 4,
          padding: { right: "15px", left: "15px" },
          breakpoints: {
            1479: {
              perPage: 3,
            },
            980: {
              perPage: 2,
            },
            680: {
              perPage: 1,
            },
          },
        }}
      >
        {props.data.map((row, key) => (
          <SplideSlide key={key}>
            <EventCard row={row} />
          </SplideSlide>
        ))}
      </Splide>
    </>
  );
};
export default EventTabs;
