const Home = () => {
  return <div className="page-wrapper p-5" id="Home">
          <div className="page-header">
            <h1 className="h2 text-center text-uppercase mb-5 mt-3">Home Page21331</h1>        
          </div> 
          <div className="page-content text-center">
            <p className="lead"><img src={`${process.env.PUBLIC_URL}/asset/images/coming-soon.svg`} alt="Comming Soon" /></p>
          </div> 
        </div>;
}
export default Home;