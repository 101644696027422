import React, { useState } from "react";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ReactToast from "../../../componants/Toast/ReactToast";
const ContactPerson = (props) => {
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm();
  const [formYearValue, setFormYearValue] = useState();
  const [toYearValue, setToYearValue] = useState();
  const onSubmit = async (data) => {
    let formData = props.previesData;
    let row_id = (formData.length + 1) * -1;

    if(formYearValue>toYearValue){
      ReactToast({ type: "error", message: "To date Should Not Less Than From Date" });
      return false;
    }
    formData.push({
      Education_id: row_id,
      univercity_name: data.univercity_name.trim(),
      degree_name: data.degree_name,
      from:formYearValue,
      to:toYearValue,
      user_education_id: row_id,
      user_university_name: data.univercity_name.trim(),
      user_degree_name: data.degree_name,
      user_from_year:formYearValue,
      user_to_year: toYearValue,
    });

    props.setLineInfo(formData);
    props.setDoRefresh(Math.random());
    props.closeModal();
  };
  const onToYearChange = (e) => {
    let date = moment(e).format("YYYY")
    setToYearValue(date)
    setValue("to", date);
    clearErrors("to");
  }
  const onFormYearChange = (e) => {
    let date = moment(e).format("YYYY")
    setFormYearValue(date);
    setValue("from", date);
    clearErrors("from");

  }
  return (
    <div
      className="ReactModal-namechange-content-container ReactModal-Content-Container"
      id="name_change_form"
    >
      <div className="ReactModal-middle-container">
        <form
          id="contact-person-add-hook-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <input
            type="hidden"
            id="inputId"
            className="form-control"
            {...register("id")}
          />
          {/* <div className="form-group">
            <div className="profile-content width75">  */}
          <div className="form-group">
            <div className="form-row mb-3 ">
              <label htmlFor="inputUnivercityName" className="float-start mb-1">
                School/University Name <em>*</em>
              </label>
              <div className="input-group">


                <input
                  type="text"
                  id="inputUnivercityName"
                  className="form-control"
                  placeholder="University Name"
                  {...register("univercity_name", {
                    required: "University Name is required ",
                  })}
                />
              </div>
              <div className="input-err">
                {errors?.univercity_name?.message}
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="form-row mb-3 ">
              <label htmlFor="inputDegreeName" className="float-start mb-1">
                Degree Name <em>*</em>
              </label>
              <div className="input-group">
                <input
                  type="text"
                  id="inputDegreeName"
                  className="form-control"
                  placeholder="Degree Name"
                  {...register("degree_name",{required:"Degree Name is required"})}
                />
              </div>
              <div className="input-err">{errors?.degree_name?.message}</div>
            </div>
          </div>
          <div className="form-group">
            <div className="form-row mb-3 ">
              <label htmlFor="inputFrom" className="float-start mb-1">
                From(Year)<em>*</em>
              </label>
              <div className="input-group">
                <DatePicker
                  className="form-control"
                  value={formYearValue}
                  dateFormat="yyyy"
                  maxDate={new Date()}
                  showYearPicker
                  onChange={onFormYearChange}
                  placeholderText="From Year"
                                  rules={{...register("from", {
                  required: "From Year is required"})}}
                />
              </div>
              <div className="input-err">{errors?.from?.message}</div>
            </div>
          </div>
          <div className="form-group">
            <div className="form-row mb-3 ">
              <label htmlFor="inputTo" className="float-start mb-1">
                To(Year)<em>*</em>
              </label>
              <div className="input-group">

                <DatePicker
                  className="form-control"
                  value={toYearValue}
                  dateFormat="yyyy"
                  maxDate={new Date("2025-12-31")}
                  showYearPicker
                  onChange={onToYearChange}
                  placeholderText="To Year"
                  rules={{...register("to", {
                    required: "To Year is required"})}}
                />
              </div>
              <div className="input-err">{errors?.to?.message}</div>
            </div>
          </div>
        </form>
      </div>
      <div className="ReactModal-Footer">
        <div className="ReactModal-ActionBlock profile-action-area">
          <div className="btn-grp m-auto">
            <button
              form="contact-person-add-hook-form"
              className="w-20 btn btn-primary m-auto"
              onClick={handleSubmit(onSubmit)}
            >
              <i className="mdi mdi-check"></i> Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPerson;
