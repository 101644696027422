
import { useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { curRouteDetailsState } from "../../../../Helpers/Recoil";
import { useEffect, useContext } from "react";
import { apiRequest } from "../../../../Helpers/General";
import SelectBox from "../../../../componants/SelectBox/SelectBox";
import { MasterContext } from "../../../../Context";
import { useState } from "react";
import moment from "moment";
import Linetable from "../../../../componants/Table/LineTable";
import ReferencesDetailForm from "./ReferencesDetailForm";
import swal from "sweetalert";
const AddPublication = (props) => {
  const { dataid } = props
  let user_id = props.id
    ? props.id
    : props.userProfile && props.userProfile.user_master_id;
  const setCurRouteDetails = useSetRecoilState(curRouteDetailsState);
  const [selectedCategory, setSelectedCategory] = useState();
  // eslint-disable-next-line
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const { categorymasterdata } = useContext(MasterContext);
  const [referencesDetail, setReferencesDetail] = useState([]);
  const [publicationDetail, setPublicationDetail] = useState([]);
  const lineTable_lables_References_Detail = [
    "References Title",
    "References URL",
    "Action"
  ];
  const {
    register,
    handleSubmit, setValue,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setCurRouteDetails(props.componentInfo);
  });
  useEffect(() => {
    const GetData = async () => {
      try {
        const apiSetting = {
          method: "GET",
          apiParams: { publication_master_id: dataid, logged_id : user_id },
        };
        const apiResult = await apiRequest("get_publication_master_detail", apiSetting);
        if (apiResult.data.settings.success === "1") {
          
          setPublicationDetail(apiResult.data.data.publication_list[0])
          console.log("categorydata", categorymasterdata)
          console.log("categor_ydata", apiResult.data.data.publication_list[0].category_data)
          setValue("publication_title", apiResult.data.data.publication_list[0].master_data.publicaton_title);
          setValue("about", apiResult.data.data.publication_list[0].master_data.publicaton_about);
          if (apiResult.data.data.publication_list[0].ref_data) {

            setReferencesDetail(apiResult.data.data.publication_list[0].ref_data)

          }
          let catiddata = apiResult.data.data.publication_list[0].category_data
          let catdata = [];
          catiddata && catiddata.map((item) => catdata.push({ cat_id: item.category_master_id }));
          setSelectedCategory(catdata);
          setValue(
            "categories",
            apiResult.data.data.publication_list[0].category_data,
            shouldValidate
          );
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        // setIsPageUpdating(false);
      }
    };
    if (dataid) {
      GetData();
    }
    // eslint-disable-next-line
  }, [dataid]);
  const onSubmit = async (data) => {
    if(data.about) {
      let about_Arr =  data.about.split(' ');
      if(about_Arr.length < 500) {
        setError('about',{ type : 'error', message : 'Minimum 500 words required'});
        return false;
      }
    }
    setLoading(true);
    let parms = {
      user_id: user_id,
      publication_master_id: dataid ? dataid : 0,
      publicaton_title: data.publication_title,
      description: data.about,
      publication_date: moment().format("YYYY-MM-DD"),
      category_ids:
        selectedCategory &&
        selectedCategory.map((val) => val.cat_id).toString(),
      ref_info: referencesDetail,
      mode: dataid ? "edit" : "add",
      country_id: "102",
    };
    try {
      const apiSetting = { method: "POST", apiParams: parms };
      const apiResult = await apiRequest(
        "add_and_edit_publication",
        apiSetting
      );
      if (apiResult.data.settings.success === "1") {
        // setIsOpen(true);
        swal({
          title: "Success",
          text: apiResult.data.settings.message,
          icon: "success",
          buttons: "OK",
          dangerMode: false,
        }).then((shouldCreateNew) => {

        });
        props.closeModal()
      } else {
        swal({
          title: "Error",
          text: apiResult.data.settings.message,
          icon: "warning",
          button: {
            text: "Close",
            closeModal: true,
          },
          dangerMode: true,
        });
        props.closeModal()
        throw new Error(apiResult.data.settings.message);
      }
    } catch (error) {
      console.log("error", error);
    }finally{
      setLoading(false);
    }

  };
  const shouldValidate = { shouldValidate: true };
  const changeCategory = (e) => {
    setValue("categories", e)
    clearErrors("categories")
    let catdata = [];
    e && e.map((item) => catdata.push({ cat_id: item.category_master_id }));
    setSelectedCategory(catdata);
    // setValue(
    //   "categories",
    //   e,
    //   shouldValidate
    // );
  };
  return (
    <div className="inner-page add-publications after-login-page">
      <div className="container">
        <div className="row">
          <form
            id="documents-add-hook-form-AddPublication"
            onSubmit={handleSubmit(onSubmit)}
          >
            <input
              type="hidden"
              id="inputId"
              className="form-control"
              {...register("id")}
            />
            <div className="form-section">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="form-row mb-4">
                    <label htmlFor="username" className="float-start mb-1">
                      Publication Title <em>*</em>
                    </label>
                    <div className="form-input-div">
                      <input
                        type="text"
                        id="username"
                        className="form-control"
                        placeholder="Add a Title"
                        {...register("publication_title", { required: "Publication Title is required" })}
                        autoFocus
                      />
                      <div className="input-err">{errors?.publication_title?.message}</div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="form-row mb-4">
                    <label htmlFor="username" className="float-start mb-1">
                      Publication Categories <em>*</em>
                    </label>
                 {categorymasterdata && (!dataid || publicationDetail.master_data)  &&
                  <div className="form-input position-relative">
                      <SelectBox
                        multiple
                        className="mdi mdi-chevron-down"
                        name="categories"
                        labelKey="category_name"
                        valueKey="category_master_id"
                        options={categorymasterdata}
                        OnChange={changeCategory}
                        {...register("categories", {
                          required: "Publication Categories is required"
                        })}
                        setValue={setValue}
                        // defaultValue={[categorymasterdata[0],categorymasterdata[1]]}
                        // defaultSelected={[categorymasterdata[0],categorymasterdata[1]]}
                        defaultValue={publicationDetail.master_data && publicationDetail.master_data.category_ids}
                        defaultSelected={
                          categorymasterdata &&
                          categorymasterdata.filter(function (row) {
                            return (
                              publicationDetail.master_data && publicationDetail.master_data.category_ids &&
                              publicationDetail.master_data.category_ids
                                .split(",")
                                .includes(row.category_master_id)
                            );
                          })
                        }
                      />
                      <div className="input-err">{errors?.categories?.message}</div>
                    </div>}
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="form-row mb-4">
                    <label htmlFor="username" className="float-start mb-1">
                      About <em>*</em>
                    </label>
                    <div className="form-input-div">
                      <textarea
                        id="username"
                        className="form-control form-textarea"
                        placeholder="Description (min 500 words)"
                        {...register("about", { required: "About is required",
                        minLength:{
                          value:500,
                          message:"Minimum 500 words required"
                        }
                       })}
                        autoFocus
                      />
                      <div className="input-err">{errors?.about?.message}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-6 col-sm-12 col-xs-12">
                  <div className="inner-title">
                    <h3>References</h3>
                  </div>
                  <div className="additional-form-info">
                    <Linetable
                      childComponant={ReferencesDetailForm}
                      addBtn="true"
                      setLineInfo={setReferencesDetail}
                      setDoRefresh={setIsPageLoading}
                      lineInfo={referencesDetail}
                      labels={lineTable_lables_References_Detail}
                      moduleCode="publication"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="profile-action-area">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="form-row">
                  {loading ?<button
                            form="contact-person-add-hook-form"
                            className="w-20 btn btn-primary m-auto"> 
                            <div className="spinner-border text-white  text-center" role="status">
                            <span className="sr-only">Loading...</span>
                        </div> </button>:   <button
                      className="btn-primary"
                      form="documents-add-hook-form-AddPublication"
                      type="submit"
                    >
                      Submit
                    </button>}
                  </div>
                </div>
              </div>
            </div>{" "}
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddPublication;
