import { useSetRecoilState } from "recoil";
import { curRouteDetailsState } from "../../../../Helpers/Recoil";
import React, { useEffect, useState } from "react";
import { apiRequest } from "../../../../Helpers/General";
import DocumentMeta from "react-document-meta";

const PrivacyPolicy = (props) => {
  const setCurRouteDetails = useSetRecoilState(curRouteDetailsState);
  useEffect(() => {
    setCurRouteDetails(props.componentInfo);
  });
  const [isPageUpdating, setIsPageUpdating] = useState(true);
  const [aboutUsData, setAboutUsData] = useState([]);

  useEffect(() => {
    const GetData = async () => {
      try {
        const apiSetting = {
          method: "GET",
          apiParams: { page_code: "privacypolicy" },
        };
        const apiResult = await apiRequest(
          "get_static_page",
          apiSetting
        );
        if (apiResult.data.settings.success === "1") {
          setAboutUsData(apiResult.data.data[0]);
          setIsPageUpdating(false);
        } else {
          setAboutUsData([]);
          setIsPageUpdating(false);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsPageUpdating(false);
      }
    };
    if (isPageUpdating) {
      GetData();
    }
  }, [isPageUpdating]);

  const meta = {
    title: aboutUsData && aboutUsData.static_page_meta_title,
    description: aboutUsData && aboutUsData.static_page_meta_desc,
    canonical: aboutUsData && aboutUsData.static_page_url,
    meta: {
      charset: "utf-8",
      name: {
        keywords: aboutUsData && aboutUsData.static_page_meta_keyword,
      },
    },
  };

  return (
    <DocumentMeta {...meta}>
      <div className={("inner-page privacy-policy-page common-content-page " + aboutUsData.static_page_code)} >
        <div className="container" >
          <div className="row" >
            <div dangerouslySetInnerHTML={{ __html: aboutUsData.static_page_content }}>
            </div>
          </div>
        </div>
      </div>
    </DocumentMeta>
  );
};

export default PrivacyPolicy;
