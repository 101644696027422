import React, { useEffect, useCallback, useRef, useState } from "react";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import ReactModal from 'react-modal';
import btnClose from './../../asset/svg/btn-close.png'
var filename = ""
var subtitle;
const CropImageUpload = React.forwardRef((props, ref) => {
    const [imgSrc, setImgSrc] = useState(
        props.imgSrc ||
        `${process.env.PUBLIC_URL}/asset/images/profilePlaceHolder.jpg`
    );
    // Image Upload
    const [upImg, setUpImg] = useState();
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 16 / 16 });
    const [completedCrop, setCompletedCrop] = useState(null);
    const [modalIsOpen, setIsOpen] = useState(false);
    const onSelectFile = (e) => {
        setIsOpen(true)
        if (e.target.files && e.target.files.length > 0) {
            filename = e.target.files[0].name
            const reader = new FileReader();
            reader.addEventListener('load', () => setUpImg(reader.result));
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;
        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );
    }, [completedCrop]);

    function generateDownload(canvas, crop) {
        if (!crop || !canvas) {
            return;
        }
        canvas.toBlob(
            (blob) => {
                const previewUrl = window.URL.createObjectURL(blob);
                setImgSrc(previewUrl)
                var file = new File([blob], (filename || "profilePick.png"), {
                    type: "image/png",
                });
                props.setProfilePic(file);
                setIsOpen(false);
              
            },
            'image/png',
            1
        );
    }
    function closeModal() {
        setIsOpen(false);
        
    }
    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '';
    }
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)'
        }
    };
    // End Image Upload
    return (
        <div className="App">
            <div className="custom-file-upload">
            <label className="label custom-file-upload fas">
                <div className="img-wrap img-upload">
                    <img htmlFor="crop-photo-upload" className="photo-upload-img" src={imgSrc || props.imgSrc} alt="" />
                </div>
                <input
                    type="file"
                    id="crop-photo-upload"
                    accept="image/*"
                    onChange={(event)=>onSelectFile(event)}
                    onClick={(event)=> { 
                        event.target.value = null
                   }}
                    // className="form-control FileUpload1"
                    name="upload"
                />
                </label>
            </div>
            <ReactModal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                //   contentLabel="Create Table"
                shouldCloseOnOverlayClick={false}
                shouldCloseOnEsc={false}
            >
                <div className="page-wrapper" id={props.id} >
                    <div className="page-row">
                        <header className="d-flex align-items-center ReactModal-Header">
                            <div className="d-flex align-items-center text-dark text-decoration-none w-100">
                                <h1 ref={_subtitle => (subtitle = _subtitle)}>{props.title}</h1>
                                <button className="Reactmodal-CloseBtn" onClick={closeModal}><img src={btnClose} alt="" /></button>
                            </div>
                        </header>
                        <div className="crop-container">
                            <ReactCrop
                                src={upImg}
                                onImageLoaded={onLoad}
                                crop={crop}
                                onChange={(c) => setCrop(c)}
                                onComplete={(c) => setCompletedCrop(c)}
                            />
                            <button
                            className="btn-primary m-auto"
                                type="button"
                                disabled={!completedCrop?.width || !completedCrop?.height}
                                onClick={() => {
                                    generateDownload(previewCanvasRef.current, completedCrop)
                                }
                                }
                            >
                                Save
                            </button>                        
                            <div className="crop-preview">
                                <canvas
                                    ref={previewCanvasRef}
                                    // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                                    style={{
                                        width: Math.round(completedCrop?.width ?? 0),
                                        height: Math.round(completedCrop?.height ?? 0)
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </ReactModal>
        </div>
    );
});
export default CropImageUpload;
