import moment from "moment";
import React, { useState } from "react";
import DatePicker from "react-date-picker";
import { useForm } from "react-hook-form";
import ReactToast from "../../../componants/Toast/ReactToast";
const OrganisationHistory = (props) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [fromdate, setFromDate] = useState();
  const [todate, setToDate] = useState();
  const onFromDatechange = (value) => {
    if(value){
      let datevalue = moment(value).format("YYYY-MM-DD");
      setValue("from_date", datevalue);
      }
      else{
        setValue("from_date", value); 
      }
    setFromDate(value);
  };
  const onToDatechange = (value) => {
    if(value){
    let datevalue = moment(value).format("YYYY-MM-DD");
    setValue("to_date", datevalue);
    }
    else{
      setValue("to_date", value); 
    }
    setToDate(value);
  };

  const onSubmit = async (data) => {
    let formData = props.previesData
    if (data.is_current_org === 'Yes') {
      for (var i in formData) {
        formData[i].user_current_org = "No";
      }
    } else {
      formData = props.previesData;
    }
    if(data.from_date > data.to_date ){
      ReactToast({
        type:"error",
        message:"From date should be less than To Date"
      })
      return false
    }
    let row_id = (formData.length + 1) * -1;
    formData.push({
      user_work_organisation_id: row_id,
      user_org_title: data.organisation_title.trim(),
      user_designation: data.designation_name.trim(),
      user_current_org: data.is_current_org,
      user_from_date: data.from_date,
      user_to_date: data.to_date,
    });

    props.setLineInfo(formData);
    if (data.is_current_org === 'Yes') props.onOrgNameChange(data.organisation_title.trim());
    props.setDoRefresh(Math.random());
    props.closeModal();
  };

  return (
    <div
      className="ReactModal-namechange-content-container ReactModal-Content-Container"
      id="name_change_form"
    >
      <div className="ReactModal-middle-container">
        <form
          id="contact-person-add-hook-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <input
            type="hidden"
            id="inputId"
            className="form-control"
            {...register("id")}
          />
          {/* <div className="form-group">
            <div className="profile-content width75">  */}
          <div className="form-group">
            <div className="form-row mb-3 ">
              <label htmlFor="inputDesignation" className="float-start mb-1">
                Designation <em>*</em>
              </label>
              <div className="input-group">
                <input
                  type="text"
                  id="inputDesignation"
                  className="form-control"
                  placeholder="Designation"
                  {...register("designation_name", {
                    required: "Designation Name required ",
                  })}
                />
              </div>
              <div className="input-err">
                {errors?.designation_name?.message}
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="form-row mb-3 ">
              <label htmlFor="inputOrganisation" className="float-start mb-1">
                Organisation Title
              </label>
              <div className="input-group">
                <input
                  type="text"
                  id="inputOrganisation"
                  className="form-control"
                  placeholder="Organisation Title"
                  {...register("organisation_title", {
                    required: "Organisation Title required ",
                  })}
                />
              </div>
              <div className="input-err">
                {errors?.organisation_title?.message}
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="form-row mb-3 custom-date">
              <label htmlFor="inputFrom" className="float-start mb-1">
                From(Year)<em>*</em>
              </label>
              <div className="input-group">
                <DatePicker
                  format="dd/MM/y"
                  onChange={onFromDatechange}
                  name="fromdate"
                  value={fromdate}
                  rules={{
                    ...register("from_date", {
                      required: "required.",
                    })
                  }}
                />
                {/* <input
                  type="date"
                  id="start"
                  name="trip-start"
                  {...register("from_date", {
                    required: "required.",
                  })}
                /> */}
              </div>
              <div className="input-err">{errors?.from_date?.message}</div>
            </div>
          </div>
          <div className="form-group">
            <div className="form-row mb-3 custom-date">
              <label htmlFor="inputTo" className="float-start mb-1">
                To Date<em>*</em>
              </label>
              <div className="input-group">
                <DatePicker
                  format="dd/MM/y"
                  onChange={onToDatechange}
                  name="todate"
                  minDate={fromdate}
                  value={todate}
                  rules={{
                    ...register("to_date", {
                      required: "required.",
                    })
                  }}
                />
                {/* <input
                  type="date"
                  id="end"
                  name="trip-start"
                  {...register("to_date", {
                    required: "required.",
                  })}
                /> */}
              </div>
              <div className="input-err">{errors?.to_date?.message}</div>
            </div>
          </div>
          <div className="form-group">
            <div className="form-row mb-3 ">
              <label htmlFor="inputTo" className="float-start mb-1">
                Is It Current Organisation?<em>*</em>
              </label>
              <div className="form-input position-relative custom-radio">
                <div className="input-group">
                  <input
                    {...register("is_current_org", { required: true })}
                    type="radio"
                    value="Yes"
                  />{" "}
                  Yes
                </div>
                <div className="input-group">
                  <input
                    {...register("is_current_org", { required: true })}
                    type="radio"
                    value="No"
                    defaultChecked
                  />{" "}
                  No
                </div>
              </div>
              <div className="input-err">{errors?.is_current_org?.message}</div>
            </div>
          </div>
        </form>
      </div>
      <div className="ReactModal-Footer">
        <div className="ReactModal-ActionBlock profile-action-area">
          <div className="btn-grp m-auto">
            <button
              form="contact-person-add-hook-form"
              className="w-20 btn btn-primary m-auto"
              onClick={handleSubmit(onSubmit)}
            >
              <i className="mdi mdi-check"></i> Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganisationHistory;
