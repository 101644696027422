import { NavLink } from "react-router-dom";
import ftrArrow from "./../../../asset/svg/icon-ftr-arrow.svg";
import ftrLogo from "./../../../asset/svg/logo.svg";
import { formatUrlLink } from "../../../Helpers/General";
import { MasterContext } from "../../../Context";
import { useContext } from "react";
const Footer = (props) => {
  const footerClickFunction = (event, id) => {
    var h2s = document.querySelectorAll("#" + id + " h2");
    if (event.target && event.target.classList.contains("active")) {
      event.target.classList.remove("active");
    } else {
      for (var i = 0; i < h2s.length; i++) {
        h2s[i].classList.remove("active");
      }
      if (event.target && event.target.nodeName === "H2") {
        event.target.classList.add("active");
      }
    }
  };
  const { genralMasterData } = useContext(MasterContext);
  let footerLabel = genralMasterData;
  return (
    <footer id="footer" className="footer">
      <div className="container">
        <div className="footer-links pt-5">
          <div
            className="row"
            id="mobileMenu"
            onClick={(event) => footerClickFunction(event, "mobileMenu")}
          >
            <div className="col-lg-4  col-md-12 col-sm-12 col-xs-12 ">
              <div className="ftr-link-lst footer-about d-flex flex-column align-items-start ">
                <h3>
                  <img src={ftrLogo} className="svg" alt="" width="95" />
                </h3>
                <div className="ftr-desc">
                  {footerLabel && footerLabel.footer_text ? (
                    <p>{footerLabel.footer_text}</p>
                  ) : (
                    <p className="common-paragraph">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Donec non ex aliquet, dapibus tellus eu, auctor libero.
                      Aliquam a nisi eu leo volutpat gravida et eu est.
                    </p>
                  )}
                </div>
                <div className="ftr-communication">
                  <ul>
                    {footerLabel &&
                      footerLabel.company_email !== undefined &&
                      footerLabel.company_email !== "" && (
                        <li>
                          <strong>Email Us:</strong>
                          <a href={"mailto:" + footerLabel.company_email}>
                            {footerLabel.company_email}
                          </a>
                        </li>
                      )}
                    {footerLabel &&
                      footerLabel.company_phone !== undefined &&
                      footerLabel.company_phone !== "" && (
                        <li>
                          <strong>Call Us:</strong>
                          <a
                            href={
                              "tel:" + footerLabel.company_phone.split(",")[0]
                            }
                          >
                            {footerLabel.company_phone}
                          </a>
                        </li>
                      )}
                    {footerLabel &&
                      footerLabel.preferred_time !== undefined &&
                      footerLabel.preferred_time !== "" && (
                        <li>
                          <strong>Prefered Time To Call:</strong>
                          <strong>{footerLabel.preferred_time}</strong>
                        </li>
                      )}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-6 col-xs-6 mobile-ftr-menu">
              <div className="ftr-link-lst quick-links">
                <h2 className="active">Company</h2>
                <div className="row mobile-ftr-row">
                  <div className="col-lg-12">
                    <ul className="d-flex flex-column">
                      <li>
                        <NavLink exact to="/About">
                          <img src={ftrArrow} className="svg" alt="" />
                          About Us
                        </NavLink>
                      </li>
                      <li>
                        <a href="https://xpertslegal.com/blog/">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6"
                            height="10"
                            viewBox="0 0 6 10"
                            fill="none"
                            id="null"
                            className="svg replaced-svg"
                          >
                            <path
                              d="M0 8.7167L3.7085 5.0001L0 1.2835L1.1417 0.141799L6 5.0001L1.1417 9.8584L0 8.7167Z"
                              fill="#1678FB"
                            ></path>
                          </svg>
                          Our Blog
                        </a>
                      </li>
                      <li>
                        <NavLink to="/ContactUs">
                          <img src={ftrArrow} className="svg" alt="" />
                          Contact Us
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/PrivacyPolicy">
                          <img src={ftrArrow} className="svg" alt="" />
                          Privacy Policy
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/TermsCondition">
                          <img src={ftrArrow} className="svg" alt="" />
                          Terms of Service
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/Faqs">
                          <img src={ftrArrow} className="svg" alt="" />
                          FAQ’s
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/sitemap">
                          <img src={ftrArrow} className="svg" alt="" />
                          Sitemap
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-9 col-sm-6 col-xs-6 mobile-ftr-menu">
              <div className="ftr-link-lst quick-links">
                <h2>Quick Links</h2>
                <div className="row mobile-ftr-row">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <ul className="d-flex flex-column flex-wrap">
                      {props && props.userProfile ? (
                        ""
                      ) : (
                        <li>
                          <NavLink to="/Pricing">
                            <img src={ftrArrow} className="svg" alt="" />
                            Pricing
                          </NavLink>
                        </li>
                      )}

                      <li>
                        <NavLink to="/networking-details">
                          <img src={ftrArrow} className="svg" alt="" />
                          Upcoming Networking meeting
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/Events/Webinar">
                          <img src={ftrArrow} className="svg" alt="" />
                          Upcoming Webinars
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/Events/Conferences">
                          <img src={ftrArrow} className="svg" alt="" />
                          Upcoming Conferences
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/Events/Skill">
                          <img src={ftrArrow} className="svg" alt="" />
                          Upcoming Skill Development Programs
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <ul className="d-flex flex-column flex-wrap">
                      <li>
                        <NavLink to="/Ranking">
                          <img src={ftrArrow} className="svg" alt="" />
                          Grading
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/lawyers-directory">
                          <img src={ftrArrow} className="svg" alt="" />
                          Lawyer Directory
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/general-counsel">
                          <img src={ftrArrow} className="svg" alt="" />
                          General Counsel Directory
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/Publications">
                          <img src={ftrArrow} className="svg" alt="" />
                          Publications
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/Employment">
                          <img src={ftrArrow} className="svg" alt="" />
                          Employment
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row ftr-bottom">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="copyright">
                {footerLabel && footerLabel.copyright_txt ? (
                  <p>{footerLabel.copyright_txt}</p>
                ) : (
                  <p>© Copyright 2021 Xperts Legal. All Rights Reserved.</p>
                )}
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
              <div className="ftr-social  float-end">
                <ul className="d-flex flex-row justify-content-around">
                  {footerLabel && footerLabel.linkedin_url && (
                    <li>
                      <a
                        href={formatUrlLink(footerLabel.linkedin_url)}
                        target="blank"
                        rel="noreferrer noopener"
                      >
                        <i className="mdi mdi-linkedin"></i>
                      </a>
                    </li>
                  )}

                  {footerLabel && footerLabel.facebook_url && (
                    <li>
                      <a
                        href={formatUrlLink(footerLabel.facebook_url)}
                        target="blank"
                        rel="noreferrer noopener"
                      >
                        <i className="mdi mdi-facebook"></i>
                      </a>
                    </li>
                  )}
                  {footerLabel && footerLabel.twitter_url && (
                    <li>
                      <a
                        href={formatUrlLink(footerLabel.twitter_url)}
                        target="blank"
                        rel="noreferrer noopener"
                      >
                        <i className="mdi mdi-twitter"></i>
                      </a>
                    </li>
                  )}
                  {footerLabel && footerLabel.youtube_url && (
                    <li>
                      <a
                        href={formatUrlLink(footerLabel.youtube_url)}
                        target="blank"
                        rel="noreferrer noopener"
                      >
                        <i className="mdi mdi-youtube"></i>
                      </a>
                    </li>
                  )}
                  {footerLabel && footerLabel.instagram_url && (
                    <li>
                      <a
                        href={formatUrlLink(footerLabel.instagram_url)}
                        target="blank"
                        rel="noreferrer noopener"
                      >
                        <i className="mdi mdi-instagram"></i>
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
