import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { saveVariable } from "../../../Helpers/Session";
import { apiRequest } from "../../../Helpers/General";
import "react-phone-number-input/style.css";
import "./Auth.css";
import { useHistory } from "react-router";
import { useSetRecoilState } from "recoil";
import { curRouteDetailsState } from "../../../Helpers/Recoil";
import iconJoinNow from "./../../../asset/svg/icon-joinnow.svg";

export default function Login(props) {
  const histoty = useHistory();
  const setCurRouteDetails = useSetRecoilState(curRouteDetailsState);
  useEffect(() => {
    setCurRouteDetails(props.componentInfo);
    if(props.userProfile) {
      histoty.push("/myprofile");
    }
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [apiMsg, setApiMsg] = useState("");
  const [isApiCalling, setIsApiCalling] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [eyeClass, setEyeClass] = useState("mdi mdi-eye-off");
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
    setEyeClass(passwordShown ? "mdi mdi-eye-off" : "mdi mdi-eye text-danger");
  };

  const loginAction = async (data) => {
    setIsApiCalling(true);
    try {
      const rememberMe = data.rememberMe;
      const apiSetting = {
        method: "POST",
        apiParams: { email_id: data.email, password: data.password },
      };
      const apiResult = await apiRequest("user_signin", apiSetting);
      setApiMsg(apiResult.data.settings.message);
      if (apiResult.data.settings.success === "1") {
        let user = apiResult.data.data;
        // user["id"] = apiResult.data.data.token_info[0].auth_token;
        if (rememberMe) {
          saveVariable("rememberMe", rememberMe, true);
          saveVariable("user", user, true);
        } else {
          saveVariable("user", user);
        }
        props.setUserProfile(user);
        if (user.profile_completed === "Yes") {
          histoty.push("/MyProfile");
        } else {
          histoty.push("/EditProfile");
        }
      }
    } catch (error) {
      setApiMsg("Something Went Wrong! Please try later");
      console.log(error);
    } finally {
      setIsApiCalling(false);
    }
  };

  return (
    <div className="inner-page login-page">
      <div className="container">
        <div className="row">
          <div className="login-main-page d-flex alignitems:Center place-content-center h-100">
            <div className="login-form m-auto d-flex align-items-center flex-column place-content-center">
              <form className="form" onSubmit={handleSubmit(loginAction)}>
                <div className="api-error mt-10">
                  <span className="text-danger">{apiMsg && apiMsg}</span>
                </div>
                <div className="form-row mb-4 email-row">
                  <label htmlFor="inputEmail" className="float-start mb-1">
                    Email
                  </label>
                  <div className="form-input-div">
                    <input
                      type="email"
                      id="inputEmail"
                      className="form-control"
                      placeholder="Email address"
                      autoFocus
                      {...register("email", {
                        required: "Enter your e-mail",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "Enter a valid e-mail address",
                        },
                      })}
                    />
                    <div className="input-err">
                      {/* errors will return when field validation fails  */}
                      {errors?.email?.message}
                    </div>
                  </div>
                </div>
                <div className="form-row mb-4 forgotpass-row d-flex flex-column">
                  <label
                    htmlFor="inputPassword"
                    className="float-start mb-1 w-100 d-flex justify-content-between"
                  >
                    Password
                  </label>
                  <div className="form-input-div">
                    <input
                      type={passwordShown ? "text" : "password"}
                      id="inputPassword"
                      className="form-control"
                      placeholder="Password"
                      {...register("password", {
                        required: "Enter your password",
                        minLength: {
                          value: 6,
                          message: "Minimum 6 characters",
                        },
                      })}
                    />
                    <i
                      onClick={togglePasswordVisiblity}
                      className={eyeClass + " mdi password-eye"}
                    ></i>
                    <div className="input-err">
                      {/* errors will return when field validation fails  */}
                      {errors?.password?.message}
                    </div>
                  </div>
                </div>

                <div className="form-action d-flex flex-column">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <div className="checkbox">
                          <label className="d-flex align-items-center">
                            <input
                              type="checkbox"
                              id="remember-me"
                              className="me-2"
                              {...register("rememberMe")}
                            />
                            Keep me logged in
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 text-center mt-5">
                      <button
                        className="btn-primary signin-btn m-auto"
                        type="submit"
                        disabled={isApiCalling}
                      >
                        <i className="mdi mdi-login"></i> Sign in
                      </button>
                      {isApiCalling && (
                        <i className="mdi mdi-36px mdi-spin mdi-loading"></i>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 text-center">
                      <div className="forgot-link m-auto text-center">
                        <Link to="/forgotpassword" className="ms-auto">
                          forgot password ?
                        </Link>
                      </div>{" "}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="joinnow-action d-flex flex-column text-center align-item-center justify-content-center">
              <div className="form-input-div">
                <Link to="/pricing" className="btn-joinnow">
                  <img src={iconJoinNow} className="svg" alt="" />
                  Join Now
                </Link>
              </div>
              {/*  <label htmlFor="inputEmail" className="float-start mb-1">
                New Member
              </label> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
