import React, { useState, useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { useParams } from "react-router-dom";
import { curRouteDetailsState } from "../../../../Helpers/Recoil";
import iconTime from "../../../../asset/svg/icon-time.svg";
import iconDate from "../../../../asset/svg/icon-date.svg";
import iconType from "../../../../asset/svg/icon-type.svg";
import iconSeat from "../../../../asset/svg/icon-seat.svg";
import Modal from "./../../../../componants/Modal/Modal";
import SignInModal from "../SignInModal/SignInModal";
import { apiRequest } from "../../../../Helpers/General";
import moment from "moment";
import swal from "sweetalert";
import Loader from "../../../../componants/Loader/Loader";
import DocumentMeta from "react-document-meta";
import NoRecord from "../../../../componants/NoRecord/NoRecord";
import { HashLink } from "react-router-hash-link";

export const formatDate = (
  dateType = "",
  dateValue = undefined,
  calc = undefined
) => {
  let value = "";
  dateValue = moment(dateValue);
  if (calc && calc.type && calc.period && calc.periodVal) {
    if (calc.type === "add")
      dateValue = dateValue.add(calc.periodVal, calc.period);
    if (calc.type === "subtract")
      dateValue = dateValue.subtract(calc.periodVal, calc.period);
  }
  if (dateType === "DateTime")
    value = dateValue.format(process.env.REACT_APP_DATE_TIME_FORMAT);
  else if (dateType === "DateTimeSecond")
    value = dateValue.format("DD/MM/YYYY hh:mm:ss");
  else if (dateType === "12HourFormate")
    value = dateValue.format("DD/MM/YYYY hh:mm A");
  else if (dateType === "Time")
    value = dateValue.format(process.env.REACT_APP_TIME_FORMAT);
  else if (dateType === "Date")
    value = dateValue.format(process.env.REACT_APP_DATE_FORMAT);
  else if (dateType === "systemDate") value = dateValue.format("YYYY-MM-DD");
  else if (dateType === "systemTime") value = dateValue.format("HH:mm");
  else value = dateValue.format("YYYY-MM-DD HH:mm:ss");
  return value;
};

const EventDetail = (props) => {
  let userId = props.id
    ? props.id
    : props.userProfile && props.userProfile.user_master_id;
  let { id } = useParams();
  let ID = id.split("-").pop();
  const setCurRouteDetails = useSetRecoilState(curRouteDetailsState);
  const [eventMetingDetail, setEventMetingDetail] = useState([]);
  const [eventMetingDetailTrn, setEventMetingDetailTrn] = useState([]);
  const [isPageUpdating, setIsPageUpdating] = useState(true);
  // const [bookStatus, setbookStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setCurRouteDetails({
      title:
        eventMetingDetail && eventMetingDetail.meeting_type === "Seminar"
          ? "Webinar"
          : eventMetingDetail.meeting_type
          ? eventMetingDetail.meeting_type
          : "Event Details",
      path: "/event-detail/:id",
      className: "events-detail-header inner-header",
      menuItem: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventMetingDetail]);
  useEffect(() => {
    const GetData = async () => {
      try {
        const apiSetting = {
          method: "GET",
          apiParams: {
            country_id: "102",
            event_id: ID,
            user_id: userId,
            user_type: props.userProfile ? props.userProfile.user_type : "",
          },
        };
        const apiResult = await apiRequest("get_events_detail", apiSetting);

        if (apiResult.data.settings.success === "1") {
          setEventMetingDetail(
            apiResult.data.data.modify_data.return_data[0]
              .meeting_master_details
          );
          setEventMetingDetailTrn(
            apiResult.data.data.modify_data.return_data[0].meeting_trans
          );
          setIsPageUpdating(false);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsPageUpdating(false);
      }
    };
    if (isPageUpdating) {
      GetData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPageUpdating, id, userId]);
  const cancelBooking = async (rowid) => {
    try {
      setLoading(true);
      let parms = {
        event_id: rowid.meeting_id,
        booking_type: "event",
        booking_date_time: moment().format("YYYY-MM-DD HH:MM:SS"),
        booking_status: "Booked",
        user_id: props.userProfile && props.userProfile.user_master_id,
        country_id: "102",
      };
      const apiSetting = {
        method: "POST",
        apiParams: parms,
      };
      const apiResult = await apiRequest("regester_for_event", apiSetting);
      if (apiResult.data.settings.success === "1") {
        swal({
          title: "Success",
          text: apiResult.data.settings.message,
          icon: "success",
          button: {
            text: "Done",
            closeModal: true,
          },
          dangerMode: false,
        });
        setIsPageUpdating(true);
      } else {
        swal({
          title: "Error",
          text: apiResult.data.settings.message,
          icon: "warning",
          button: {
            text: "Close",
            closeModal: true,
          },
          dangerMode: true,
        });
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const meta = {
    title: eventMetingDetail.meta_title && eventMetingDetail.meta_title,
    description:
      eventMetingDetail.meta_description && eventMetingDetail.meta_description,
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          eventMetingDetail.meta_key_words && eventMetingDetail.meta_key_words,
      },
    },
  };

  if (isPageUpdating) {
    return <Loader />;
  } else if (eventMetingDetail.length === 0) {
    return <NoRecord pageName="Data" />;
  } else {
    return (
      <DocumentMeta {...meta}>
        <div className="inner-page networking-detail-page">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                <div
                  className={
                    "common-page-content networking-detail-content " +
                    eventMetingDetail.meeting_for.toLowerCase()
                  }
                >
                  <div className="row">
                    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2 text-center">
                      <div className="event-head d-inline-flex align-item-center m-auto">
                        <div className="date-stats d-flex flex-column align-items-center">
                          <span>
                            {eventMetingDetail.meeting_confrence_mode &&
                            eventMetingDetail.meeting_confrence_mode
                              ? eventMetingDetail.meeting_confrence_mode
                              : "Offline"}
                          </span>
                          <label>
                            {moment(
                              eventMetingDetail &&
                                eventMetingDetail.meeting_date
                            ).format("DD")}
                            <small>
                              {moment(
                                eventMetingDetail &&
                                  eventMetingDetail.meeting_date
                              ).format("MMM YYYY")}
                            </small>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
                      <div className="detail-content">
                        <div className="detail-content-top">
                          <div className="detail-page-title page-title">
                            <h2>
                              {eventMetingDetail &&
                                eventMetingDetail.meeting_title}
                            </h2>
                          </div>
                          <div className="detail-page-status d-flex">
                            {eventMetingDetail.end_date_text &&
                              eventMetingDetail.end_date_text !== "" && (
                                <div className="end-date me-2">
                                  <label>
                                    <b>
                                      {eventMetingDetail.conference_days} Days
                                    </b>{" "}
                                  </label>
                                  <span>
                                    Complete on{" "}
                                    {formatDate(
                                      "Date",
                                      eventMetingDetail.meeting_end_date
                                    )}{" "}
                                  </span>
                                </div>
                              )}
                            <div className="post-time">
                              {eventMetingDetail &&
                                eventMetingDetail.meeting_time}
                              <span>
                                {eventMetingDetail &&
                                  eventMetingDetail.gmt_off_set &&
                                  " (" + eventMetingDetail.gmt_off_set + ")"}
                              </span>
                            </div>
                            <div
                              className={
                                "post-type ms-auto " +
                                eventMetingDetail.meeting_for.toLowerCase()
                              }
                            >
                              {eventMetingDetail &&
                                eventMetingDetail.meeting_for}
                            </div>
                          </div>
                          {eventMetingDetailTrn && (
                            <div className="detail-page-status our-speakers event-our-speakers">
                              <h3>Keynote Speakers</h3>
                              <div className="d-flex flex-row flex-wrap">
                                {eventMetingDetailTrn.map((item, key) => (
                                  <div
                                    className="card d-flex flex-row"
                                    key={key}
                                  >
                                    <div className="card-header">
                                      <div className="profile-pic">
                                        <img
                                          src={
                                            item.trn_image ||
                                            `${process.env.PUBLIC_URL}/asset/images/no-image.jpg`
                                          }
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                    <div className="card-content">
                                      <HashLink
                                        to={
                                          "/event-detail/" +
                                          id +
                                          "#key-not-speker" +
                                          item.trn_title
                                        }
                                        scroll={(el) =>
                                          el.scrollIntoView({
                                            behavior: "auto",
                                            block: "end",
                                            bottom: 0,
                                          })
                                        }
                                      >
                                        <div className="card-title">
                                          {item.trn_title}
                                        </div>
                                      </HashLink>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="detail-content-banner">
                          <div className="event-banner">
                            <img
                              src={
                                (eventMetingDetail &&
                                  eventMetingDetail.meeting_image) ||
                                `${process.env.PUBLIC_URL}/asset/images/no-image.jpg`
                              }
                              alt={
                                eventMetingDetail
                                  ? eventMetingDetail.alt_for_event_image
                                  : ""
                              }
                            />
                          </div>
                        </div>
                        <div className="detail-content-mdl ">
                          <p className="common-paragraph">
                            <strong className="highlight-text">
                              {eventMetingDetail &&
                                eventMetingDetail.meeting_title}
                            </strong>
                          </p>
                          <div
                            className="common-paragraph"
                            dangerouslySetInnerHTML={{
                              __html:
                                eventMetingDetail &&
                                eventMetingDetail.long_description,
                            }}
                          ></div>
                        </div>
                        {eventMetingDetailTrn && (
                          <div className="row our-speaker-title">
                            <div className="page-title  text-start">
                              <h2>More About Speakers</h2>
                            </div>
                          </div>
                        )}

                        {eventMetingDetailTrn && (
                          <div className="row">
                            <div className="our-speakers">
                              {eventMetingDetailTrn.map((item1, key) => (
                                <div key={key} className="card d-flex flex-row">
                                  <div className="card-header">
                                    <div className="profile-pic">
                                      <img
                                        src={
                                          item1.trn_image ||
                                          `${process.env.PUBLIC_URL}/asset/images/no-image.jpg`
                                        }
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className="card-content"
                                    id={"key-not-speker" + item1.trn_title}
                                  >
                                    <div className="card-title">
                                      {item1.trn_title}
                                    </div>
                                    <p className="common-paragraph">
                                      {item1.trn_description}
                                    </p>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <div className="right-search-block">
                  <div className="right-block-title">
                    <h2>
                      Register <span>for the Meeting</span>
                    </h2>
                  </div>

                  <div className="card">
                    <div className="card-content">
                      <ul>
                        <li>
                          <div className="reg-time">
                            <img src={iconTime} alt="" />{" "}
                            <strong>
                              {eventMetingDetail &&
                              eventMetingDetail.meeting_time
                                ? eventMetingDetail.meeting_time
                                : "--"}
                            </strong>
                            {eventMetingDetail &&
                            eventMetingDetail.host_country_name
                              ? eventMetingDetail.host_country_name
                              : "--"}
                            {/* India */}
                          </div>
                        </li>
                        <li>
                          <div className="reg-date">
                            <img src={iconDate} alt="" />
                            <strong>
                              {eventMetingDetail &&
                              eventMetingDetail.meeting_date
                                ? moment(
                                    eventMetingDetail &&
                                      eventMetingDetail.meeting_date
                                  ).format("ll")
                                : "--"}
                            </strong>{" "}
                            {eventMetingDetail && eventMetingDetail.meeting_date
                              ? moment(
                                  eventMetingDetail &&
                                    eventMetingDetail.meeting_date
                                ).format("dddd")
                              : "--"}
                          </div>
                        </li>
                        <li>
                          <div className="reg-seats">
                            <img src={iconSeat} alt="" />{" "}
                            <strong>
                              {eventMetingDetail &&
                              eventMetingDetail.meeting_allowed_members
                                ? eventMetingDetail.meeting_allowed_members
                                : "--"}
                            </strong>{" "}
                            Seats
                          </div>
                        </li>
                        <li>
                          <div className="reg-type">
                            <img src={iconType} className="svg" alt="" />{" "}
                            <strong>
                              {eventMetingDetail &&
                              eventMetingDetail.meeting_for
                                ? eventMetingDetail.meeting_for
                                : "--"}
                            </strong>
                          </div>
                        </li>

                        {eventMetingDetail &&
                          eventMetingDetail.meeting_type === "Conference" &&
                          eventMetingDetail.meeting_confrence_mode ===
                            "Offline" && (
                            <li>
                              <div className="reg-type">
                                <img src={iconType} className="svg" alt="" />{" "}
                                <strong>Conference Hall</strong>
                                {eventMetingDetail &&
                                  eventMetingDetail.meeting_venue_info}
                              </div>
                            </li>
                          )}
                        <li>
                          {loading ? (
                            <button className="btn-green">
                              {" "}
                              <span
                                className="spinner-border text-white  text-center"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </span>
                            </button>
                          ) : eventMetingDetail.meeting_status === "Archive" ? (
                            <button className= "btn-green" disabled> Event Completed </button>
                          ) : (props.userProfile ? (
                            eventMetingDetail.booking_status === "Booked" ? (
                              <button
                                className={
                                  "btn-green event-" +
                                  eventMetingDetail.booking_status.toLowerCase()
                                }
                              >
                                Already Registered
                              </button>
                            ) : (
                              <button
                                className="btn-green"
                                onClick={() => cancelBooking(eventMetingDetail)}
                              >
                                Register Now
                              </button>
                            )
                          ) : (
                            <Modal
                              btnCoverClass="btn-green"
                              title="Sign In To Continue"
                              id="registerPopup"
                              btnTitle="Register Now"
                              btnClass=""
                              child={SignInModal}
                            />
                          ))}
                        </li>
                        <li>
                          <p className="common-paragraph">
                            You can cancel your booking upto{" "}
                            <b>
                              {eventMetingDetail &&
                              eventMetingDetail.meeting_cancellation_allowed_hours
                                ? eventMetingDetail.meeting_cancellation_allowed_hours
                                : "--"}
                            </b>{" "}
                            hours before the event start time.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DocumentMeta>
    );
  }
};

export default EventDetail;
