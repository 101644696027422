import React, { useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import Navigation from "./Navigation";
import { Link, NavLink } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { curRouteDetailsState } from "../../../Helpers/Recoil";
import logo from "../../../asset/svg/logo.svg";
import { imgSvg } from "./../../../Helpers/General";
import Avatar from "../../../componants/Avatar/Avatar";
const Header = (props) => {
  const [selected, setSelected] = useState("IN");
  const routeDetails = useRecoilValue(curRouteDetailsState);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  return (
    <header
      id="header"
      className={
        "fixed-top d-flex flex-column  " +
        ((routeDetails && routeDetails.className) ? routeDetails.className :"")
      }
    >
      <div className="container">
        <div className="row">
          <nav className="navbar navbar-expand-lg p-0">
            <div className="logo">
              <Link to="/">
                <img src={logo} alt="Xperts Legal" className="svg" height="60" />
              </Link>
            </div>
            <div className="navbar-middle m-auto">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarCollapse"
                aria-controls="navbarCollapse"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={toggle}
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className={isOpen?"navbar-collapse m-auto w-100":"collapse navbar-collapse m-auto w-100"}
                id="navbarCollapse"
              >
                <Navigation setIsOpen={setIsOpen} isOpen={isOpen} settings={props}/>
              </div>
            </div>
            {props.userProfile ?
              <div className="navbar-right">
                <div className="header-action">
                  <ul className="d-flex justify-content-around align-items-center m-0">
                    <li>
                      <ReactFlagsSelect
                        searchable={true}
                        selected={selected}
                      
                        onSelect={(code) => setSelected(code)}
                      />
                    </li> <li>

                      <div className="dropdown text-end user-dropdown">

                        <button className="d-flex align-items-center link-dark text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                          <Avatar fullName={props.userProfile.first_name} pictureUrl={props.userProfileprofile_pic ? props.userProfileprofile_pic : ""} size="32px" fontSize="16px"></Avatar>
                          <i className="mdi mdi-chevron-down"></i>
                        </button>
                        <ul className="dropdown-menu text-small" aria-labelledby="dropdownUser1">
                          <li>
                            <NavLink className="dropdown-item" to="/MyProfile">
                              My Profile
                            </NavLink>
                          </li>
                          <li>
                            <NavLink className="dropdown-item" to="/change-password">
                              Change Password
                            </NavLink>
                          </li>
                          <li>
                            <a className="dropdown-item" onClick={props.logoutAction} href="/">
                              Sign out
                            </a>
                          </li>

                        </ul>
                      </div></li>

                  </ul>
                </div>
              </div>
              : <div className="navbar-right">
                <div className="header-action">
                  <ul className="d-flex justify-content-around align-items-center m-0">
                    <li>

                      <Link to="/login" className="btn-login">
                      Join Now / Sign In
                      </Link>
                    </li>

                    <li>
                      <ReactFlagsSelect
                        searchable={true}
                        selected={selected}
                        onSelect={(code) => setSelected(code)}
                      />
                    </li>
                  </ul>
                </div>
              </div>}
          </nav>
        </div>
      </div>
      <div className="page-title inner-page-title">
        <div className="pageTitle w-100">
          <h1>{routeDetails && routeDetails.title}</h1>
        </div>
      </div>

    </header>
  );
};
imgSvg();
export default Header;
