import React, { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { curRouteDetailsState } from "../../../Helpers/Recoil";
import { clearAll } from "../../../Helpers/Session";
import { useHistory } from "react-router";
const ThankYou = (props) => {
let history=useHistory();
  const setCurRouteDetails = useSetRecoilState(curRouteDetailsState);
  useEffect(() => {
    setCurRouteDetails(props.componentInfo);
  });
  useEffect(()=>{
    setTimeout(()=>{
      clearAll();
      history.push("/login");
      window.location.reload();
    },5000)
  })
  return (
    <div className="inner-page about-page">
      <div className="container">
        <div className="row">
          <div className="page-title  text-center">
            <h1>Thank You</h1>
            <p className="common-paragraph">We have sent you a temporary password <br /> on your registered email address</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
