import { useEffect, useContext, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import PhoneInput, {
  isValidPhoneNumber,
  getCountryCallingCode,
  parsePhoneNumber,
} from "react-phone-number-input";
import { useForm } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { curRouteDetailsState } from "../../../../Helpers/Recoil";
import SelectBox from "../../../../componants/SelectBox/SelectBox";
import { apiRequest } from "../../../../Helpers/General";
import { MasterContext } from "../../../../Context/index";
import ReactToast from "../../../../componants/Toast/ReactToast";
var emailExist = "";
//var usernameExist ="";
var plan_data = [];
var package_details = [];
const MembershipCheckout = (props) => {
  const history = useHistory();
  const [planOption, setPlanoption] = useState([]);
  var sel_plan = JSON.parse(localStorage.getItem("planDetail"));

  var curr_currency = JSON.parse(localStorage.getItem("currency"));  
  const [selectedCurr, setSelectedCurr] = useState(curr_currency);

  if (Object.keys(planOption).length === 0) {
    const GetPlanData = async () => {
      const apiSetting = {
        method: "GET",
        apiParams: { country_id: "102", from_page: "pricing" },
      };
      const apiResult = await apiRequest(
        "get_package_plan_page_detail",
        apiSetting
      );
      if (apiResult.data.settings.success === "1") {
        let plan_details = apiResult.data.data;
        if (
          plan_details &&
          plan_details.data_formation &&
          plan_details.data_formation.return_data
        ) {
          plan_data =
            plan_details.data_formation.return_data[0].package_master_info;
          package_details = plan_details.data_formation.return_data.map(
            (value, key) => {
              value.package_master_info["package_name_new"] =
                value.package_master_info.package_name +
                " - " +
                value.package_master_info.duration_name +
                " - " +
                value.package_master_info.discounted_price;
              return value.package_master_info;
            }
          );

          // package_details = package_details.filter((row1)  => {
          //   return ((row1.discounted_price && row1.discounted_price > 0))
          // });

          setPlanoption(package_details);
        }
      }
    };
    GetPlanData();
  }
  var plan = sel_plan === null || sel_plan === "" ? plan_data : sel_plan;
  const setCurRouteDetails = useSetRecoilState(curRouteDetailsState);
  const {
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();
  const { countrydata, genralMasterData } = useContext(MasterContext);
  const allow_multi_currency_signup = (genralMasterData?.allow_multi_currency_signup 
    && genralMasterData?.allow_multi_currency_signup === "yes") ? true : false ;
  const [currList, setCurList] = useState([]);
  const [passwordShown, setPasswordShown] = useState(false);
  const [eyeClass, setEyeClass] = useState("mdi mdi-eye-off");
  const [confirmpasswordShown, setconfirmpasswordShown] = useState(false);
  const [eyeClassCpass, seteyeClassCpass] = useState("mdi mdi-eye-off");
  const [stateOption, setStateoption] = useState([]);
  const [countryOption, setCountryOption] = useState([]);
  // eslint-disable-next-line
  const [countryCode, setCountryCode] = useState("IN");
  const [cityOption, setcityOption] = useState([]);
  const [countryId, setCountryId] = useState();
  const [stateId, setStateId] = useState();
  const [cityId, setCityId] = useState();
  const [phoneCode, setPhoneCode] = useState();
  const [termAccepted, setTermAccepted] = useState(false);
  const stateRef = useRef([]);
  const cityRef = useRef([]);
  const [loading, setLoading] = useState(false);

  const [selectedPackage, setSelectedPackage] = useState([]);
  const [packageMasterId, setPackageMasterId] = useState();
  const [packageAmount, setPackageAmount] = useState();
  const [packageDuration, setPackageDuration] = useState();
  const [currencyId, setCurrencyId] = useState();
  const [currencyCode, setCurrencyCode] = useState();
  const [packageRef, setPackageRef] = useState("");
  const [packageText, setPackageText] = useState("");

    const prepareCurrency = async (loop) => {
        let currency_list = [];
        if(loop && Object.keys(loop).length > 0) {
            for(const x in loop) {
                let obj = {
                    "id" : x,
                    "name" : x
                }
                currency_list.push(obj);
            }
            if(currency_list.length === 1) {
              	setSelectedCurr(currency_list);
            }
        }
        setCurList(currency_list);
    }

  if (packageMasterId === undefined && plan && plan.package_master_id) {
    let sel_plan = [];
    sel_plan.push(plan);
    setSelectedPackage(sel_plan);
    setPackageMasterId(plan.package_master_id);
    setPackageAmount(plan.discounted_price);
    setPackageDuration(plan.package_duration_days);
    setCurrencyId(plan.currency_master_id);
    setCurrencyCode(plan.package_currency_code);
    setPackageRef(plan.package_name);
    setPackageText(
      plan.package_currency_code +
        " " +
        plan.discounted_price +
        " Every " +
        plan.package_duration_days +
        " Days."
    );
    prepareCurrency(plan?.currency_info);
  }

  useEffect(() => {
    if (props.userProfile) {
      history.push("/myprofile");
    }
    setCurRouteDetails(props.componentInfo);
    if (countrydata) {
      setCountryOption(countrydata);
    }
    // eslint-disable-next-line
  }, [countrydata]);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
    setEyeClass(passwordShown ? "mdi mdi-eye-off" : "mdi mdi-eye text-danger");
  };
  const toggleConfirmPasswordVisiblity = () => {
    setconfirmpasswordShown(confirmpasswordShown ? false : true);
    seteyeClassCpass(
      confirmpasswordShown ? "mdi mdi-eye-off" : "mdi mdi-eye text-danger"
    );
  };
  const onchangeConfirmpassword = (e) => {
    let form = document.querySelector("form");
    let pass = form.querySelector('input[name="password"]').value;
    if (pass === e.target.value) {
      clearErrors("confirm_password");
    } else {
      setError("confirm_password", {
        type: "invalid",
        message: " Password not match.",
      });
    }
  };
  const onChangePassword = (e) => {
    let form = document.querySelector("form");
    let pass = form.querySelector('input[name="confirm_password"]').value;
    if (pass === e.target.value) {
      clearErrors("confirm_password");
    } else {
      setError("confirm_password", {
        type: "invalid",
        message: " Password not match.",
      });
    }
  };
  const changeCountry = async (e) => {
    setValue("country_id", e[0] && e[0].country_id);
    setValue("country", e[0] && e[0].country_id);
    setCountryId(e[0] && e[0].country_id);
    if (countryId) {
      stateRef.current.clear();
      setStateId();
    }
    if (cityId) {
      cityRef.current.clear();
      setCityId();
    }
    setcityOption([]);
    if (e[0] && e[0].country_id) {
      const apiSetting = {
        method: "GET",
        apiParams: { country_id: e[0].country_id },
      };
      const apiResult = await apiRequest("get_state_master", apiSetting);
      if (apiResult.data.settings.success === "1") {
        setStateoption(apiResult.data.data);
      }
    } else {
      setStateoption([]);
    }
  };
  const changeState = async (e) => {
    setValue("state", e[0] && e[0].state_id);
    setStateId(e[0] && e[0].state_id);
    if (cityId) {
      cityRef.current.clear();
      setCityId();
    }
    if (e[0] && e[0].state_id) {
      const apiSetting = {
        method: "GET",
        apiParams: { state_id: e[0].state_id ,req_from:"getall" },
      };
      const apiResult = await apiRequest("get_city_master", apiSetting);
      if (apiResult.data.settings.success === "1") {
        setcityOption(apiResult.data.data);
      }
    } else {
      setcityOption([]);
    }
  };

  const changeCity = (e) => {
    setCityId(e[0] && e[0].city_id);
  };
  const changeMobile = (value, e) => {
    // let code= getCountryCallingCode(value)
    if (value) {
      if (isValidPhoneNumber(value) === true) {
        let phoneNumber = parsePhoneNumber(value);
        let country_code = phoneNumber.country;
        let country_call = getCountryCallingCode(country_code);
        setValue("mobile", value);
        setPhoneCode(country_call);
        clearErrors("mobile");
      } else {
        setError("mobile", {
          type: "invalid",
          message: "Invalid Mobile number.",
        });
      }
    } else {
      setError("mobile", {
        type: "required",
        message: "Mobile number is mandatory.",
      });
    }
  };

  const onCountryChange = (e) => {
    setCountryCode(e);
  };

  const checkEmail = async (e) => {
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)) {
      try {
        const apiSetting = {
          method: "POST",
          apiParams: { email_id: e.target.value },
        };
        const apiResult = await apiRequest(
          "validate_email_and_username_exists",
          apiSetting
        );
        if (apiResult.data.settings.success === "1") {
          clearErrors("email");
          emailExist = "";
        } else {
          emailExist = apiResult.data.settings.message;
          setError("email", {
            type: "invalid",
            message: apiResult.data.settings.message,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
      }
    }
  };

  const checkGstNo = async (e) => {
    const gstNo = e.target.value;
    if (gstNo === "" || gstNo === undefined || gstNo === null) {
      clearErrors("gstn_no");
    } else {
      const isValid = validateGstnNumber(gstNo);
      if (isValid) {
        clearErrors("gstn_no");
      } else {
        setError("gstn_no", {
          type: "invalid",
          message: "Invalid Gst Number",
        });
      }
    }
  };
  function validateGstnNumber(gstn_no) {
    //same logc as Adesk, given by Sanjay Rao
    var REGEX_1 =
      /^([0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[Zz1-9A-Ja-j]{1}[0-9a-zA-Z]{1})+$/;
    var REGEX_2 = /^([0-9]{4}[A-Z]{3}[0-9]{5}[UO]{1}[N][A-Z0-9]{1})+$/;
    var REGEX_3 =
      /^([0-9]{2}[a-zA-Z]{4}[0-9]{5}[a-zA-Z]{1}[0-9]{1}[Z]{1}[0-9]{1})+$/;
    var REGEX_4 = /^([0-9]{4}[a-zA-Z]{3}[0-9]{5}[N][R][0-9a-zA-Z]{1})+$/;
    var REGEX_5 =
      /^([0-9]{2}[a-zA-Z]{4}[a-zA-Z0-9]{1}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[D]{1}[0-9a-zA-Z]{1})+$/;
    var REGEX_6 =
      /^([0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[C]{1}[0-9a-zA-Z]{1})+$/;
    var REGEX_7 = /^([9][9][0-9]{2}[a-zA-Z]{3}[0-9]{5}[O][S][0-9a-zA-Z]{1})+$/;

    if (gstn_no === "") {
      return true;
    }

    gstn_no = gstn_no.toUpperCase();
    if (gstn_no.match(REGEX_1)) {
      return true;
    } else if (gstn_no.match(REGEX_2)) {
      return true;
    } else if (gstn_no.match(REGEX_3)) {
      return true;
    } else if (gstn_no.match(REGEX_4)) {
      return true;
    } else if (gstn_no.match(REGEX_5)) {
      return true;
    } else if (gstn_no.match(REGEX_6)) {
      return true;
    } else if (gstn_no.match(REGEX_7)) {
      return true;
    } else {
      return false;
    }
  }

  const changePalnDropdown = async (e) => {
    if(e.length <= 0) {
        return true;
    }
    setSelectedPackage(e);
    setPackageMasterId(e[0] && e[0].package_master_id);
    setPackageDuration(e[0] && e[0].package_duration_days);
    setPackageRef(e[0] && e[0].package_name);
	let cur_id = 0;
	let disc_price = 0;
	let cur_code = selectedCurr['0']['id'];
	if(e[0]?.currency_info && e[0].currency_info[cur_code]) {
		disc_price = e[0].currency_info[cur_code]['discounted_price'];
		cur_id = e[0].currency_info[cur_code]['currency_master_id'];
	}

    setPackageAmount(disc_price);
    setCurrencyId(cur_id);
    setCurrencyCode(cur_code);
    setPackageText(
		cur_code +
          " " +
          disc_price +
          " Every " +
          e[0].package_duration_days +
          " Days."
    );
    prepareCurrency(e[0] && e[0].currency_info);
  };
  const changeCurrDropdown = (e) => {
    setSelectedCurr(e);
    let changed_cur = ((e[0] && e[0]['id']) || "INR");
    if(changed_cur && selectedPackage && selectedPackage[0]?.currency_info && selectedPackage[0].currency_info[changed_cur]) {
      let curInfo = selectedPackage[0].currency_info[changed_cur];
      setPackageAmount(curInfo.discounted_price);
      setCurrencyId(curInfo.currency_master_id);
      setCurrencyCode(curInfo.package_currency_code);
      setPackageText(
        curInfo.package_currency_code +" " + curInfo.discounted_price +" Every " +selectedPackage[0].package_duration_days +" Days."
      );
    }
  };

  /* const checkUserName = async (e) => {
    if ((e.target.value).length>5) {
      try {
        const apiSetting = {
          method: "POST",
          apiParams: { username: e.target.value },
        };
        const apiResult = await apiRequest("validate_email_and_username_exists", apiSetting);
        if (apiResult.data.settings.success === "1") {
          clearErrors("username");
          usernameExist=""
        } else {
          usernameExist="Username Already Exists"
          setError("username", {
            type: "invalid",
            message: "Username Already Exists",
          })
        }
      } catch (error) {
        console.log(error);
      } finally {
      }
    }else{
      setError("username", {
        type: "invalid",
        message: "Minimum 6 characters",
      })
      
    }
  }; */

  const onSubmitEvent = (e) => {
    setLoading(true);
    let form = document.querySelector("form");
    // eslint-disable-next-line
    let userName = form.querySelector('input[name="email_id"]').value || 0;
    let email = form.querySelector('input[name="email_id"]').value || 0;
    let password = form.querySelector('input[name="password"]').value || 0;
    let confirm_password =
      form.querySelector('input[name="confirm_password"]').value || 0;
    let first_name = form.querySelector('input[name="first_name"]').value || 0;
    let last_name = form.querySelector('input[name="last_name"]').value || 0;
    let address1 = form.querySelector('input[name="address1"]').value || 0;
    let country_id = form.querySelector('input[name="country_id"]').value || 0;
    let state_id = form.querySelector('input[name="state_id"]').value || 0;
    let mobile_no = form.querySelector('input[name="mobile_no"]').value || 0;

    if (email === 0) {
      ReactToast({ type: "error", message: "Email is required." });
      e.preventDefault();
      setLoading(false);
      return false;
    } else if (password === 0) {
      ReactToast({ type: "error", message: "Password is required." });
      e.preventDefault();
      setLoading(false);
      return false;
    } else if (confirm_password === 0) {
      ReactToast({ type: "error", message: "Confirm Password is required." });
      e.preventDefault();
      setLoading(false);
      return false;
    } else if (first_name === 0) {
      ReactToast({ type: "error", message: "Confirm Password is required." });
      e.preventDefault();
      setLoading(false);
      return false;
    } else if (last_name === 0) {
      ReactToast({ type: "error", message: "Confirm Password is required." });
      e.preventDefault();
      setLoading(false);
      return false;
    } else if (address1 === 0) {
      ReactToast({ type: "error", message: "Address1 is required." });
      e.preventDefault();
      setLoading(false);
      return false;
    } else if (country_id === 0) {
      ReactToast({ type: "error", message: "Country is required." });
      e.preventDefault();
      setLoading(false);
      return false;
    } else if (state_id === 0) {
      ReactToast({ type: "error", message: "State is required." });
      e.preventDefault();
      setLoading(false);
      return false;
    } else if (mobile_no === 0) {
      ReactToast({ type: "error", message: "Phone Number is required." });
      e.preventDefault();
      setLoading(false);
      return false;
    }

    /* let formData = {
      user_name: userName,
      email: email,
      pass: password,
      conf_pass: confirm_password,
      first_name: first_name,
      address: address1,
      country: country_id,
      state: state_id,
      last_name: last_name,
      mobile: mobile_no,
    };

    Object.keys(formData).forEach(function (key) {
      if (formData[key] === 0) {
        ReactToast({ type: "error", message: "Enter Required Form fields" });
        e.preventDefault()
        return false;

      }

    }); */
    setLoading(false);
  };
  /* const onSubmit = async (data) => {
    dispatchEvent(new Event('submit'));
    let params = {
      first_name: data.first_name,
      last_name: data.last_name,
      email_id: data.email,
      username: data.username,
      password: data.password,
      confirm_password: data.confirm_password,
      phone_code: countryCode,
      mobile_no: data.phone_number,
      address1: data.address1,
      address2: data.address2,
      country_id: data.country,
      state_id: data.state,
      city_id: data.city,
      zip_code: data.postal_code,
      gstn_no: "",
      whats_up_no_country_phone_code: "",
      whats_up_no: "",
      facebook_profile_link: "",
      twitter_profile_link: "",
      you_tube_link: "",
      linked_in_link: "",
      instagram_link: "",
      user_availability: "",
      package_master_id: 11,
      package_price: 5000,
      currency_id: "INR",
      package_duration: 365,
      payment_gateway_name: "",
      transactionid: "",
      payment_date: "2021-11-02",
      payment_status: "initiated",
      payment_req_data: "",
      payment_res_data: ""

    }
/* 
    try {
      const apiSetting = {
        method: "POST",
        apiParams: params,
      };
      const apiResult = await apiRequest("user_sign_up_lawyer", apiSetting);
      if (apiResult.data.settings.success === "1") {
        let user = apiResult.data.data;
      }
    } catch (error) {
      console.log(error);
    } finally {
    } 
  };
 */

  return (
    <div className="inner-page membership-page">
      <div className="container">
        <div className="row">
          <div className="offset-lg-2 col-lg-8  offset-md-2 col-md-8 col-sm-12 col-xs-12">
            <div className="page-title">
              <h2>Membership Checkout</h2>
              <div className="selected-plan">
                <div className="form-row mb-4 width50per">
                  <div className="form-input-div1 ">
                    <h3>
                      <label
                        htmlFor="package_master_id_select"
                        className="float-start mb-1 package_master_id_select"
                      >
                        Membership Level |
                      </label>
                      <SelectBox
                        className="mdi mdi-chevron-down"
                        name="package_master_id_select"
                        id="package_master_id_select"
                        labelKey="package_name"
                        valueKey="package_master_id"
                        options={planOption}
                        isCancel={false}
                        OnChange={changePalnDropdown}
                        defaultSelected={selectedPackage}
                        defaultValue={selectedPackage}
                        placeholder="Select Membership Level"
                      />
                    </h3>
                  </div>
                  {allow_multi_currency_signup && currList && currList.length > 1 ? 
                    <div className="form-input-div1">
                        <h3>
                        <label
                            htmlFor="currency_id_select"
                            className="float-start mb-1 package_master_id_select"
                        >
                            Change Currency  |
                        </label>
                        <SelectBox
                            className="mdi mdi-chevron-down"
                            name="currency_id_select"
                            id="currency_id_select"
                            labelKey="name"
                            valueKey="name"
                            options={currList}
                            isCancel={false}
                            OnChange={changeCurrDropdown}
                            defaultSelected={selectedCurr}
                            defaultValue={selectedCurr}
                            placeholder="Select Currency"
                        />
                        </h3>
                    </div> : ""}
                </div>

                <p className="common-paragraph">
                  You have selected the
                  <strong className="selected-plan-name">{packageRef}</strong>
                  membership level.
                </p>
                <p className="common-paragraph">
                  The price for membership is
                  <strong className="selected-plan-price">
                    {packageText}
                    {/* INR 5,000.00 every 365 Days. */}
                  </strong>
                </p>
              </div>
            </div>
          </div>
          {/* action={`${process.env.REACT_APP_BACKEND_URL}action.html`} */}
          <form
            action={`${process.env.REACT_APP_BACKEND_URL}action.html`}
            method="POST"
            onSubmit={onSubmitEvent}
          >
            <div className="row">
              <div className="offset-lg-2 col-lg-8  offset-md-2 col-md-8 col-sm-12 col-xs-12">
                <div className="inner-title">
                  <h3>Account Information</h3>
                </div>
                <div className="form-section">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div className="form-row mb-4">
                        <div className="form-input-div">
                          <input
                            type="hidden"
                            id="package_master_id"
                            name="package_master_id"
                            className="form-control"
                            defaultValue={packageMasterId}
                          />
                        </div>
                        <div className="form-input-div">
                          <input
                            type="hidden"
                            id="amount"
                            name="amount"
                            defaultValue={packageAmount}
                          />
                        </div>
                        <div className="form-input-div">
                          <input
                            type="hidden"
                            id="package_duration"
                            name="package_duration"
                            className="form-control"
                            defaultValue={packageDuration}
                          />
                        </div>
                        <div className="form-input-div">
                          <input
                            type="hidden"
                            id="currency_id"
                            name="currency_id"
                            className="form-control"
                            defaultValue={currencyId}
                          />
                        </div>
                        <div className="form-input-div">
                          <input
                            type="hidden"
                            id="currency"
                            name="currency"
                            className="form-control"
                            defaultValue={currencyCode}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                      <div className="form-row mb-4">
                        <label htmlFor="email" className="float-start mb-1">
                          Email Address <em>*</em>
                        </label>
                        <div className="form-input-div">
                          <input
                            type="text"
                            id="email_id"
                            name="email_id"
                            /* {...register("email", {
                              required: "Enter your e-mail",
                              pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "Enter a valid Email.",
                              },
                            })} */
                            onChange={checkEmail}
                            className="form-control"
                            placeholder="Enter Email Address"
                            required
                          />
                          <div className="input-err">
                            {errors?.email?.message}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                      <div className="form-row mb-4">
                        <label htmlFor="email" className="float-start mb-1">
                          GST Number
                        </label>
                        <div className="form-input-div">
                          <input
                            type="text"
                            id="gstn_no"
                            name="gstn_no"
                            onChange={checkGstNo}
                            className="form-control"
                            placeholder="Enter GST Number"
                          />
                          <div className="input-err">
                            {errors?.gstn_no?.message}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div className="form-row mb-4">
                        <label htmlFor="password" className="float-start mb-1">
                          Password <em>*</em>
                        </label>
                        <div className="form-input-div">
                          <input
                            type={passwordShown ? "text" : "password"}
                            id="password"
                            name="password"
                            /* {...register("password", {
                              required: "Enter your password",
                              minLength: {
                                value: 6,
                                message: "Minimum 6 characters",
                              },
                            })} */
                            required
                            className="form-control"
                            placeholder="Enter Password"
                            onChange={onChangePassword}
                            autoFocus
                          />
                          <i
                            onClick={togglePasswordVisiblity}
                            className={eyeClass + " mdi password-eye"}
                          ></i>
                          <div className="input-err">
                            {errors?.password?.message}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div className="form-row mb-4">
                        <label
                          htmlFor="confirm_password"
                          className="float-start mb-1"
                        >
                          Confirm Password <em>*</em>
                        </label>
                        <div className="form-input-div">
                          <input
                            type={confirmpasswordShown ? "text" : "password"}
                            id="confirm_password"
                            name="confirm_password"
                            /*  {...register("confirm_password", {
                               required: "Confirm Password is required",
                             })} */
                            className="form-control"
                            placeholder="Confirm Password"
                            required
                            autoFocus
                            onChange={onchangeConfirmpassword}
                          />
                          <i
                            onClick={toggleConfirmPasswordVisiblity}
                            className={eyeClassCpass + " mdi password-eye"}
                          ></i>
                          <div className="input-err">
                            {errors?.confirm_password?.message}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="offset-lg-2 col-lg-8  offset-md-2 col-md-8 col-sm-12 col-xs-12">
                <div className="inner-title">
                  <h3>Billing Address</h3>
                </div>
                <div className="form-section">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div className="form-row mb-4">
                        <label
                          htmlFor="first_name"
                          className="float-start mb-1"
                        >
                          First Name <em>*</em>
                        </label>
                        <div className="form-input-div">
                          <input
                            type="text"
                            id="first_name"
                            name="first_name"
                            // {...register("first_name", { required: " First Name is requred" })}
                            required
                            autoFocus
                            className="form-control"
                            placeholder="Enter First Name"
                          />
                          <div className="input-err">
                            {errors?.first_name?.message}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div className="form-row mb-4">
                        <label htmlFor="last_name" className="float-start mb-1">
                          Last Name <em>*</em>
                        </label>
                        <div className="form-input-div">
                          <input
                            type="text"
                            id="last_name"
                            name="last_name"
                            // {...register("last_name", { required: " Last Name is requred" })}
                            className="form-control"
                            placeholder="Enter Last Name"
                            required
                            autoFocus
                          />
                          <div className="input-err">
                            {errors?.last_name?.message}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div className="form-row mb-4">
                        <label htmlFor="address1" className="float-start mb-1">
                          Address 1 <em>*</em>
                        </label>
                        <div className="form-input-div">
                          <input
                            type="text"
                            id="address1"
                            name="address1"
                            //{...register("address1", { required: " Address 1 is requred" })}
                            required
                            className="form-control"
                            placeholder="Enter Address 1"
                            autoFocus
                          />
                          <div className="input-err">
                            {errors?.address1?.message}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div className="form-row mb-4">
                        <label htmlFor="address2" className="float-start mb-1">
                          Address 2
                        </label>
                        <div className="form-input-div">
                          <input
                            type="text"
                            id="address2"
                            name="address2"
                            // {...register("address2", { required: " Address 2 is requred" })}
                            className="form-control"
                            placeholder="Enter Address 2"
                            autoFocus
                          />
                          <div className="input-err">
                            {errors?.address2?.message}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div className="form-row mb-4">
                        <label htmlFor="" className="float-start mb-1">
                          Country <em>*</em>
                        </label>
                        <div className="form-input-div">
                          <input
                            type="hidden"
                            id="country_id"
                            name="country_id"
                            value={countryId}
                          />
                          <SelectBox
                            className="mdi mdi-chevron-down"
                            name="country_id_select"
                            id="country_id_select"
                            labelKey="country"
                            valueKey="country_id"
                            options={countryOption}
                            //{...register("country", { required: "Required" })}
                            OnChange={changeCountry}
                            defaultValue={countryId}
                            placeholder="Select value from dropdown"
                            required
                            // autoFocus
                          />
                          <div className="input-err">
                            {errors?.country?.message}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div className="form-row mb-4">
                        <label htmlFor="state" className="float-start mb-1">
                          State <em>*</em>
                        </label>
                        <div className="form-input-div">
                          <input
                            type="hidden"
                            id="state_id"
                            name="state_id"
                            value={stateId}
                          />
                          <SelectBox
                            className="mdi mdi-chevron-down"
                            name="state_id_select"
                            id="state_id_select"
                            labelKey="state"
                            valueKey="state_id"
                            options={stateOption}
                            placeholder="Select value from dropdown"
                            //{...register("state", { required: " State is required" })}
                            required
                            // autoFocus
                            OnChange={changeState}
                            ref={stateRef}
                          />
                          <div className="input-err">
                            {errors?.state?.message}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div className="form-row mb-4">
                        <label htmlFor="city" className="float-start mb-1">
                          City
                        </label>
                        <div className="form-input-div">
                          <input
                            type="hidden"
                            id="city_id"
                            name="city_id"
                            value={cityId}
                          />
                          <SelectBox
                            className="mdi mdi-chevron-down"
                            name="city_id_select"
                            id="city_id_select"
                            labelKey="city"
                            valueKey="city_id"
                            options={cityOption}
                            OnChange={changeCity}
                            placeholder="Select value from dropdown"
                            ref={cityRef}
                            // {...register("city")}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div className="form-row mb-4">
                        <label
                          htmlFor="postal_code"
                          className="float-start mb-1"
                        >
                          Postal Code
                        </label>
                        <div className="form-input-div">
                          <input
                            type="text"
                            id="zip_code"
                            name="zip_code"
                            //{...register("postal_code", { required: "Required" })}
                            className="form-control"
                            placeholder="Enter Postal Code"
                            // required
                            autoFocus
                          />
                          <div className="input-err"></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div className="form-row mb-4">
                        <label htmlFor="mobile" className="float-start mb-1">
                          Phone Number <em>*</em>
                        </label>
                        <div className="form-input-div">
                          <input
                            type="hidden"
                            id="phone_code"
                            name="phone_code"
                            defaultValue={phoneCode}
                          />
                          <PhoneInput
                            id="mobile_no"
                            name="mobile_no"
                            placeholder="Phone Number"
                            defaultCountry="IN"
                            onChange={(e, val) => changeMobile(e, val)}
                            /*  rules={{
                               ...register("mobile", {
                                 required: " Phone Number is required.",
                               }),
                             }} */
                            onCountryChange={onCountryChange}
                          />
                          <div className="input-err">
                            {errors?.mobile?.message}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="form-group">
                        <div className="checkbox">
                          <label className="d-flex align-items-center">
                            <input
                              type="checkbox"
                              id="termAccepted"
                              className="me-2"
                              onClick={(e) => {
                                const tmp = e.target.checked;
                                setTermAccepted(tmp);
                              }}
                              /* {...register("termAccepted")} */
                            />
                            By clicking "Checkbox", you agree to the{" "}
                            <Link
                              to="/TermsCondition"
                              target="_blank"
                              className="ms-2 terms-link"
                            >
                              Terms
                            </Link>{" "}
                            &nbsp; &{" "}
                            <Link
                              to="/PrivacyPolicy"
                              target="_blank"
                              className="ms-2 terms-link"
                            >
                              Privacy Policy
                            </Link>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5">
                      <div className="form-row mt-5 custom-action">
                        {loading && (
                          <button
                            form="contact-person-add-hook-form"
                            className="w-20 btn btn-primary m-auto"
                          >
                            <div
                              className="spinner-border text-white  text-center"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>{" "}
                          </button>
                        )}
                        {!loading && (emailExist || !termAccepted) ? (
                          <button
                            disabled
                            className="btn-green m-auto"
                            type="submit"
                            value="Submit"
                          >
                            Submit and CheckOut
                          </button>
                        ) : (
                          <button
                            className="btn-green m-auto"
                            type="submit"
                            value="Submit"
                          >
                            Submit and CheckOut
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MembershipCheckout;
