import React, { useState } from "react";
import { useForm } from "react-hook-form";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { saveVariable } from "../../../Helpers/Session";
const LawyerInquiry = (props) => {
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    let formData = [];
    formData.push({
        'user_name' : data.name,
        'selected_lawyer_id' : props.selectedLawyer,
        'inquiry_mobile_number' : data.inquiry_mobile_number,
        'request_on' : props.request_on
    });
    let cur_time = new Date().getTime();
    cur_time = cur_time + (1 * 60 * 60* 1000);
    localStorage.setItem('user_cookie_time',cur_time);
    props.closeModal();
    props.saveInquiryLog(formData);
  };

    const changeMobile = (value) => {
        if (value) {
            if (isValidPhoneNumber(value) === true) {
                setValue("inquiry_mobile_number", value);
                clearErrors("inquiry_mobile_number");
            } else {
                setError("inquiry_mobile_number", {
                    type: "invalid",
                    message: "Invalid Mobile number",
                });
            }
        } else {
            setError("inquiry_mobile_number", {
                type: "required",
                message: "Mobile number is mandatory",
            });
        }
    };

  
  return (
    <div className="ReactModal-namechange-content-container ReactModal-Content-Container" id="lawyer_inquery">
        <div className="ReactModal-middle-container">
            <div className="modal-txt">
                <h4>
                    <p>
                        Kindly share your Name And Mobile no to access information about the lawyer.
                    </p>
                </h4>
            </div>
            <form id="contact-person-add-hook-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-row">
              <label htmlFor="inputEmail" className="float-start mb-1 d-flex">
                Name <em>*</em>
              </label>
              <div className="form-input position-relative">
                <input
                  type="text"
                  id="inputEmail"
                  className="form-control"
                  placeholder="Name"
                  {...register("name", {
                    required: "Name is required",
                  })}
                />
                <div className="input-err">{errors?.name?.message}</div>
              </div>
            </div>
                <div className="form-group">
                    <div className="form-row mb-3 ">
                    <label htmlFor="inputUnivercityName" className="float-start mb-1">
                        Mobile Number <em>*</em>
                    </label>
                    <div className="form-input-div form-input position-relative">
                        <PhoneInput
                            id="inquiry_mobile_number"
                            placeholder="Mobile Number"
                            defaultCountry={"IN"}
                            onChange={changeMobile}
                            international ={false}
                            countries = {['IN']}
                            rules={{
                              ...register("inquiry_mobile_number", {
                                required: "Mobile Number is required",
                              }),
                            }}
                        />
                    </div>
                    <div className="input-err">
                        {errors?.inquiry_mobile_number?.message}
                    </div>
                    </div>
                </div>          
            </form>
        </div>
        <div className="ReactModal-Footer">
            <div className="ReactModal-ActionBlock profile-action-area">
                <div className="btn-grp m-auto">
                    <button form="contact-person-add-hook-form" className="w-20 btn btn-primary m-auto" onClick={handleSubmit(onSubmit)}>
                        <i className="mdi mdi-check"></i> View Detail
                    </button>
                </div>
            </div>
        </div>
    </div>
  );
};

export default LawyerInquiry;
