import moment from "moment";
import React, { useState } from "react";
import DatePicker from "react-date-picker";
import { useForm } from "react-hook-form";
const AwardForm = (props) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [eventdate, seteventDate] = useState();
  const onEventDatechange = (value) => {
    if(value){
      let datevalue = moment(value).format("YYYY-MM-DD");
      setValue("attened_date", datevalue);
      }
      else{
        setValue("attened_date", value); 
      }
    seteventDate(value);
  };
  
  const onSubmit = async (data) => {
    let formData = props.previesData;
    let row_id = (formData.length + 1) * -1;
    formData.push({
        out_meeting_id: row_id,
        attened_date: data.attened_date,
        event_name: data.event_name,
        venue: data.venue
    });
    props.setLineInfo(formData);
    props.setDoRefresh(Math.random());
    props.closeModal();
  }

  return (
    <div
      className="ReactModal-namechange-content-container ReactModal-Content-Container"
      id="name_change_form"
    >
      <div className="ReactModal-middle-container">
        <form
          id="contact-person-add-hook-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <input
            type="hidden"
            id="inputId"
            className="form-control"
            {...register("id")}
          />
          {/* <div className="form-group">
            <div className="profile-content width75">  */}
          <div className="form-group">
            <div className="form-row mb-3 ">
              <label htmlFor="inputeventtitle" className="float-start mb-1">
                Title <em>*</em>
              </label>
              <div className="input-group">
                <input
                  type="text"
                  id="inputeventtitle"
                  className="form-control"
                  placeholder="Name"
                  {...register("event_name", {
                    required: "Title is required",
                  })}
                />
              
              
              </div>
              <div className="input-err">{errors?.event_name?.message}</div>
            </div>
          </div>
          <div className="form-group">
            <div className="form-row mb-3 custom-date">
              <label htmlFor="inputFrom" className="float-start mb-1">
              Event Date<em>*</em>
              </label>
              <div className="input-group">
              <DatePicker
                  format="dd/MM/y"
                  onChange={onEventDatechange}
                  name="eventattdate"
                  value={eventdate}
                  maxDate={new Date()}
                  rules={{...register("attened_date", {
                    required: "Event Date is required",
                  })}}
                />
              </div>
              <div className="input-err">{errors?.attened_date?.message}</div>
            </div>
          </div>
          <div className="form-group">
            <div className="form-row mb-3 ">
              <label htmlFor="inputeventtitle" className="float-start mb-1">
                 Venue <em>*</em>
              </label>
              <div className="input-group">
                <input
                  type="text"
                  id="inputeventtitle"
                  className="form-control"
                  placeholder="Name"
                  {...register("venue", {
                    required: "Venue is required",
                  })}
                />
              
              
              </div>
              <div className="input-err">{errors?.venue?.message}</div>
            </div>
          </div>
        </form>
      </div>
      <div className="ReactModal-Footer">
        <div className="ReactModal-ActionBlock profile-action-area">
          <div className="btn-grp m-auto">
            <button
              form="contact-person-add-hook-form"
              className="w-20 btn btn-primary m-auto"
              onClick={handleSubmit(onSubmit)}
            >
              <i className="mdi mdi-check"></i> Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AwardForm;
