import React from "react";
import { useForm } from "react-hook-form";
const ContactPerson = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const regexUrl = new RegExp(
    "http[s]?://(?:[a-zA-Z]|[0-9]|[$-_@.&+]|[!*(),]|(?:%[0-9a-fA-F][0-9a-fA-F]))+"
  );
  const onSubmit = async (data) => {
    let formData = props.previesData;
    let row_id = (formData.length + 1) * -1;

    formData.push({
      ranking_id: row_id,
      // title                   : data.title && data.title.map((val) => val.child_enum_key).toString(),
      user_rank_title: data.user_rank_title.trim(),
      user_credential_link: data.user_credential_link,
    });

    props.setLineInfo(formData);
    props.setDoRefresh(Math.random());
    props.closeModal();
  };

  return (
    <div
      className="ReactModal-namechange-content-container ReactModal-Content-Container"
      id="name_change_form"
    >
      <div className="ReactModal-middle-container">
        <form
          id="contact-person-add-hook-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <input
            type="hidden"
            id="inputId"
            className="form-control"
            {...register("id")}
          />
          {/* <div className="form-group">
            <div className="profile-content width75">  */}
          <div className="form-group">
            <div className="form-row mb-3 ">
              <label htmlFor="inputRankTitle" className="float-start mb-1">
                Grade Title<em>*</em>
              </label>
              <div className="input-group">
                <input
                  type="text"
                  id="inputRankTitle"
                  className="form-control"
                  placeholder="Grade Title"
                  {...register("user_rank_title", {
                    required: " Grade Title is required ",
                  })}
                />
              </div>
              <div className="input-err">
                {errors?.user_rank_title?.message}
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="form-row mb-3 ">
              <label htmlFor="inputCredentialLink" className="float-start mb-1">
                Credential Link<em>*</em>
              </label>
              <div className="input-group">
                <input
                  type="text"
                  id="inputCredentialLink"
                  className="form-control"
                  placeholder="https://example.com"
                  {...register("user_credential_link", {
                    required: " Credential Link is required",
                    pattern: {
                      value: regexUrl,
                      message: "Enter a valid  Credential Link",
                    },
                  })}
                />
              </div>
              <div className="input-err">
                {errors?.user_credential_link?.message}
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="ReactModal-Footer">
        <div className="ReactModal-ActionBlock profile-action-area">
          <div className="btn-grp m-auto">
            <button
              form="contact-person-add-hook-form"
              className="w-20 btn btn-primary m-auto"
              onClick={handleSubmit(onSubmit)}
            >
              <i className="mdi mdi-check"></i> Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPerson;
