import { useSetRecoilState } from "recoil";
import { curRouteDetailsState } from "../../../../Helpers/Recoil";
import React, { useEffect, useState,useContext } from "react";
import { apiRequest } from "../../../../Helpers/General";
import DocumentMeta from "react-document-meta";
import { MasterContext } from "../../../../Context";
const Faqs = (props) => {
  const setCurRouteDetails = useSetRecoilState(curRouteDetailsState);
  useEffect(() => {
    setCurRouteDetails(props.componentInfo);
  });
  const { genralMasterData } = useContext(MasterContext);
  let faqLabel = genralMasterData.FAQ
  const [isPageUpdating, setIsPageUpdating] = useState(true);
  const [faqsData, setFAQSData] = useState([]);
  const [faqsMetaData, setFAQSMetaData] = useState([]);
  useEffect(() => {
    const GetData = async () => {
      try {
        const apiSetting = {
          method: "GET",
        };
        const apiResult = await apiRequest("faq_lising", apiSetting);

        if (apiResult.data.settings.success === "1") {
          setFAQSData(apiResult.data.data.faq_listing);
          setFAQSMetaData(apiResult.data.data.faq_meta_data[0]);
          setIsPageUpdating(false);
        } else {
          setFAQSData([]);
          setFAQSMetaData([]);
          setIsPageUpdating(false);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsPageUpdating(false);
      }
    };
    if (isPageUpdating) {
      GetData();
    }
  }, [isPageUpdating]);
  const meta = {
    title: faqsMetaData && faqsMetaData.meta_title,
    description: faqsMetaData && faqsMetaData.meta_description,
    meta: {
      charset: "utf-8",
      name: {
        keywords: faqsMetaData && faqsMetaData.meta_key_words,
      },
    },
  };

  return (
    <DocumentMeta {...meta}>
      <div className="inner-page about-page">
        <div className="container">
          <div className="row">
            <div className="page-title  text-center">
              <h1>{faqLabel&&faqLabel.gm_heading?faqLabel.gm_heading:"FAQs"}</h1>
            </div>
          </div>
          <div className="row">
            <div className="common-page-content about-content col-12">
              <div className="row">
                <div className="accordion" id="accordionExample">
                  {faqsData.map((row, key) => (
                    <div className="accordion-item" key={key}>
                      <h2 className="accordion-header" id={"headingOne"}>
                        <button
                          className={key === 0 ? "accordion-button" : "accordion-button collapsed"}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={"#Collape" + key}
                          aria-expanded="true"
                          aria-controls={"Collape" + key}
                        >
                          {row.faq_question}
                        </button>
                      </h2>
                      <div
                        id={"Collape" + key}
                        className={"accordion-collapse collapse " + (key === 0 && " show")}
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p className="common-paragraph" dangerouslySetInnerHTML={{ __html: row.faq_answer }}></p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DocumentMeta>
  );
};

export default Faqs;
